export function Counter() {
    let counter: number = 1;
    return function () {
        counter++;
        return counter;
    }
};

export function elementRect(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    return { 
                xLeft: rect.left + window.scrollX,
                xRight: rect.right + window.scrollX, 
                yTop: rect.top + window.scrollY, 
                yBottom: rect.bottom + window.scrollY, 
                width: rect.width, 
                height: rect.height 
            };
}

// export function formatNumberWithCommas(number?: number): string {
//     if (!number && number !== 0) return '';
//     return numberAsFixed(number, 2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || '';
// }

export function formatNumberWithCommas(number?: number): string {
    if (!number && number !== 0) return '';
    return numberAsFixed(number, 2)
        .replace('.', ',') 
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); 
}

export function numberAsFixed(number: number, decimals: number = 0): string {
    return typeof number === 'number' && number.toFixed(decimals) || '';
}

export function formatTimestamp(unix?: number): string {
    if (!unix) return '';
    const timestamp = parseInt(unix.toFixed(0).substring(0, 10));
    const date = new Date(timestamp * 1000);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getUTCFullYear().toString();
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  }

 export function cs(value: any | any[]) {
    console.log(value);
 } 

 export function ObjectAsArray(source: Record<string, { name: string }>) { 
   return Object.entries(source).map(([key, value]) => ({id: +key, name: value.name}));
 }    

export function differenceInDays(date1: Date, date2: Date): number {
    return Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
}
  
  