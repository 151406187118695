import apiClient from "./apiclient";
import * as Auth from "./auth";


async function callCountries(hs) {

    let url = "/pfm/api/v1/reference-books/countries";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    //console.info("res(RefBooks/Countries): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setCountries(res.data);
        }
        /*
        return new Promise((resolve, reject) => {
            setTimeout(resolve, 3000, 'OK-c');
        });
        */

    } else {
        console.log("callCountries() failed");
    }
    return "";
}

async function callTimezones(hs) {

    let url = "/pfm/api/v1/reference-books/timezones";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    //console.info("res(RefBooks/Timezones): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setTimezones(res.data);
        }
    } else {
        console.log("callTimezones() failed");
    }
    return "";
}

async function callTaskStatuses(hs) {

    let url = "/pfm/api/v1/reference-books/task-statuses";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    // console.info("res(RefBooks/TaskStatuses): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setTaskStatuses(res.data);
        }
    } else {
        console.log("callTaskStatuses() failed");
    }
    return "";
}

async function callMilestoneStatuses(hs) {

    let url = "/pfm/api/v1/reference-books/milestone-statuses";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    //console.info("res(RefBooks/MilestineStatuses): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setMilestoneStatuses(res.data);
        }
    } else {
        console.log("callMilestoneStatuses() failed");
    }
    return "";
}


async function callDayTypes(hs) {

    let url = "/pfm/api/v1/reference-books/day-types";
    if (hs !== "") {
        url += "?hash=" + hs;
    }
    const res = await apiClient.get(url);
    //console.info("res(RefBooks/DayTypes): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setDayTypes(res.data);
        }
    } else {
        console.log("callDayTypes() failed");
    }
    return "";
}

async function callMembershipLevels(hs) {

    let url = "/pfm/api/v1/reference-books/membership-levels";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    //console.info("res(RefBooks/MembershipLevels): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setMembershipLevels(res.data);
        }
    } else {
        console.log("callMembershipLevels() failed");
    }
    return "";
}

async function callPlanStatuses(hs) {

    let url = "/pfm/api/v1/reference-books/schedule-statuses";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    //console.info("res(RefBooks/PlanStatuses): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setPlanStatuses(res.data);
        }
    } else {
        console.log("callPlanStatuses() failed");
    }
    return "";
}

async function callPlanningSessionStatuses(hs) {

    let url = "/pfm/api/v1/reference-books/scheduling-session-statuses";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    //console.info("res(RefBooks/PlanningSessionStatuses): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setPlanningSessionStatuses(res.data);
        }
    } else {
        console.log("callPlanningSessionStatuses() failed");
    }
    return "";
}

async function callPlanningSessionTypes(hs) {

    let url = "/pfm/api/v1/reference-books/scheduling-session-types";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    //console.info("res(RefBooks/PlanningSessionTypes): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setPlanningSessionTypes(res.data);
        }
    } else {
        console.log("callPlanningSessionTypes() failed");
    }
    return "";
}

async function callSubscriptionStatuses(hs) {

    let url = "/pfm/api/v1/reference-books/subscription-statuses";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    //console.info("res(RefBooks/SubscriptionStatuses): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setSubscriptionStatuses(res.data);
        }
    } else {
        console.log("callSubscriptionStatuses() failed");
    }
    return "";
}

async function callDateFormats(hs) {

    let url = "/pfm/api/v1/reference-books/datetime-formats";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    // console.info("res(RefBooks/callDateFormats): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setDateFormats(res.data);
        }
    } else {
        console.log("callDateFormats() failed");
    }
    return "";
}


/*
async function callTradeTypeTemplates(hs) {

    let url = "/pfm/api/v1/reference-books/trade-type-templates";
    if (hs !== "") {
        url += "?hash=" + hs;
    }

    const res = await apiClient.get(url);
    //console.info("res(RefBooks/TradeTypeTemplates): ", res);
    if (res.status === 200) {
        if (res.data.hash) {
            Auth.setTradeTypeTemplates(res.data);
        }
    } else {
        console.log("callTradeTypeTemplates() failed");
    }
    return "";
}
*/



export async function callRefBooks(reload) {
    let init = true;
    let rb = Auth.getRefBooks();

    let url = "/pfm/api/v1/reference-books";
    if (rb) {
        let hs = reload ? "": rb.totalHash;
        url += "?hash=" + hs;
        init = reload ? true : false;
    }

    const res = await apiClient.get(url);
    if (res.status === 200) {
        if (res.data.totalHash) {
            Auth.setRefBooks(res.data);

            const hs1 = init ? "" : res.data.book.countries;
            const hs2 = init ? "" : res.data.book['day-types'];
            const hs3 = init ? "" : res.data.book['membership-levels'];
            const hs4 = init ? "" : res.data.book['milestone-statuses'];
            const hs5 = init ? "" : res.data.book['schedule-statuses'];
            const hs6 = init ? "" : res.data.book['scheduling-session-statuses'];
            const hs7 = init ? "" : res.data.book['scheduling-session-types'];
            const hs8 = init ? "" : res.data.book['subscription-statuses'];
            const hs9 = init ? "" : res.data.book['task-statuses'];
            const hs10 = init ? "" : res.data.book.timezones;
            const hs11 = init ? "" : res.data.book['datetime-formats'];
            

            const pm1 = callCountries(hs1);
            const pm2 = callDayTypes(hs2);
            const pm3 = callMembershipLevels(hs3);
            const pm4 = callMilestoneStatuses(hs4);
            const pm5 = callPlanStatuses(hs5);
            const pm6 = callPlanningSessionStatuses(hs6);
            const pm7 = callPlanningSessionTypes(hs7);
            const pm8 = callSubscriptionStatuses(hs8);
            const pm9 = callTaskStatuses(hs9);
            const pm10 = callTimezones(hs10);
            const pm11 = callDateFormats(hs11);
            

            return Promise.all([pm1, pm2, pm3, pm4, pm5, pm6, pm7, pm8, pm9, pm10, pm11]).then((a) => a);
        } else {
            return "";
        }
    } else {
        console.log("callRefBooks() failed");
    }
    return "";
}
