import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { getUrlId } from '../../components/AppComponents/funcs';
import { Grider } from '../../components/AppComponents/Grider/Grider';
import { ModalVOrganizer, ModalVRow } from '../../components/AppComponents/Modals/ModalUtils';
import { IOrganizationSubscriptionSummary, ISubscriptionData } from '../../components/AppComponents/types';
import { ModalNames } from '../../components/AppComponents/variables';
import { Button } from '../../components/BCComponents/Controls/Buttons/Buttons';
import { Header } from '../../components/BCComponents/Layouts/Layouts';
import { FlatHPanel, FlatVPanel } from '../../components/BCComponents/Layouts/Panels/ArrangementPanels';
import { TabView } from '../../components/BCComponents/Layouts/TabView/TabView';
import { MonthPicker } from '../../components/BCComponents/MonthPIcker/MonthPicker';
import { navigateCommand } from '../../components/BCComponents/Navigation';
import { t } from '../../locale';
import { RetrieveRecord } from '../../utils/DataSets/DataSets';
import './Licenses.css';
import { createActiveStore } from '../../state/ActiveStore';

export const currentSubscription = createActiveStore<IOrganizationSubscriptionSummary | null>(null);

export function Licenses() {
    const license = useStore(currentSubscription.store) || ({} as IOrganizationSubscriptionSummary);
    const orgId = getUrlId();

    useEffect(() => {
        RetrieveRecord<IOrganizationSubscriptionSummary>('organizations/${id}/subscription-summary', undefined, { id: orgId || -1 }, 'aaa')
        .then(data => data && currentSubscription.set(data.record))
        .catch(reason => console.log('Licenses retrieve error', reason));
    }, [orgId]);

    return license && <>
        {/* <Header label={t('%Licenses%')} style={{ paddingLeft: '1em', paddingBottom: 0 }} /> */}
        <div className="license">
            <ModalVOrganizer>
                <ModalVRow colSpan={true}>
                    <FlatHPanel verticalAlign="top">
                        { <div style={{ fontSize: '1.5em', fontWeight: '600', letterSpacing: '1px' }}>{ license.title || 'No License Name'}</div> }
                        { license.isTrial ? <div className="trial">{t(`%TRIAL${(license.endDt || '9999-99-99').substring(0, 10) < (new Date()).YYYY_MM_DD() && ' EXPIRED' || '' }%`)} </div> : null }
                    </FlatHPanel>
                </ModalVRow>                    
                
                <ModalVRow label='Usage started'>
                    { (new Date(license.startDt)).DDMMYYYY() }
                </ModalVRow>
                
                { license.endDt && <ModalVRow label='Expiration date'>
                    { (new Date(license.endDt)).DDMMYYYY() }
                </ModalVRow> }
                
                { license.maxUsers && <ModalVRow label='Maximum members'>
                    { license.maxUsers }
                </ModalVRow> }

                <ModalVRow label='Active members'>
                    { license.usedUsers }
                </ModalVRow>
            </ModalVOrganizer>
            <div className="hDelimeter" style={{ margin: '0 0 0.5em 0' }}></div>
            { license?.subscriptionId && <Grider 
                key     = { license.subscriptionId }
                content = {`organizations/${ license.organizationId }/subscriptions`} 
                label   = { t('%Subscription history%') } 
                serviceDomain="aaa"
            /> }
        </div>
    </>
}

function getPreviousMonth(date: Date) {
    const previousMonth = date.getMonth() === 0 ? 11 : date.getMonth() - 1;
    const year = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
    return { year, month: previousMonth + 1 };
}

function Usage() {
    const currDate = new Date();
    const currMonth = useMemo(() =>  ({ year: currDate.getFullYear(), month: currDate.getMonth() + 1 }), []);
    
    const license = useStore(currentSubscription.store);
    const [usageMonth, setUsageMonth] = useState<{ year: number, month: number }>(currMonth);

    return license &&
        <FlatVPanel>
        <FlatHPanel style={{ margin: '0.5em' }}>
            <MonthPicker fromYear={ (new Date()).getFullYear() - 2 } defaultValue={ currMonth } years={ 3 } onSelect={(year, month) => setUsageMonth({ year, month }) } />
        </FlatHPanel>
        <Grider 
            key={`${usageMonth?.month || -1}#${usageMonth?.year || -1}`}
            content={`organizations/${ license.organizationId }/subscriptions/${ license.subscriptionId }/usages`} 
            label="" 
            serviceDomain="aaa"
            params={ usageMonth }
        />                  
        </FlatVPanel>;
}