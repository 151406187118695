import { useStore } from 'effector-react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createActiveStore } from '../../../../state/ActiveStore'
import { FlatHPanel, FlatVPanel } from '../../Layouts/Panels/ArrangementPanels'
import { FlatRightPanel } from '../../Layouts/Panels/RightPanel'
import { FlatWidePanel } from '../../Layouts/Panels/StretchedPanels'
import { IconButton } from '../Buttons/IconButton/IconButton'
import { Label } from '../Label/Label'

import { t } from '../../../../locale'
import apiClient from '../../../../utils/apiclient'
import { cs } from '../../utils'
import './MessageBox.css'

const duration = 10;

let elementKey: number = 0;

const messages = createActiveStore<{ element: JSX.Element, message: string, key: number }[]>([]);

export function alert(msg: { message: string, type?: 'notify' | 'error', caption?: string, label?: string, uid?: string }) {
    elementKey++;
    !messages.get().find(message => message.message === msg.message) && 
    messages.set([
            ...messages.get(), 
            { 
                element: <MessageBox {...msg} key={ elementKey } elementKey={elementKey} />, 
                key: elementKey,
                message: msg.message, 
            }
    ])
}

function MessageBox(msg: { message: string, type?: 'notify' | 'error', caption?: string, label?: string, uid?: string, elementKey: number }) {
    const timer = useRef<NodeJS.Timeout | null>(null); 
    const [closesIn, setClosesIn] = useState(duration);
    const [countdown, setCountdown] = useState(true);

    function close() {
        timer.current && clearTimeout(timer.current);
        const index =  messages.get().findIndex(msgBox => msgBox.key === msg.elementKey);
        messages.get().splice(index, 1);
        messages.set([...messages.get()]);
    }

    useEffect(() => {
        if (countdown) 
            timer.current = setTimeout(() => closesIn === 0 ? close() : (closesIn > 0 && setClosesIn(closesIn - 1)), 1000)
        else 
            timer.current && clearTimeout(timer.current);

        return () => { timer.current && clearTimeout(timer.current) };
    }, [closesIn, countdown]);

    useLayoutEffect(() => {
        setTimeout(() => msgBoxRef.current && !msgBoxRef.current.classList.contains('activated') && msgBoxRef.current.classList.add('activated'), 100);
    }, []);

    const msgBoxRef = useRef<HTMLDivElement>(null); 

    return <div key={ msg.elementKey } onMouseEnter={ () => setCountdown(false) } className='messageBox' ref={ msgBoxRef }>
        <FlatHPanel width='100%'>
            <Label icon='caution' iconSize='1.5em' iconColor='#D13438' style={{ flexShrink:0, flexGrow:0 }} />
            <FlatVPanel width='100%'>
                <FlatWidePanel vertical='center' style={{ marginBottom: '0.5em' }}>
                    <div className='messageCaption'> { msg.caption || t('%System message%') } </div>
                    { countdown && <FlatRightPanel style={{ color: '#999' }}>{t('%Closes in%')}&nbsp;{ closesIn }</FlatRightPanel> || null } 
                    <IconButton icon='cancel' color='#999' size='1.75em' onClick={ close } />
                </FlatWidePanel>
                <div className='messageLabel' style={{ padding: '0 0 0.25em 0', fontWeight: 500 }}>{ msg.label }</div>
                <div className='messageText'>{ msg.message }</div>
                { msg.uid && <FlatWidePanel style={{ color: '#999' }} classes='messageUID' vertical='center'>
                    { msg.uid }
                    <IconButton color='#999' size='1.75em' icon='clipboard' />
                </FlatWidePanel> || null }
            </FlatVPanel>
        </FlatHPanel>
    </div>
}

export function MessagesContainer() {
    const _messages = useStore(messages.store);
    return _messages.length && <div className='messagesContainer'> { _messages.map(msgBox => msgBox.element) } </div> || null
}

const silentModeURLs: string[] = [];

export function AddSilentModeURL(url: string) { silentModeURLs.indexOf(url) < 0 && silentModeURLs.push(url); }

export function RemoveSilentModeURL(url: string) {
    const index = silentModeURLs.indexOf(url);
    (index !== -1) && silentModeURLs.splice(index, 1);
}

apiClient.interceptors.response.use(
    response => response,
    error => {
       cs(error); 
      (!error.response || (error.response.status !== 401 && !silentModeURLs.some(value => error.response.config.url.indexOf(value) >= 0))) && 
      alert({ message: error.message, label: error.response?.statusText, caption: error.response?.status, uid: error.config?.headers?.['X-Bc-Request-Id'] });
      return Promise.reject(error);
    }
  );
