import { useStore } from "effector-react"  
import { createActiveStore } from "../../state/ActiveStore";
import React, { createContext, useContext } from "react";
import { postMessage } from "./Messages";
import { SafeJSONParse } from "../AppComponents/funcs";

export let baseUrl = '/';

export const NavigationMessage = 'NavigationMessage';

export function setBaseUrl(url: string) {
    baseUrl = url && url.replaceAll('*', '') || '/';
} 

export type ICommandBody = Record<string, any> | string | '#';

export interface IURLCommand {
    action: string;
    body: ICommandBody;
}

export interface INavigationContext extends IURLCommand {
    paths: string[];
}

export const NavigationContext = createContext<Partial<INavigationContext>>({});

export function navigateParam(paramName: string, paramValue: string, preserve?: boolean, body: ICommandBody = '', path?: string, ignorePushState: boolean = false) {
    const search: Record<string, any> = preserve && getURLParams() || {};
    paramValue && (search[paramName] = paramValue) || (Object.keys(search).indexOf(paramName) > -1 && delete search[paramName]);
    !body && Object.keys(search).indexOf('body') > -1 && delete search.body;
    body && body !== '#' && (search.body = body);
    navigate(path || '', search, ignorePushState);
}

export function navigateParams(params: Record<string, string>, preserve?: boolean, body: ICommandBody = '', path?: string, ignorePushState: boolean = false) {
    const search: Record<string, any> = preserve && getURLParams() || {};

    Object.keys(params).forEach(paramName => {
        const paramValue = params[paramName];
        paramValue ? (search[paramName] = paramValue) : (Object.keys(search).indexOf(paramName) > -1 && delete search[paramName]);
    });
    !body && Object.keys(search).indexOf('body') > -1 && delete search.body;
    body && body !== '#' && (search.body = body);
    navigate(path || '', search, ignorePushState);
}



export function navigateCommand(action: string, body: ICommandBody = '', preserve?: true, path?: string, ignorePushState: boolean = false) {
    const validBody = typeof body === 'object' ? (Object.keys(body).length > 0 && body || '') : body; 
    navigateParam('action', action, preserve, validBody, path, ignorePushState);
}

export function navigate(url: string, search: Object | string = {}, ignorePushState: boolean = false): void {
    const normalizedUrl = url && `${baseUrl}/${url}`.replace(/\/+/g, '/') || window.location.pathname;
    const queryString = typeof search === 'string' && (search[0] === '?' ? search.substring(1) : search) || 
                        Object.entries(search).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(typeof value === "string" && value || JSON.stringify(value))}`).join('&')
    const finalUrl = queryString ? `${normalizedUrl}?${queryString}` : normalizedUrl;
    !ignorePushState && window.history.pushState(null, '', finalUrl);
    urlState.set(finalUrl);
    // postMessage(NavigationMessage, null);
}

export function explodePath(path?: string): string[] {
    const base = baseUrl.split('/').filter(v => v);
    let tail = false;
    return (path || window.location.pathname || '').split('/').filter(v => v.trim()).filter((v, index) => tail || (tail = v !== base[index])) || [];
}

const urlState = createActiveStore<string>(window.location.pathname + window.location.search);

export function useNavigation() {
    useStore(urlState.store)
    return explodePath();
}

function normalPathnameSequence(path: string) {
    return path.replace(/\/+/g, '/').split('/').filter(v => v);
}

const isChildContext = createContext<{navigation: boolean, command: boolean}>({navigation: false, command: false});

export function Navigation({ path, children }: { path: string; children: React.ReactNode | React.ReactNode[] | undefined }) {
    const validPath = path.trim().split('?')[0];
    return useContext(isChildContext).navigation && 
                <ChildNavigation path={validPath}>
                    { children } 
                </ChildNavigation> ||
                <RootNavigation path={validPath}> 
                    { children } 
                </RootNavigation>;
}

function seqElementMatch(template: string, location: string): boolean {
    if (!location || !template) return false;

    const comparation = +(template.charAt(0) === '*') + (+(template.charAt(template.length - 1) === '*') * 2);
    switch (comparation) {
        case 1:
            return location.endsWith(template.substring(1));
        case 2:
            return location.startsWith(template.substring(0, template.length - 1));
        case 3:
            return location.includes(template.substring(1, template.length - 1));
        default:
            return location === template;
    }
}

function isPathMatch(path: string): boolean {
    const exact = path.charAt(path.length - 1) === '#';
    const templateSeq = [...normalPathnameSequence(baseUrl), ...normalPathnameSequence(path.replaceAll('#', ''))];
    const locationSeq = normalPathnameSequence(window.location.pathname);
    return (!exact || templateSeq.length >= locationSeq.length) && 
            templateSeq.every((v, index) => v === '*' || seqElementMatch(v.trim(), locationSeq[index]));
}

function pathNamesAreEqual(first: string, second: string) {
    return first.split('?')[0].replace(/\/+$/, '') === second.replace(/\/+$/, '');
}

function RootNavigation({ path, children }: { path: string; children: React.ReactNode | React.ReactNode[] | undefined }) {
    const url = useStore(urlState.store);
    const childContext = useContext(isChildContext);
    return (<>{pathNamesAreEqual(url, window.location.pathname) &&
        <isChildContext.Provider value={{ navigation: true, command: childContext.command }}>
            { isPathMatch(path) && 
                <NavigationContext.Provider value={{ paths: [path] }}>
                    { children }
                </NavigationContext.Provider> 
            }
        </isChildContext.Provider>
    }</>)
}

function ChildNavigation({ path, children }: { path: string; children: React.ReactNode | React.ReactNode[] | undefined }) {
    const navContext = useContext(NavigationContext);
    return <>{isPathMatch(path) &&
                <NavigationContext.Provider value={{ paths: [...(navContext.paths || []), path] }}>
                    { children }
                </NavigationContext.Provider>
    }</>;
}

export function Command({action, path, children} : {action?: string, path?: string, children: React.ReactNode | React.ReactNode[]}) {
    const validPath = path && path.trim().split('?')[0];
    return (useContext(isChildContext).command && <ChildCommand action={action} path={validPath}>{ children }</ChildCommand> ||
                        <RootCommand action={action} path={validPath}> { children } </RootCommand>);
}

export function getURLCommand(): Partial<IURLCommand> {
    const params = new URLSearchParams(window.location.search);
    return { 
        action: params.get('action') || undefined,
        body: params.get('body') || undefined
    };
}

export function getURLParam(name: string, safeParse?: true) {
    const params = new URLSearchParams(window.location.search);
    try {
        return safeParse ? JSON.parse(decodeURIComponent(params.get(name) || '')) : decodeURIComponent(params.get(name) || '') || undefined;
    } catch (error) { 
        return undefined;
    }
}

export function getNavigationBodyParams() {
    const body = getURLCommand().body;
    return typeof body === 'string' && SafeJSONParse(decodeURIComponent(body)) as Record<string, any> || {};
}

export function getURLParams() {
    const params = new URLSearchParams(window.location.search);
    const res: Record<string, string> = {};
    params.forEach((value, key) => res[key] = decodeURIComponent(value));
    return res;
}

function RootCommand({action, path, children} : {action?: string, path?: string, children: React.ReactNode | React.ReactNode[]}) {
    const url = useStore(urlState.store);
    const urlCommand = getURLCommand();
    const childContext = useContext(isChildContext);
    return (<>{pathNamesAreEqual(url, window.location.pathname) && urlCommand.action === action &&
        <isChildContext.Provider value={{ command: true, navigation: childContext.navigation }}>
            { (!path || isPathMatch(path)) && 
                <NavigationContext.Provider 
                    value={{ 
                        action: action,
                        body: urlCommand.body, 
                        paths: path && [path] || [] 
                    }}
                >{ children }</NavigationContext.Provider> }
        </isChildContext.Provider>
    }</>)
}

function ChildCommand({action, path, children} : {action?: string, path?: string, children: React.ReactNode | React.ReactNode[]}) {
    const navContext = useContext(NavigationContext);
    return <>{(!path || isPathMatch(path)) && navContext.action === action &&
                <NavigationContext.Provider 
                    value={{ 
                        action: action, 
                        body: navContext.body, 
                        paths: [...(navContext.paths || []), ...(path && [path] || [])]
                    }}
                >
                        { children }
                </NavigationContext.Provider>
    }</>;    
}

window.addEventListener('popstate', () => {
   navigate(explodePath(window.location.pathname).join('/'), window.location.search, true); 
});