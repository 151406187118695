import { useStore } from 'effector-react';
import { useEffect, useState } from "react";
import { Home } from './pages/Home/Home';
import { createActiveStore } from './state/ActiveStore';
import * as Auth from "./utils/auth";

import { upgradeProfile } from './components/AppComponents/aaa';
import { IUser } from './components/AppComponents/types';
import { currentUser } from './components/AppComponents/variables';
import { alert, MessagesContainer } from './components/BCComponents/Controls/MessageBox/MessageBox';
import { explodePath, navigate } from './components/BCComponents/Navigation';
import { LoginCover } from './containers/Login/Login';
import { InitApp } from './Init';
import { SignIn } from './pages/SignIn/SignIn';

Auth.setLocale(Auth.getLocale() || 'en');
const isAuthorizedState = createActiveStore(false);

export function Application() {
  const [initialized, setInitialized] = useState(false);
  const isAuthorized = useStore(isAuthorizedState.store);

  useEffect(() => {
    InitApp((isAuthorizedResult, error, errorMessage) => {
      if (error) {
        alert({ message: errorMessage || 'Init error'}); 
        return;
      }
      isAuthorizedState.set(isAuthorizedResult);
      setInitialized(true); 
    });
  }, [])
  
  return initialized && <Scene isAuthorized={isAuthorized} /> || <></>;
};

function Scene({ isAuthorized }: { isAuthorized: boolean }) {
  const [isUpgraded, setIsUpgraded] = useState(false);
  useEffect(() => {
    if (isAuthorized) {
      const user = Auth.getUserProfile() as IUser;
      const path = explodePath();
      const urlOrg = path[0] && user.organizations?.find(org => org.uid === path[0]);
      const orgId = urlOrg && urlOrg.id || user.organizations?.[0]?.id || 0;
      Auth.setOrgUid(urlOrg && urlOrg.uid || user.organizations?.find(org => org.id === orgId) || '_admin');
      currentUser.set({ ...user, currentOrganizationID: orgId });
      // upgradeProfile(orgId, (orgUid) => { 
      //   urlOrg ? navigate(path.join('/'), window.location.search) : navigate(orgUid);
      //   setTimeout(() => setIsUpgraded(true), 750);
      // }, () => navigate('/'));
  }}, [isAuthorized]);

  return <>
    <MessagesContainer />
    { isAuthorized && <Home /> || <SignIn isAuthorized={ isAuthorizedState } /> }
  </>
}
