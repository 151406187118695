import { useEffect, useRef } from "react";
import './Tabber.css';

export function Tabber({tabIndex} : { tabIndex?: number}) {
    const tabRef = useRef<HTMLElement>(null);

    useEffect(() => {
        const elem = tabRef.current?.parentElement;
        elem?.setAttribute('tabIndex', tabIndex?.toString() || '0');
        elem?.classList.add('tabIndexActivated');

        function onKeyUp(event: KeyboardEvent) {
            switch (event.key) {
                case 'Enter':
                    elem?.click();        
                    break;
                case 'Escape':
                    elem?.removeAttribute('tabIndex');
                    setTimeout(() => elem?.setAttribute('tabIndex', tabIndex?.toString() || '0'), 0);                    
                    break;
            }
        }

        elem?.addEventListener('keyup', onKeyUp);

        return () => {
            elem?.removeAttribute('tabIndex');
            elem?.classList.remove('tabIndexActivated')
            elem?.removeEventListener('keyup', onKeyUp);  
        }; 
    }, []);
    return (<i style={{display: 'none'}} ref={tabRef}></i>)
}