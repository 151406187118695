import React, { CSSProperties, MouseEvent } from 'react';
import './IconButton.css';
import { Icons } from '../../SVGIcons/icons';
import { ButtonBase, IButtonBase } from '../AbstractButton/ButtonBase';

interface IIconButton extends IButtonBase {
    /**
     * The name of the icon to be displayed. This should correspond to a class name defined
     * in the icons.css file, which applies the appropriate SVG background image.
     * Example values: 'delete', 'edit', etc.
     */
    icon: Icons;

    /**
     * Optional size for the icon, which will be used to set the CSS variable '--size'.
     * This determines the width and height of the icon.
     * Example value: '2em', '24px', etc.
     */
    size?: string;
}

/**
 * `IconButton` Component - Renders an icon button with customizable properties and styles.
 * 
 * @component
 * @param {IIconButton} props - The component's props.
 * 
 * @returns {JSX.Element} The rendered IconButton component.
 * 
 * @example
 * // Example usage:
 * <IconButton icon="delete" size="2em" color="red" hoverColor="blue" onClick={handleClick} />
 */
export function IconButton(props: IIconButton) {
    const { icon, size, ...restProps } = props;
    const cssVariables = { ...(size && { '--size': size }) } as CSSProperties;

    return (
        <ButtonBase
            {...restProps}
            classes={`icon svgIcon ${icon} ${props.classes || ''}`}
            style={{...props.style, ...cssVariables}}
        >
            {null}
        </ButtonBase>
    );
}
