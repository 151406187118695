import { ActiveStore } from "../../state/ActiveStore";

export enum AuthStates {
    SignIn                      = 'SignIn',
    SignUp                      = 'SignUp',
    ResetPassword               = 'ResetPassword',
    VerificationRequired        = 'VerificationRequired',
    AccountVerified             = 'AccountVerified',
    ResetCode                   = 'ResetCode',
    ChangePassword              = 'ChangePassword',
    SendEmail                   = 'SendEmail',
    Invite                      = 'Invite',
}