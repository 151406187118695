import axios, { AxiosRequestConfig } from "axios";
import apiClient from "./apiclient";
import * as Auth from "./auth";

declare global {
    interface Date {
        YYYYMMDD(): number;
        YYYY_MM_DD(): string;
        DDMMYYYY(): string;
        setYYYY_MM_DD(value: string): Date;
        setDDMMYYYY(value: string): Date;
        addDays(days: number): Date;
        getWeekNumber(): number;
    }
}

export function getDDMMYYYY(value?: string) {
    if (!value) return '';
    const dt = value.split('-');
    return `${dt[2]}.${dt[1]}.${dt[0]}`;
}

export function getYYYY_MM_DD(value?: string) {
    if (!value) return '';
    const dt = value.split('.');
    return `${dt[2]}-${dt[1]}-${dt[0]}`;
}

export function rsubstr(str: string, length: number) {
    return str.substring(str.length - length);
}

Date.prototype.getWeekNumber = function (): number {
    return getWeekNumber(this);
};


/* returns string dd.mm.yyyy with leading zeros */
Date.prototype.DDMMYYYY = function (): string {
    return rsubstr('0' + this.getDate().toString(), 2) + '.' +
        rsubstr('0' + (this.getMonth() + 1).toString(), 2) + '.' +
        this.getFullYear().toString();
};

Date.prototype.setDDMMYYYY = function (value: string): Date {
    const dateParts = value.match(/^(\d{2})\.(\d{2})\.(\d{4})$/);
    if (!dateParts) {
        throw new Error(`Invalid date format. Expected format: dd.mm.yyyy. Real value: "${value}"`);
    }
    const day = parseInt(dateParts[1], 10);
    const month = parseInt(dateParts[2], 10);
    const year = parseInt(dateParts[3], 10);

    if (month < 1 || month > 12 || day < 1 || day > 31) {
        throw new Error(`Invalid date values. Real value: ${value}`);
    }

    this.setTime((new Date(year, month - 1, day)).getTime());

    return this;
};

Date.prototype.YYYYMMDD = function (): number {
    return parseInt(this.getFullYear().toString() +
        rsubstr('0' + (this.getMonth() + 1).toString(), 2) +
        rsubstr('0' + this.getDate().toString(), 2));
};

Date.prototype.YYYY_MM_DD = function (): string {
    return this.getFullYear().toString() + '-' +
        rsubstr('0' + (this.getMonth() + 1).toString(), 2) + '-' +
        rsubstr('0' + this.getDate().toString(), 2);
};

Date.prototype.addDays = function (days: number) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.setYYYY_MM_DD = function (value: string): Date {
    const dateParts = value.match(/^(\d{4})\-(\d{2})\-(\d{2})$/);  
    if (!dateParts) {
      throw new Error(`Invalid date format. Expected format: yyyy-mm-dd. Real value: "${value}"`);
    }
    const year  = parseInt(dateParts[1], 10);
    const month = parseInt(dateParts[2], 10);
    const day   = parseInt(dateParts[3], 10);
  
    if (month < 1 || month > 12 || day < 1 || day > 31) {
      throw new Error(`Invalid date values. Real value: ${value}`);
    }
  
    this.setTime((new Date(year, month-1, day)).getTime());

    return this;
};

export function dateArrayToYYYYMMDDArray(dates: Date[]): number[] {
    return dates.map<number>((date) => date.YYYYMMDD());
}

export function Jan01(year?: number): string {
    return (year || new Date().getFullYear()) + '-01-01';
};

export function Dec31(year?: number): string {
    return (year || new Date().getFullYear()) + '-12-31';
};

// weekendsOfWeek = 0 - Sunday, 1 - Monday ... 6 - Saturday
export function allHolidays(fromYear: number, toYear: number, weekendsOfWeek: number[], extraHolidays: Date[], extraWorkDays: Date[]): Date[] {
    toYear = toYear > fromYear ? toYear : fromYear;
    const end: Date = new Date(toYear + "-12-31");
    const result: Date[] = [];

    const extraHolidaysUTC = dateArrayToYYYYMMDDArray(extraHolidays);
    const extraWorkDaysUTC = dateArrayToYYYYMMDDArray(extraWorkDays);
    let current = new Date(fromYear + "-01-01");
    while (current <= end) {
        const currentYYYYMMDD = current.YYYYMMDD();
        (weekendsOfWeek.indexOf(current.getDay()) > -1 || extraHolidaysUTC.indexOf(currentYYYYMMDD) > -1) &&
            extraWorkDaysUTC.indexOf(currentYYYYMMDD) === -1 &&
            result.push(new Date(current));

        current.setDate(current.getDate() + 1);
    };
    return result;
}

export const compareUTC = (dt: Date, m: number) => {
    const h = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate());
    return h === m;
};

export const formatDate = (date?: Date): string => {
    if (!date) return '';
    let loc = Auth.getLocale();
    if (loc === null) {
        loc = "en";
    }
    return date.toLocaleString(loc, { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })
}

export function getWeekNumber(date: Date): number {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const daysUntilDate = Math.round((date.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil((daysUntilDate + (startOfYear.getDay() || 7)) / 7);
    return weekNumber;
}

export function cAttr(add: any, key: string, value?: string) {
    return add ? { [key]: value || '' } : {}
}

export const uploadFile = async (url: string, file: File, uploadProgressCallback?: (event: ProgressEvent) => void, method?: string, uploadHeaders?: Record<string, string>) => {
    const postData = new FormData();
    postData.append('file', file);
    postData.append('fileName', file.name);
    const config: AxiosRequestConfig = { headers: { 'Content-Type': 'multipart/form-data', ...(uploadHeaders || {}) }, onUploadProgress: uploadProgressCallback };

    const res = (method || '').toUpperCase() === 'PUT' ? await apiClient.put(url, postData, config) : await apiClient.post(url, postData, config);
    return (res.status === 200) && res?.data?.binaryObjectId && { binaryObjectId: res?.data?.binaryObjectId } || "FAIL";
}

export const uploadFileToCloud = async (url: string, file: File, uploadProgressCallback?: (event: ProgressEvent) => void, method?: string, uploadHeaders?: Record<string, string>) => {
    const headers = { ...uploadHeaders, 'Content-Type': file.type };
    const config = { headers, onUploadProgress: uploadProgressCallback };
    const data = new Blob([file], { type: file.type });
    const res = (method || '').toUpperCase() === 'PUT' ? await axios.put(url, data, config) : await axios.post(url, data, config);
    return (res.status === 200) && res?.data?.binaryObjectId && { binaryObjectId: res?.data?.binaryObjectId } || "FAIL";
}