import React, { ReactNode, CSSProperties } from 'react';
import { Attr } from '../../../../utils/utils';
import './Panels.css'; 
import './StretchedPanels.css';
import '../../bcUsers.css';

/**
 * Interface for a flat wide panel component.
 */
interface IFlatWidePanel {
  /** The children nodes to be rendered within the panel. */
  children: ReactNode;
  
  /** 
   * The vertical alignment of content inside of the panel. 
   * Possible values: 'top', 'center', 'bottom'.
   */
  vertical?: 'top' | 'center' | 'bottom';
  
  /** Additional styles to be applied to the panel. */
  style?: CSSProperties;

  classes?: string;
}

/**
 * Interface for a wide panel component, extending IFlatWidePanel.
 */
interface IWidePanel extends IFlatWidePanel {
  /** 
   * A boolean indicating whether to display edges on the panel.
   */
  edges?: boolean;
}

/**
 * WidePanel Component - Puts the content to the left and to the right edges of the panel accordingly.
 *
 * @component
 * @param {boolean} [edges=true] - Whether to include edges (border, padding, and margin) in the panel.
 * @param {CSSProperties} [style] - Additional CSS styles to apply to the panel.
 *
 * @returns {JSX.Element} The rendered WidePanel component.
 *
 * @example
 * // Example usage:
 * <WidePanel edges={false} style={{ backgroundColor: 'lightgray' }}>
 *   Your content goes here
 * </WidePanel>
 */
export function WidePanel({ children, edges = true, vertical = 'top', style, classes }: IWidePanel ) {
    return (
        <div 
            className={`wide horizontal panel ${edges ? 'withEdges' : ''} ${classes || ''}`} 
            { ...Attr('vertical', vertical, vertical) }
            style={style}
        >
            {children}
        </div>
    );
}

/**
 * FlatWidePanel Component - A flat panel that puts the content to the left and to the right edges of the panel accordingly without borders, margins, and paddings.
 *
 * @component
 * @param {CSSProperties} [style] - Additional CSS styles to apply to the panel.
 *
 * @returns {JSX.Element} The rendered FlatWidePanel component.
 *
 * @example
 * // Example usage:
 * <FlatWidePanel style={{ backgroundColor: 'lightgray' }}>
 *   Your content goes here
 * </FlatWidePanel>
 */
export function FlatWidePanel({ children, style, vertical = 'top', classes }: IFlatWidePanel) {
    return (
      <WidePanel style={style} edges={false} vertical={vertical} classes={classes}>
        {children}
      </WidePanel>
    );
}


/**
 * Interface for the props of the TallPanel component.
 */
interface IFlatTallPanel {
  /** The children nodes to be rendered inside the tall panel. */
  children: ReactNode;

  /**
   * A boolean indicating whether to display edges on the tall panel.
   * Default value is true.
   */
  edges?: boolean;

  /**
   * The horizontal alignment of the tall panel.
   * Possible values: 'left', 'center', 'right'.
   * Default value is 'left'.
   */
  horizontal?: 'left' | 'center' | 'right';

  /** Additional styles to be applied to the tall panel. */
  style?: CSSProperties;

  classes?: string;
}

/** 
 * Interface for the props of the TallPanel component, extending IFlatTallPanel.
*/
interface ITallPanel extends IFlatTallPanel {
  /**
   * A boolean indicating whether to display edges on the tall panel.
   * Default value is true.
   */
  edges?: boolean;
}

/**
 * TallPanel component renders a vertical panel with a height equal to its parent's height.
 * The content inside the panel extends both to the top and bottom, providing a flexible layout.
 * It offers options to display edges, set horizontal alignment, and apply custom styles.
 *
 * @param {ReactNode} children - The content or components to be rendered inside the tall panel.
 * @param {boolean} [edges=true] - Indicates whether to display edges on the tall panel. Default is true.
 * @param {'left' | 'center' | 'right'} [horizontal='left'] - The horizontal alignment of the tall panel.
 * @param {CSSProperties} [style] - Additional styles to be applied to the tall panel.
 * @returns {ReactNode} The rendered TallPanel component.
 */
export function TallPanel({ children, edges = true, horizontal = 'left', style, classes } : ITallPanel) {
  return (
    <div
      className={`tall vertical panel ${edges ? 'withEdges' : ''} ${classes}`}
      {...Attr('horizontal', horizontal, horizontal)}
      style={{height: '100%', ...style}}
    >
      {children}
    </div>
  );
}

/**
 * FlatTallPanel component renders a vertically aligned panel with a height equal to its parent's height.
 * This component is a wrapper around the TallPanel, providing a flat appearance by hiding edges.
 *
 * @param {ReactNode} children - The content or components to be rendered inside the flat tall panel.
 * @param {CSSProperties} [style] - Additional styles to be applied to the flat tall panel.
 * @param {'left' | 'center' | 'right'} [horizontal='left'] - The horizontal alignment of the flat tall panel.
 * @returns {ReactNode} The rendered FlatTallPanel component.
 */
export function FlatTallPanel({ children, style, horizontal = 'left', classes }: IFlatTallPanel) {
  return (
    <TallPanel style={style} edges={false} horizontal={horizontal} classes={classes}>
      {children}
    </TallPanel>
  );
}

