import { Store } from "effector";
import { ActiveStore, createActiveStore } from "../../state/ActiveStore";
import { useStore } from "effector-react";

export interface Message<messageType = any, answerType = any> {
    message: messageType;
    callback?: (answer: answerType) => void;
}

export type SenderedMessage<messageType = any> = { sender: any, value: messageType };

export type SenderMessage<messageType = any, answerType = any> = Message<SenderedMessage<messageType>, answerType>;

const messages: Record<string, Record<string, ActiveStore<Message>>> = {};

export function listen<messageType = any>(name: string, listener?: string): Store<Message<messageType>> {
    const listeners = messages[name] || (messages[name] = {});
    const validListener = listener?.replaceAll('*', '').trim() || '*';
    return (listeners[validListener] || (listeners[validListener] = createActiveStore<Message<messageType>>({} as Message))).store;
}

export function useListen<messageType = any>(messageType: string, listenerName?: string): Message<messageType> {
    return useStore(listen<messageType>(messageType, listenerName));
}

export function postMessage<messageType = any, answerType = any>(name: string, message: messageType, listener: string = '', callback?: (answer: answerType) => void) {
    const listeners = messages[name];
    if (!listeners) return false;
    const validListener = listener?.replaceAll('*', '').trim() || '*';
    (validListener === '*' && Object.keys(listeners) || [validListener]).forEach(listenerItem =>
        listeners[listenerItem]?.set({
            message: message, 
            callback: (answer: answerType) => callback && setTimeout(callback, 0, answer)
    }));
    return true;
}