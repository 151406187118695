import { imgs, urlModuleOrder } from '../../../components/AppComponents/variables';
import { useEffect, useState } from 'react';
import './Menu.css'
import { t } from "../../../locale";
import * as Auth from "../../../utils/auth";
import { explodePath, navigate, useNavigation } from '../../../components/BCComponents/Navigation';
import { Tabber } from '../../../components/BCComponents/Controls/Tabber/Tabber';
import { alert } from '../../../components/BCComponents/Controls/MessageBox/MessageBox';

enum MenuItems {
    Home         = 'Home',
    Organization = 'Organizations',
    // Licenses     = 'Licenses',
    Security     = 'AdminSecurity',
}

const locationMap: { [key in MenuItems]: string } = {
    [MenuItems.Home]: '/:org',
    [MenuItems.Organization]:   '/:org/organizations',
    // [MenuItems.Licenses]:       '/:org/licenses',
    [MenuItems.Security]:       '/:org/security',
};

const delimetersBefore: Partial<{ [key in MenuItems]: boolean }> = {
}

const delimetersAfter: Partial<{ [key in MenuItems]: boolean }> = {
    // [MenuItems.Security]: true,
}

const urlToMenuMap: Record<string, MenuItems> = {
    'organizations': MenuItems.Organization,
    'portal/organizations': MenuItems.Organization,
    // 'licenses': MenuItems.Licenses,
    'home':     MenuItems.Home,
    'security': MenuItems.Security,
    'platform': MenuItems.Security,
};

export function MainMenu() {
    const url = useNavigation()[urlModuleOrder] || 'home';
    const urlSuffix = useNavigation()[urlModuleOrder + 1];
    const activeMenuItem = urlToMenuMap[url] || urlToMenuMap[url + '/' + urlSuffix];
    const [expanded, setExpanded] = useState((localStorage.menuExpanded || 'false').toLowerCase() === 'true');

    const handleClick = (menuItem: MenuItems) => {
        const locale = Auth.getLocale() || 'en';
        const urlSupport = `${locale === 'de' ? 'de/standard-support/' : 'standard-support/'}` || '';
        locationMap[menuItem] === 'support' && window.open('https://bimcosmos.com/' + urlSupport, '_blank') ||
        //   navigate(locationMap[menuItem].replace(':org', Auth.getOrgUid() || 'adminDesk')); 
          navigate(locationMap[menuItem].replace(':org', '_admin')); 
        };
   
    return (
        <div className={`leftMenu svgIcon ${expanded ? '' : 'collapsed'}`}>
            <div>
                <a href={`/portal-old/${Auth.getOrgUid() || ''}`+'/profile'} target="_blank">
                    <img className='menuLogo' src={`${imgs}/menuLogo.svg`} />
                </a>
            </div>
            <img className='menuLogo collapsed' src={`${imgs}/bimcosmos.svg`} />
            
            {Object.values(MenuItems).map((menuItem, index) => (<span key={index}>
                { delimetersBefore[menuItem] && <div className='hdelimiter'></div> }
                <div 
                    className={`item ${menuItem.toLowerCase()} ${activeMenuItem === menuItem ? 'selected' : ''}`} 
                    onClick={() => handleClick(menuItem as MenuItems)}
                >
                    <Tabber />
                    <span className="label">{t(`%${menuItem}%`)}</span>
                </div>
                { delimetersAfter[menuItem] && <div className='hdelimiter'></div> }
            </span>))}

            <div 
                className="item hide" 
                onClick={() => setExpanded(localStorage.menuExpanded = !((localStorage.menuExpanded || 'false').toLowerCase() === 'true')) }
            >
                <Tabber />
                <span className="label labelHide">{t('%Hide%')}</span>
            </div>
        </div>
    );
}