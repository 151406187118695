import { useEffect, useRef, useState } from 'react';
import './Permissions.css'
import { IPermission, IPermissionGroup, IPermissionsData } from '../../../components/AppComponents/types';
import { SyncState } from '../../../state/SyncStore';
import { RetrieveDataset } from '../../../utils/DataSets/DataSets';
import { FlatHPanel, FlatVPanel } from '../../../components/BCComponents/Layouts/Panels/ArrangementPanels';
import { Header } from '../../../components/BCComponents/Layouts/Layouts';
import { FlatWidePanel } from '../../../components/BCComponents/Layouts/Panels/StretchedPanels';
import { FlatRightPanel } from '../../../components/BCComponents/Layouts/Panels/RightPanel';
import { Button } from '../../../components/BCComponents/Controls/Buttons/Buttons';
import { DCell, HCell, RData, RHeader, RRow, RTable } from '../../../components/BCComponents/Controls/Grids/RTable';
import { IconButton } from '../../../components/BCComponents/Controls/Buttons/IconButton/IconButton';
import { Checkbox } from '../../../components/BCComponents/Controls/Checkbox/Checkbox';
import { cssVar } from '../../../utils/utils';
import { t } from '../../../locale';
import { getUrlId, navigateInOrg } from '../../../components/AppComponents/funcs';


const simpleRoot: IPermissionGroup = {
    id: "root",
    name: "Root Group",
    presentationOrder: 0,
    isGroup: true,
    children: [],
    level: -1,
    expanded: true,
    grantedChildTotalCount: 0,
};

export function Permissions() {
    const roleId = getUrlId();
    const [root, setRoot] = useState<IPermissionGroup | null>(null);
    const [_, setExpandingState] = useState({});
    const [data, setData] = useState<IPermissionsData | null>(null);
    const [onlyGranted, setOnlyGranted] = useState(true);
    const cachedData = useRef<IPermissionsData | null>(null);
    const [reload, setReload] = useState({});
    const version = useRef(1);
    const sync = useRef<SyncState<any, any> | null>(null);

    const [roleName, setRoleName] = useState('');

    useEffect(() => {
        RetrieveDataset({ source: 'roles/${roleId}/permissions', serviceDomain: 'aaa', params: { roleId } }). 
        then(result => { 
            cachedData.current = result.dataSet as any as IPermissionsData;
            sync.current = result.sync;
            setData({ ...cachedData.current });
        });

        RetrieveDataset({source: 'roles', serviceDomain: 'aaa', sliceId: roleId})
        .then(result => setRoleName((result.dataSet as any).name || ''));
    }, [roleId, reload]);

    useEffect(() => {
            if (!data) return;
            const _root: IPermissionGroup = { ...simpleRoot };
            _root.children = [];
            
            const groupsById: { [key: string]: IPermissionGroup } = {};
            
            data.permissionGroups.forEach((group: IPermissionGroup) => {
                group.isGroup = true;
                group.expanded = true;
                group.children = [];
                groupsById[group.id] = group;
            });

            data.permissionGroups.forEach((group: IPermissionGroup) => {
                if (group.parentId) {
                    const parentGroup = groupsById[group.parentId];
                    group.parent = parentGroup;
                    parentGroup.children.push(group);
                } else {
                    group.parent = _root;
                    _root.children.push(group);
                }
            });
    
            data.permissions.forEach((permission: IPermission) => {
                permission.isGroup = false;
                if (permission.groupId && groupsById[permission.groupId]) {
                    const parentGroup = groupsById[permission.groupId];
                    permission.parent = parentGroup;
                    parentGroup.children.push(permission);
                } else {
                    permission.parent = _root;
                    _root.children.push(permission);
                }
            });
    
            setRoot(_root);
            version.current++;
    }, [data]);

    function flattenItems(items: (IPermission | IPermissionGroup)[], onlyGranted: boolean, level = 0): { flattenedItems: (IPermission | IPermissionGroup)[], grantedCount: number } {
        let grantedCount = 0;
    
        const flattenedItems = items.reduce((acc: (IPermission | IPermissionGroup)[], item) => {
            item.level = level;
            const group = item as IPermissionGroup;
    
            if (group.children) {
                const result = flattenItems(group.children, onlyGranted, level + 1);
                group.grantedChildTotalCount = result.grantedCount;
                acc.push(item); 
                grantedCount += result.grantedCount;
                if (group.expanded) {
                    acc = acc.concat(result.flattenedItems);
                }
            } else {
                if (!onlyGranted || (item as IPermission).isGranted) {
                    acc.push(item);
                    if ((item as IPermission).isGranted) {
                        grantedCount++;
                    }
                }
            }
            return acc;
        }, []);
    
        return { flattenedItems, grantedCount };
    }
    
    const visibleItems = root ? flattenItems(root.children, onlyGranted, 0) : { flattenedItems: [], grantedCount: 0};
    
    function permissionExpandClick(group: IPermissionGroup) {
        group.expanded = !group.expanded;
        setExpandingState({});
    }

    return (
        <FlatVPanel key={version.current} style={{ overflowY: 'auto', height: '100%' }}>
            <FlatVPanel width='100%'>
                <Header back={ { label: t('%Back to roles%'), onClick: () => navigateInOrg(`security`, { tab: 'roles'}) } } label={roleName} />

                <FlatWidePanel vertical='center' style={ { paddingBottom: '1em' } }>
                    <div style={{fontWeight: 'bold', color: '#292929' }}>{t('%Permissions%')}</div>
                    <FlatRightPanel style={{ minHeight: '2em'}}>
                        { 
                            onlyGranted && <Button icon='edit' iconColor='#FF693A' onClick={() => setOnlyGranted(false) }>{t('%Edit%')}</Button> || 
                            <>
                                <Button icon='backup' classes='rounded red' onClick={() => {
                                    sync.current?.updatePut({
                                        version: data?.version || 0, 
                                        permissionIds:  visibleItems.flattenedItems
                                                        .filter((item: IPermission) => item.isGranted)
                                                        .map(item => item.id)}).then(() => setReload({})).catch(() => {
                                                            cachedData.current && setData({ ...cachedData.current })                        
                                                        })
                                    setOnlyGranted(true);
                                }}>{t('%Save%')}</Button>
                                
                                <Button icon='cancel' classes='rounded' onClick={() => { 
                                    setOnlyGranted(true);
                                    cachedData.current && setData({ ...cachedData.current });
                                }}>{t('%Cancel%')}</Button>
                            </>
                        }
                    </FlatRightPanel>
                </FlatWidePanel>
            </FlatVPanel>
            <FlatHPanel classes='thinScrollbar'>
                <RTable>
                    <RHeader>
                        <HCell>{t('%Name%')}</HCell>
                        <HCell>{t('%Type%')}</HCell>
                        <HCell>{t('%Permission ID%')}</HCell>
                    </RHeader>
                    <RData>
                        { visibleItems.flattenedItems.map((item: IPermission | IPermissionGroup, index) => (
                            onlyGranted && item.isGroup && (item as IPermissionGroup).grantedChildTotalCount === 0 ? null : 
                            <RRow key={index} classes={`permission ${item.isGroup ? 'group' : ''} ${(item as IPermissionGroup).children?.length > 0 ? 'hasChild' : ''}`}>
                                <DCell classes='permissionCell' style={{ ...(cssVar('--level', item.level.toString())) }}>
                                    <div className='permissionCellContent'>{ 
                                        item.isGroup ? 
                                            <><IconButton 
                                                classes={`expander${(item as IPermissionGroup).expanded && ' expanded' || ''}`} 
                                                icon='arrowDown' 
                                                onClick={ () => permissionExpandClick(item as IPermissionGroup) } 
                                            />{ item.name }</>: <>
                                                <Checkbox 
                                                    disabled={onlyGranted} 
                                                    kind='checkbox' 
                                                    defaultValue={(item as IPermission).isGranted} 
                                                    label={item.name}
                                                    onClick={ checked => (item as IPermission).isGranted = checked }
                                                /> 
                                            </>    
                                    }</div>    
                                </DCell>
                                <DCell>
                                    {!item.isGroup ? (item as IPermission).isSystem && t('%System%') || t('%Non system%') : ''}
                                </DCell>
                                <DCell>
                                    {!item.isGroup && item.id || ''}
                                </DCell>
                            </RRow>
                        )) }
                    </RData>
                </RTable>
            </FlatHPanel>
        </FlatVPanel>
    );
}