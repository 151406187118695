import { CSSProperties, ReactNode } from "react";
import { cssProp } from "../../../../utils/utils";
import '../../bcUsers.css';

interface ICommonPanel {
    children: ReactNode | ReactNode[];

    proportion?: number;
    style?: CSSProperties;
    verticalAlign?: 'top' | 'center' | 'bottom';
    horizontalAlign?: 'left' | 'center' | 'right' | 'stretch';
    width?: string;
    height?: string;
    classes?: string; 
    ref?: React.LegacyRef<HTMLDivElement> | undefined;
}

interface IContentRendering {
    contentRendering?: 'fromLeftToRight' | 'fromUpToDown';
}

interface IEdges {
    edges?: boolean;
}

type IFlatPanel = ICommonPanel & IContentRendering;

type IOrientedPanel = ICommonPanel & IEdges;

type IPanel = ICommonPanel & IContentRendering & IEdges;

export function Panel( { children, ref, style, classes, edges, proportion = 0, height, width, horizontalAlign = 'left', verticalAlign = 'top', contentRendering = 'fromUpToDown' } : IPanel) {
    const cssVariables: CSSProperties = {
        ...(cssProp('width', width, width)),
        ...(cssProp('height', height, height)),
        ...(cssProp('flexDirection', true, contentRendering === 'fromLeftToRight' && 'row' || 'column')),

        ...(cssProp('alignItems', true, 
                        contentRendering === 'fromUpToDown' && (
                            (horizontalAlign === 'left' && 'flex-start') ||
                            (horizontalAlign === 'center' && 'center') ||
                            (horizontalAlign === 'right' && 'flex-end') ||
                            'stretch'
                        ) || (
                            (verticalAlign === 'top' && 'flex-start') ||
                            (verticalAlign === 'center' && 'center') ||
                            (verticalAlign === 'bottom' && 'flex-end') ||
                            'stretch'
                        )
        )),

        ...(cssProp('justifyContent', true, 
                        contentRendering === 'fromLeftToRight' && (
                            (horizontalAlign === 'left' && 'flex-start') ||
                            (horizontalAlign === 'center' && 'center') ||
                            (horizontalAlign === 'right' && 'flex-end') ||
                            'stretch'
                        ) || (
                            (verticalAlign === 'top' && 'flex-start') ||
                            (verticalAlign === 'center' && 'center') ||
                            (verticalAlign === 'bottom' && 'flex-end') ||
                            'stretch'
                        )
        ))
    };

    return (
        <div ref={ ref } className={`panel ${edges ? 'withEdges' : ''} ${classes || ''}`} style={{...style, ...cssVariables, flexGrow: proportion  > 0 && proportion || 0 }}>
            { children }
        </div>
    )
}

export function FlatPanel(props : IFlatPanel) {
    return (<Panel {...props} edges={false}>
        { props.children }
    </Panel>)
}

export function VPanel(props : IOrientedPanel) {
    return (<Panel {...props} contentRendering="fromUpToDown" edges={true}>
        { props.children }
    </Panel>)
}

export function FlatVPanel(props : ICommonPanel) {
    return (<Panel {...props} contentRendering="fromUpToDown" edges={false}>
        { props.children }
    </Panel>)
}

export function HPanel(props : IOrientedPanel) {
    return (<Panel {...props} contentRendering="fromLeftToRight" edges={true}>
        { props.children }
    </Panel>)
}

export function FlatHPanel(props : ICommonPanel) {
    return (<Panel {...props} contentRendering="fromLeftToRight" edges={false}>
        { props.children }
    </Panel>)
}

interface IHVPanelBase {
    children: ReactNode | ReactNode[];
    style?: CSSProperties;
    classes?: string;
}

interface IVertical extends IHVPanelBase {
    startArrange?: 'fromTop' | 'fromBottom';
}

export function Vertical({ children, style, startArrange = 'fromTop', classes }: IVertical) {
    return (
        <div className={`panel vertical cover ${startArrange === 'fromBottom' ? 'end' : ''} ${classes || ''}`} style={style}>
            { children }
        </div>
    )
}

interface IHorizontal extends IHVPanelBase {
    startArrange?: 'fromLeft' | 'fromRight';
}

export function Horizontal({ children, style, startArrange = 'fromLeft', classes }: IHorizontal) {
    return (
        <div className={`panel horizontal cover ${startArrange === 'fromRight' ? 'end' : ''} ${classes || ''}`} style={style}>
            {children}
        </div>
    )
}