import { Countries, GridName, ServiceRestDomains } from "./types";
import * as Auth from "../../../src/utils/auth";
import { NavigationContext, explodePath, getURLParam, navigate } from "../BCComponents/Navigation";
import { useContext } from "react";
import { urlIdOrder } from "./variables";

export function GridURL(name: GridName, domain?: ServiceRestDomains, module?: string) {
    return `/${domain || 'pfm'}/api/v1${ module ? '/' + module : ''}/grid/${name}`;
};
  
export function SyncStateURL(name: string, excludeID?: boolean, serviceDomain?: string, searchParams?: Object) {
    return `/${serviceDomain || 'pfm'}/api/v1/${name}` + `${ excludeID ? '' : '/${ID}'}` + URLSearchFromParams(searchParams);
}

export function SafeJSONParse(json: string) {
    try {
        return JSON.parse(json);
    } catch (error) {
        return null;
    }
}

export function GetCountry(id?: number) {
    return (Auth.getCountries() as Countries)?.items.find(c => c.id === id);
}

// export function GetAnyRecordFromCache(id: number) {
//     return (Auth.getSomethings() as Somethings)?.items.find(c => c.id === id);
// }
 
export function navigateInOrg(url: string, search: Object | string = {}, ignorePushState: boolean = false): void {
    navigate(`/${Auth.getOrgUid() || ''}/${url.replace(/^\/+/, '')}`, search, ignorePushState);
}

export function URLSearchFromParams(params?: Record<string, any>): string {
    const queryParams = paramsToQuery(params);
    return queryParams && `?${queryParams}` || '';
}

export function paramsToQuery(params?: Record<string, any>): string {
    return Object.keys(params || {}).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params![key])}`).join('&');
}

export function getUrlId() {
    const id = explodePath()[urlIdOrder];
    return id && /^\d+$/.test(id) && parseInt(id) || 0;
}
