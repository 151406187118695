import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import * as Auth from "./auth";

const apiClient = axios.create({
	baseURL: "",
	headers: { "Content-type": "application/json" },
	timeout: process.env.AXIOS_TIMEOUT ? Number(process.env.AXIOS_TIMEOUT) : 600000
});

apiClient.interceptors.request.use(
	req => {
		const auth = Auth.getAccessToken()
		auth && (req.headers['Authorization'] = Auth.getAccessToken());
		req.headers['X-Bc-Language'] = Auth.getLocale(); //"en";
		req.headers['X-Bc-Request-Id'] = uuidv4();
		return req;
	},
	err => { return Promise.reject(err); }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
	failedQueue.forEach((prom) => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	failedQueue = [];
};

apiClient.interceptors.response.use(
	res => { return res; },
	err => {
		const originalReq = err.config;
		const status = err.response ? err.response.status : null;
		if (status === 401 && !originalReq._retry) {
			if (isRefreshing) {
				return new Promise(function (resolve, reject) {
					failedQueue.unshift({ resolve, reject });
				})
					.then(token => {
						originalReq.headers["Authorization"] = token;
						originalReq.headers['X-Bc-Language'] = Auth.getLocale();
						originalReq.headers['X-Bc-Request-Id'] = uuidv4();
						return Promise.resolve(axios(originalReq));
					})
					.catch(err => {
						return Promise.reject(err);
					});
			}

			originalReq._retry = true;
			isRefreshing = true;

			return new Promise(function (resolve, reject) {
				axios
					.post("/aaa/api/v1/auth/tokens/refresh", {
						refreshToken: Auth.getRefreshToken()
					}, {
						headers: {
							'X-Bc-Request-Id': uuidv4(),
						}
					})
					.then(response => {
						Auth.setAccessToken(response.data.accessToken);
						Auth.setRefreshToken(response.data.refreshToken);
						// fillPerms(response.data.allowedPermissions);
						Auth.setUserPerms(response.data.allowedPermissions);
						processQueue(null, response.data.accessToken);
						originalReq.headers["Authorization"] = Auth.getAccessToken();
						originalReq.headers['X-Bc-Language'] = Auth.getLocale(); //"en";
						originalReq.headers['X-Bc-Request-Id'] = uuidv4();
						resolve(axios(originalReq));
					})
					.catch(err => {
						processQueue(err, null);
						reject(err);
						Auth.delTokens();
						window.location.replace("/");
					})
					.finally(() => {
						isRefreshing = false;
					});
			});

		}
		return Promise.reject(err);
	}
)

export default apiClient;