import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AuthStates } from './LoginTypes';
import { useStore } from 'effector-react';
import { LoginContext } from './LoginContext';
import { Store } from 'effector';
import { ActiveStore, createActiveStore } from '../../state/ActiveStore';
import { t } from "../../locale";
import { imgs } from '../../components/AppComponents/variables';
import { doResetPassword, doAskResetPassword } from './LoginUtils';
import { IsValidPassword, PasswordMust, passwordFailReason } from './Passwords';
import { isValidEmail } from '../../utils/utils';

interface ISignUpFormData {
    password: string;
    repeatPassword: string;
}

interface IEmailFormData {
    email: string;
}


export function SendEmail() {
    const context = useContext(LoginContext)!;
    const [credentialsError, setCredentialsError] = useState(false);

    const formData = useMemo<ActiveStore<IEmailFormData>>(() => {
        return createActiveStore<IEmailFormData>({
            email: '',
        });
    }, []);

    function onComplete(success: boolean) {
        if (success) {
            context.authState.set(AuthStates.ResetPassword);
        } else {
            setCredentialsError(!success);
        }
    }


    return (
        <>
            <div className="signInLabel">{t('%Reset password%')}</div>

            <div className="vMargin15"></div>

            <div className='line16'>
                {t('%Enter the email associated with your account and we’ll send you a reset link%')}
            </div>            

            <div className="vMargin15"></div>

            <input
                autoFocus
                tabIndex={1}
                type="text"
                className="editField grayBackground"
                placeholder={t('%Email%')}
                onChange={e => formData.set({ ...formData.get(), email: e.target.value })}
            />

            <div className="vMargin15"></div>

            <SendButton formData={formData.store} onComplete={onComplete} onSubmit={() => setCredentialsError(false)}  tbi={3} />

            {credentialsError && <div className="infoMessageBlock font09">{t('%Looks like this email doesn’t exist in our system. Try again or%')} <span className='link'>{t('%contact support%')}</span></div>}

            <div className="vMargin15"></div>

            <div className="newTo">
                {t('%Back to%')}&nbsp;&nbsp;&nbsp;
                <span 
                    tabIndex={6}
                    className="link"
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === "Enter" && context.authState.set(AuthStates.SignIn)}
                    onClick={ () => context.authState.set(AuthStates.SignIn) }
                >{t('%Sign in%')}</span>&nbsp;&nbsp;&nbsp;
                {t('%page%')}
            </div>
        </>
    );
}


export function ChangePassword() {

    const code = new URLSearchParams(window.location.search).get('code');

    const context   = useContext(LoginContext)!;
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordRepeatRef = useRef<HTMLInputElement>(null);

    const [formData, passwordsEqual, passwordFail] = useMemo<[ActiveStore<ISignUpFormData>, ActiveStore<boolean>, ActiveStore<passwordFailReason[]>]>(() => {
        return [
            createActiveStore<ISignUpFormData>({ 
                password: '',
                repeatPassword: '',
            }),
            createActiveStore<boolean>(true),
            createActiveStore<passwordFailReason[]>([]),
        ] 
    }, []);

    const _formData = useStore(formData.store);

    function onSubmit() {
        const _formData = formData.get();
        code && doResetPassword(code, _formData.password)
        .then(result => {
            result && context.authState.set(AuthStates.SignIn);
        })              
    }

    function onBlurPassword() {
        const password = passwordRef.current!.value;
        const repeatPassword = passwordRepeatRef.current!.value;
        passwordsEqual.set(!password || !repeatPassword || password === repeatPassword); 
    }

    function setAreEnteredPasswordsEqual() {
        (
            !passwordRef.current!.value || 
            !passwordRepeatRef.current!.value || 
            passwordRef.current!.value === passwordRepeatRef.current!.value
        ) && 
            passwordsEqual.set(true);
    }
   
    function isFormComplete() {
        return  passwordRef.current && passwordRepeatRef.current &&
                passwordRef.current.value === passwordRepeatRef.current.value &&
                IsValidPassword(passwordRef.current!.value).length === 0;
    }

    useEffect(() => {
        IsValidPassword(_formData.password, passwordFail);
    }, [_formData.password])

    return (<>
        <div className="signInLabel">{t('%Reset your password%')}</div>
        <div className="vMargin10"></div>

        <div className='line16 greyText'>{context.emailProvided}</div>

        <div className="vMargin15"></div>


        <div className='relative'>
            <input 
                ref={ passwordRef }
                type="password" 
                className="editField grayBackground" 
                placeholder={t('%New password%')}
                onChange={e => { 
                    formData.set({...formData.get(), password: e.target.value});
                    setAreEnteredPasswordsEqual();
                }}
                onBlur={ onBlurPassword }
            />
            <img
                src={`${imgs}/hidden.svg`}
                className="hiddenEye"
                title={t('%Show or hide password%')}
                onClick={() => passwordRef.current!.type = passwordRef.current!.type === 'password' && 'text' || 'password'}
            />            
        </div>
        
        <PasswordsNotEqual equal={ passwordsEqual.store } />
        
        <div className='relative'>
            <input 
                ref={ passwordRepeatRef }
                type="password" 
                className="editField grayBackground" 
                placeholder={t('%Confirm password%')}
                onChange={e => {
                    formData.set({...formData.get(), repeatPassword: e.target.value});
                    setAreEnteredPasswordsEqual();
                }}
                onBlur={ onBlurPassword }
            />
            <img
                src={`${imgs}/hidden.svg`}
                className="hiddenEye"
                title={t('%Show or hide password%')}
                onClick={() => passwordRepeatRef.current!.type = passwordRepeatRef.current!.type === 'password' && 'text' || 'password'}
            />         
        </div>    

        <div className="vMargin15"></div>      

        <div 
            className={`signButton ${isFormComplete() ? 'enabled' : ''}`} 
            onClick={onSubmit}
        >
            {t('%Reset password%')}
        </div>

        <div className="vMargin15"></div>
                
        <div className="newTo">
            <PasswordMust failReason={ passwordFail.store }/>
        </div>
    </>);
}


function PasswordsNotEqual({ equal }: { equal: Store<boolean> }) {
    const _equal = useStore(equal);
    return (<>
        <div className="smallSizedInfo redText">{!_equal && t('%Passwords are not equal%') || <>&nbsp;</>}</div>
    </>)    
}


export function ResetPassword() {
    const context = useContext(LoginContext)!;

    return (
        <>
            <div className='backButton' onClick={() => context.authState.set(AuthStates.SignIn) }>{t('%back%')}</div>
            <div className="signInLabel newTo" style={{ paddingTop: '0.5em' }}>{t('%Reset your password%')}</div>
            <div className="vMargin15"></div>

            <div className='hvCentered'>
                <div className='resetPasswordCircle'>
                    <img src={`${imgs}/resetpassword.svg`} />
                </div>
            </div>

            <div className="vMargin15"></div>

            <div className="line16">{t('%Look in your Inbox for an email explaining how to reset your password%')}</div>
            <div className="vMargin10"></div>   
            <div className='greyText line16'> {context.emailProvided}</div>

            <div className="vMargin15"></div>   

            <div className='newTo'>{t('%Have not received?%')}&nbsp;&nbsp;&nbsp;&nbsp;<span onClick={() => doAskResetPassword(context.emailProvided)} className="link">{t('%Resend%')}</span></div>        
        </>
    )
}


function SendButton({ formData, onSubmit, onComplete, tbi }: { formData: Store<IEmailFormData>, onSubmit?: () => void, onComplete: (success: boolean) => void, tbi: number }) {
    const context = useContext(LoginContext)!;
    const _formData = useStore(formData);

    function isSignInEnabled(): 'enabled' | '' {
        return isValidEmail(_formData.email) && 'enabled' || '';
    }

    function onSubmitClick() {
        onSubmit && onSubmit();
        isSignInEnabled() === 'enabled' &&
            doAskResetPassword(_formData.email)
            .then(result => {
                context.emailProvided = _formData.email;
                onComplete(result)
            })
    }

    return (
        <div
            tabIndex={tbi}
            className={`signButton ${isSignInEnabled()}`}
            onClick={onSubmitClick}
            onKeyUp={(e) => e.key === "Enter" && onSubmitClick()}
        >{t('%Send%')}</div>
    );
}