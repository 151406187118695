import { SyncStateURL } from "../../components/AppComponents/funcs";
import { SyncState, createDataProvider, createSyncState } from "../../state/SyncStore"
import apiClient from "../apiclient";
import * as Auth from "../../utils/auth";

export const dp = createDataProvider();

export async function RetrieveRecord<T = any>(source: string, id?: number, params?: Record<string, any>, serviceDomain?: string): Promise<{record: T, sync: SyncState<T, T>}> {
    const sync = createSyncState<T, T>({
        uid: `dataSetRecord#${source}`,
        request: dp.new({ urlTemplate: SyncStateURL(source, (!id && id !== 0), serviceDomain) })
    })!;
    return { record: await sync.setParams({...((id || id === 0) && {id, ID: id} || {}), ...params}).load(), sync: sync };
}

export async function RetrieveDataset<T = any>({source, uid, params, cached = false, sliceId, sourceDetalization, serviceDomain, searchParams}: {source: string, uid?: string, sliceId?: number, cached?: boolean, serviceDomain?: string, sourceDetalization?: string[] | string, searchParams?: Record<string, any>, params?: Record<string, any>}): Promise<{dataSet: T[], sync: SyncState<T[], T>}> {
    const tail = sourceDetalization && Array.isArray(sourceDetalization) ? sourceDetalization.join('/') : sourceDetalization || '';
    const sync = createSyncState<T[], T>({
        uid: uid || `dataSet#${source}#${tail}#${sliceId || sliceId === 0 ? sliceId : ''}`,
        request: dp.new({ urlTemplate: SyncStateURL(source, sliceId || sliceId === 0 ? false : true, serviceDomain, searchParams) + (tail ? `/${tail}` : '') }),
        idValue: value => (value as any).id
    })!;
    sync.setParams({ ...(sliceId && {ID: sliceId} || {}), ...params});
    return { dataSet: await ((sliceId || sliceId === 0 || params || searchParams) || !cached ? sync.load() : sync.initLoad()), sync: sync };
}

export async function reloadUserProfile() {
    const res = await apiClient.get("/pfm/api/v1/user");
    if (res.status === 200 && res.data) {
        Auth.setUserProfile(res.data);
        return true;
    }    
    return false;    
}