import React from "react";
import { ActiveStore } from "../../state/ActiveStore";
import { AuthStates } from "./LoginTypes";

/**
 * Interface for the Login Context.
 * 
 * This interface defines the shape of the context used for managing the state in the login flow.
 * 
 * - `isAuthorized`: ActiveStore managing the authorization state of the user.
 * - `authState`: ActiveStore holding the current authentication state, based on the AuthStates enum.
 * - `language`: ActiveStore for the currently selected language.
 * - `emailProvided`: String to hold the user's provided email.
 * - `passwordProvided`: String to hold the user's provided password.
 * - `firstNameProvided`: String to hold the user-provided first name.
 * - `lastNameProvided`: String to hold the user-provided last name.
 */
export interface ILoginContext {
    /**
     * ActiveStore managing the authorization state of the user.
     * It holds a boolean value indicating whether the user is authorized.
     */
    isAuthorized: ActiveStore<boolean>;

    /**
     * ActiveStore holding the current authentication state of the user.
     * It uses the AuthStates enum to represent various authentication states.
     */
    authState: ActiveStore<AuthStates>;

    /**
     * ActiveStore for managing the current language setting of the application.
     * It holds a string value representing the language key.
     */
    language: ActiveStore<string>;

    /**
     * String to store the email address provided by the user during the login or registration process.
     */
    emailProvided: string;

    /**
     * String to store the password provided by the user during the login process.
     */
    passwordProvided: string;

    /**
     * String to store the first name provided by the user, typically during the registration process.
     */
    firstNameProvided: string;

    /**
     * String to store the last name provided by the user, typically during the registration process.
     */
    lastNameProvided: string;
}


/**
 * Context for the Login module.
 * 
 * This context provides a way to pass down and manage login-related data throughout the component tree.
 * It uses the ILoginContext interface to ensure the correct shape of the provided data.
 *
  * - `isAuthorized`: ActiveStore managing the authorization state of the user.
 * - `authState`: ActiveStore holding the current authentication state, based on the AuthStates enum.
 * - `language`: ActiveStore for the currently selected language.
 * - `emailProvided`: String to hold the user's provided email.
 * - `passwordProvided`: String to hold the user's provided password.
 * - `firstNameProvided`: String to hold the user-provided first name.
 * - `lastNameProvided`: String to hold the user-provided last name.
 */
export const LoginContext = React.createContext<ILoginContext | null>(null);