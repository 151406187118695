import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AuthStates } from './LoginTypes';
import { LoginContext } from './LoginContext';
import * as Auth from "../../utils/auth";

// import { t } from '../../models/platformitems';
import { t } from "../../locale";


export function Invite() {

    const code = new URLSearchParams(window.location.search).get('code');
    const org = new URLSearchParams(window.location.search).get('org');

    const context = useContext(LoginContext)!;

    function onSubmit() {
        Auth.setInvitationCode(code);
        Auth.setInvitationOrg(org);
        context.authState.set(AuthStates.SignIn);
        window.location.replace("/portal");
    }

    return (<>
        <div className="signInLabel">{t('%Join the organization%')}</div>
        <div className="vMargin10"></div>
      
         <>
            <div className="vMargin15"></div>

            <div className="line16">            
                {t('%You have been invited to join the organization%')}
                &nbsp;{org}&nbsp;
                {t('%on the BIMcosmos platform.%')}
            </div>

            <div className="vMargin15"></div>

            <div className="line16">            
                {t('%Click ‘Join’ to proceed.%')}
            </div>            

            <div className="vMargin15"></div>

            <div
                className={`signButton enabled short`}
                onClick={onSubmit}
            >
                {t('%Join%')}
            </div>

            <div className="vMargin15"></div>

            <div>
                <span
                    className="link"
                    onClick={() => window.history.go(-1)}
                >{t('%Reject%')}
                </span>
            </div>            
        </>

    </>);

}



