import { useContext, useEffect, useState } from "react";
import { DataSourceContext, IRefBookSource, LookupField, ModalOperationContext, NumberField, StringField } from "../../components/AppComponents/Modals/ModalFields";
import { IInviteUser, IModalOptions, IRole, IUser, IUserRole } from "../../components/AppComponents/types";
import { createDataProvider, createSyncState } from "../../state/SyncStore";
import { getNavigationBodyParams, navigateCommand } from "../../components/BCComponents/Navigation";
import { tableMessages } from "../../components/BCComponents/bcGlobals";
import { postMessage } from "../../components/BCComponents/Messages";
import { RegisterModal } from "../../components/AppComponents/Modals/Modal";
import { ModalClasses, ModalNames } from "../../components/AppComponents/variables";
import { RetrieveDataset } from "../../utils/DataSets/DataSets";

export function InviteUserModal() {
    return <>
        {/* <FieldsRow>
        </FieldsRow> */}
        <StringField label='Email' name="email" required={true} />
        <LookupField label='Select a role' name="roleId" required={true} source={{ refBookName: 'roles/allowed-to-offer', serviceDomain: 'aaa' } as IRefBookSource} />
        {/* <BooleanField label='Grant trial license (Starter)' name="isTrialLicenseGranted" /> */}
        {/* <LookupField label='Select a license' name="licenseId" source={{ refBookName: 'suggest/licenses' }} /> */}
    </>;
}

export function validateInviteUser(data?: IInviteUser) {
    return true;
    // return (data && (data['isTrialLicenseGranted'] || data['licenseId']) && true) || false;
}

const inviteUserOptions: IModalOptions<IInviteUser> = {
    appearance: "popup",
    createModal: InviteUserModal,
    validate: validateInviteUser,
    labels: { submit: { add: 'Invite' } },
    controlsToHideOnCreate: ['wide', 'side', 'popup'],
    title: { caption: { add: 'Invite user', edit: '' } },
}

// ########################################################################################
const dp = createDataProvider();

const sync = createSyncState<IUserRole[], IUserRole>({
    uid: "SyncUserRoleRequest",
    request: dp.new({ urlTemplate: '/aaa/api/v1/users/${userId}' }),
    idValue: (item) => item,
})!;

export function ChangeUserRoleModal() {
    const navParams = getNavigationBodyParams();
    const role = navParams.params.Role || '';
    const operation = useContext(ModalOperationContext);
    const userId = getNavigationBodyParams().params.ID || -1;
    const [user, setUser] = useState<IUser | undefined>(undefined);

    useEffect(() => {
        RetrieveDataset<IUser>({ serviceDomain: 'aaa', sliceId: userId, source: 'users', sourceDetalization: 'role-in-organization' })
            .then(provider => setUser(provider.dataSet as any as IUser))
            .catch(() => {
                navigateCommand('dataset', {
                    name: ModalNames.messagebox,
                    method: 'add',
                    title: 'You cannot change this role',
                    subtitle: role
                });
            })
    }, []);

    operation.onCreate = () => {
        user && sync.setParams({ userId }).updatePatch({ roleId: user.roleId })
            .then(() => {
                operation.close && operation.close();
                postMessage(tableMessages.rowAffected, { lastAffectedId: userId }, 'organizations/users');
            })
            .catch(err => { console.log(err) });
        return false;
    };

    return user &&
        <DataSourceContext.Provider value={user}>
            <LookupField label="Select a role" name="roleId" required={true} source={{ refBookName: 'roles', serviceDomain: 'aaa' }} />
        </DataSourceContext.Provider> || null;
}

function afterChange(fieldname: string, data?: IUser): boolean {
    if (data && data.roleId && fieldname === 'roleId') {
        return true;
    }
    return false;
}

const changeRoleUserOptions: IModalOptions<IUser> = {
    appearance: "popup",
    createModal: ChangeUserRoleModal,
    afterChange: afterChange,
    labels: { submit: { add: 'Save changes' } },
    controlsToHideOnCreate: ['wide', 'side', 'popup'],
    title: { caption: { add: 'Change user role' } },
    local: true,
}

// ########################################################################################

export function RoleModal() {
    return <>
        <StringField label="Name" name="name" required={true} />
        <NumberField label="Grant level" name="grantLevel" required={true} />
    </>;
}


const rolesOptions: IModalOptions<IRole> = {
    appearance: "popup",
    createModal: RoleModal,
    editModal: RoleModal,
    serviceDomain: 'aaa',
    labels: { submit: { add: 'Create', edit: 'Save changes' } },
    controlsToHideOnCreate: ['wide', 'side', 'popup'],
    controlsToHideOnEdit: ['wide', 'side', 'popup'],
    title: { caption: { add: 'Create role', edit: 'Edit role' } },
}

export const InitSecurityModals = () => {
    RegisterModal(ModalClasses.grider, ModalNames.inviteUser, inviteUserOptions);
    RegisterModal(ModalClasses.grider, ModalNames.organizationsUsers, changeRoleUserOptions);
    RegisterModal(ModalClasses.grider, ModalNames.roles, rolesOptions);
}
