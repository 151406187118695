import { UserField } from "../../../AppComponents/Modals/ModalFields";
import { IconSide } from "../../../AppComponents/variables";
import { Button, IButton } from "../Buttons/Buttons";
import { IconButton } from "../Buttons/IconButton/IconButton";
import { t } from "../../../../locale";
import './Label.css';
import { Color } from "../../bcGlobals";

export interface ILabel extends Omit<IButton, 'disabled' | 'onClick' | 'label'> {}

export function Label(props: ILabel) {
    return (<Button {...props} classes={(props.classes || '') + ' justLabel'} style={{ cursor:"text", ...props.style}} disabled={true} />)
}

export function IconWithBg(props: ILabel & { backgroundColor?: Color, borderRadius?: string }) {
    return (
        <div className="iconWithBg" style={{ backgroundColor: props.backgroundColor, borderRadius: props.borderRadius}}>
            <Button {...props} classes={(props.classes || '') + ' justLabel'} style={ props.style } disabled={true} />
        </div>
    )
}