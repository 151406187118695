import { Event, Store, createEvent, createStore } from 'effector';

export interface ActiveStore<State> {
    store:  Store<State>,
    get:    () => State,
    set:    Event<State>,
}

export function createActiveStore<State>(value: State, forStore?: Store<State>): ActiveStore<State> {
    const store     = forStore || createStore<State>(value);
    const setter    = createEvent<State>();
    store.on(setter, (state, value: State) => value );
    return {store, get: store.getState, set: setter}
}