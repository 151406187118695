import { Store } from "effector";
import { useStore } from "effector-react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { t } from "../../../locale";
import { createActiveStore } from "../../../state/ActiveStore";
import { SyncState, createDataProvider, createSyncState, syncStateByUID } from "../../../state/SyncStore";
import { tableMessages } from "../../BCComponents/bcGlobals";
import { Button } from "../../BCComponents/Controls/Buttons/Buttons";
import { IconButton } from "../../BCComponents/Controls/Buttons/IconButton/IconButton";
import { FlatVPanel } from "../../BCComponents/Layouts/Panels/ArrangementPanels";
import { FlatControlsPanel } from "../../BCComponents/Layouts/Panels/ContolsPanel";
import { FlatRightPanel } from "../../BCComponents/Layouts/Panels/RightPanel";
import { FlatWidePanel } from "../../BCComponents/Layouts/Panels/StretchedPanels";
import { postMessage } from "../../BCComponents/Messages";
import { NavigationContext, navigateCommand, useNavigation } from "../../BCComponents/Navigation";
import { cAttr } from "../../../utils/helpers";
import { SafeJSONParse, SyncStateURL } from "../funcs";
import { deepCopy } from "../Grider/griderUtils";
import { IFormButtonLabels, IModalOptions, IModalTitle, IModalTitleCaption, IURLAction, ModalAppearance, ModalControlButtons, ModalEditMethods, ModalMethods, ModalValidate } from "../types";
import { DataSourceContext, IModalOperationContext, ModalOperationContext } from "./ModalFields";
import './Modals.css';

const modals: Record<string, Record<string, IModalOptions<any>>> = {};

const dp = createDataProvider();

export function RegisterModal<T = Record<string, any>>(modalClass: string, name: string, modal: IModalOptions<T> | null) {
    const className = modalClass.trim() || '*';
    const classModals = modals[className] || (modals[className] = {});
    modal ? classModals[name] = modal : delete classModals[name];
}

function getTitle({ caption, data, method } : {caption?: IModalTitleCaption, data: any, method: ModalEditMethods }) {
    const title = (method === 'add' && caption?.add) || (method === 'edit' && caption?.edit) || '';
    return typeof title === 'string' ? t(`%${title || ''}%`) :  
           typeof title === 'function' ? title(data) : 
           title;  
}

function SanityCheck(body: any): IURLAction | undefined {
    if (!body || typeof body !== 'object') return undefined;
    const objectBody = body as IURLAction;
    if (!objectBody.method || !objectBody.name || (['add', 'edit'].indexOf(objectBody.method) === -1)) return undefined;
    return body as IURLAction;
}

const allTabableElements =  ':scope>:not(.modalContainer) [tabindex],' + 
                            ':scope>:not(.modalContainer) input,' +
                            ':scope>:not(.modalContainer) textarea,' +
                            ':scope>:not(.modalContainer) button,' +
                            ':scope>:not(.modalContainer) a,' +
                            ':scope>:not(.modalContainer) select,' +
                            ':scope>:not(.modalContainer) area,' +
                            ':scope>:not(.modalContainer) iframe,' +
                            ':scope>:not(.modalContainer) [contenteditable="true"]';

export interface IModalSyncDataSet<T = any> { 
    sync: SyncState<T[], T> | SyncState<T, T>,
    reload?: true 
}

interface IModal { 
    modalClass: string, 
    hideSubmitPanel?: boolean, 
    actionBody?: IURLAction, 
    initialDataSet?: Record<string, any>[] | IModalSyncDataSet
}

export function Modal(params : IModal) {
    useNavigation();
    const context               = useContext(NavigationContext);
    const body                  = SanityCheck(typeof context.body === 'string' && SafeJSONParse(context.body));
    return <div className="modalEntire" {...cAttr(true, 'name', body?.name)} key={ window.location.pathname + window.location.search }>
                <ModalContent {...params} />
           </div>;
}

let modalLevel = 0;

function ModalContent({ modalClass, initialDataSet, actionBody, hideSubmitPanel } : IModal) {
    const dataSet               = useRef(initialDataSet);
    const [dataRow, setDataRow] = useState<Object | null>(null);
    const context               = useContext(NavigationContext);
    let body                    = SanityCheck(typeof context.body === 'string' && SafeJSONParse(context.body));
    body                        = actionBody && {...(body || {}), ...actionBody} || body;
    const options               = body && modals[modalClass || '*'] && modals[modalClass || '*'][body.name];
    const id                    = body && ((body as any).id || (body as any).ID || body.params && (body.params['ID'] || body.params['id'] || -1));
    const modalRef              = useRef<HTMLDivElement>(null);
    const [_, setAffectedData]  = useState<Object>({});
    const [title, setTitle]     = useState<IModalTitle>({});
    const syncParams            = useRef({});
    const submittingNow         = useRef(false); 

    const optionSync = useMemo(() => options?.syncName && syncStateByUID(options.syncName), []);
    dataSet.current  = useMemo(() => optionSync ? { sync: optionSync } : dataSet.current, []);

    const onDataChange  = useMemo(() => createActiveStore<{name: string} | null>(null), []);
    const validations   = useMemo(() => options?.validate && [options.validate] || [], [options?.validate]);    

    useEffect(() => {
        !body || body.method === "add" || options?.local ? setDataRow(new Object) :
            !dataSet.current ? sync?.setParams({ID: id, id: id}).load().then(data => setDataRow(deepCopy(data))).catch(() => navigateCommand('')) :
                Array.isArray(dataSet.current) ? setDataRow(deepCopy(Array.isArray(dataSet.current) && dataSet.current.find(row => row['ID'] === id || row['id'] === id) || null)) :
                    dataSet && (dataSet.current.reload ? dataSet.current.sync?.setParams({ID: id, id: id}).load().then(data => setDataRow(deepCopy(data))).catch(() => navigateCommand('')) : 
                        setDataRow(deepCopy((id || id === 0) && dataSet.current.sync?.find(id)[0] || dataSet.current.sync.state())))
    }, [body && body.name, body && body.method, id, dataSet.current]);

    useEffect(() => {
        setTimeout(() => modalRef.current?.classList.add('ready'), 50);

        setTimeout(() => {
            const element = (modalRef.current?.querySelector('input:not(.select-container),textarea:not(.select-container)') as HTMLInputElement);
            element && (!element.parentElement?.classList.contains('select-container') && element.focus()); 
        }, 500);
    }, [dataRow]);

    useEffect(() => {
        const parent = modalRef.current?.parentElement;
        const selfLevel = ++modalLevel;

        parent?.querySelectorAll(allTabableElements).forEach(el => {
            el.setAttribute('disabled-tabindex', el.getAttribute('tabindex') || '');
            el.setAttribute('tabindex', '-1');
        });      
          
        onDataChange.set({name: '--init'});

        const onDefaultCatch = (event: KeyboardEvent) => { 
            event.key === 'Escape' && (overlappingModal.get() ? overlappingModal.set(null) : onCancel()); 
            event.key === 'Enter' && selfLevel === modalLevel && onSubmit();
        }

        document.addEventListener('keydown', onDefaultCatch); 

        return () => {
            modalLevel--;
            parent?.querySelectorAll(':scope>:not(.modalContainer) [disabled-tabindex]').forEach(el => {
                const tabIndex = el.getAttribute('disabled-tabindex');
                tabIndex ? el.setAttribute('tabindex', tabIndex) : el.removeAttribute('tabindex');
                el.removeAttribute('disabled-tabindex');
            });
            document.removeEventListener('keydown', onDefaultCatch);
        }
    }, [dataRow]);

    const operationContextRef = useRef<IModalOperationContext>({ 
        __changeNotification:   modalValidation, 
        __fieldValidations: validations, 
        
        close:      onCancel,
        submit:     onSubmit,
        setTitle:   setTitle,
        setSyncParams: setSyncParams,
        userValidate: () => {
            onDataChange.set({name: '--user'});
        },
        params:     actionBody?.params,

        onCreate:   options?.onCreate,
        onEdit:     options?.onEdit, 
        onCancel:   options?.onCancel, 
    });

    const operationContext = operationContextRef.current;

    Object.assign(operationContext, 
        { 
            params:           actionBody?.params, 
            fieldValidations: validations, 
            onChange:         modalValidation, 
            close:            onCancel,
            setTitle:         setTitle,
        }
    );

    const syncRef = useRef<SyncState<any, any> | null>(null);

    if (!body || !options || (!options.local && body.method === "edit" && (!id && id !== 0))) return (<></>);

    const sync = options.local ? null : 
                 optionSync || 
                 (dataSet.current && !Array.isArray(dataSet.current) && dataSet.current.sync) || 
                 createSyncState<Record<string, any>, Record<string, any>>({
                    uid: `SyncModalRequest#${body.name}`,
                    request: dp.new({ urlTemplate: SyncStateURL(body.name, body.method === 'add' && options.excludeId, options.serviceDomain) })
                 })!;

    if (syncRef.current !== sync) 
        (sync?.store as Store<any[]>)?.watch(state => {
            !submittingNow.current && Array.isArray(state) && state.length && setDataRow(deepCopy(sync?.find(id)[0])) 
        } );
    syncRef.current = sync;

    function setSyncParams(params: Record<string, any>) {
        syncParams.current = deepCopy(params);
    }

    function onSuccess(affectedId: number) {
        setTimeout(() => {
            navigateCommand('', {}, true);
            body && postMessage(tableMessages.rowAffected, { lastAffectedId: affectedId?.toString() }, body.name);
        }, 300);
        modalRef?.current?.classList.remove('ready');
    }

    function onSubmit() {
        options?.afterChange && options?.afterChange(`--${body?.method}`, dataRow);

        function onEdit() { (!operationContext?.onEdit || (operationContext?.onEdit && operationContext?.onEdit(dataRow))) && onSuccess(id); submittingNow.current = false; } 
        function onCreate(data: Record<string, any>) { (!operationContext?.onCreate || (operationContext?.onCreate && operationContext?.onCreate({ ...dataRow, ...data }))) && onSuccess(data.id); submittingNow.current = false; }
        
        submittingNow.current = true;

        body?.method === 'edit' && (options?.local ? onEdit() : sync?.setParams({ID: id, id: id, ...(syncParams.current || {})}).updatePut(dataRow).then(() => onEdit()));
        body?.method === 'add' && (options?.local ? onCreate(dataRow || {}) : sync?.setParams(syncParams.current || {}).add(dataRow).then(data => onCreate(data)));
        return true;
    }

    function onCancel() {
        if (operationContext?.onCancel && !operationContext?.onCancel()) return false;
        setTimeout(() => {
            navigateCommand('', {}, true);
            body && postMessage(tableMessages.rowAffected, { operationCanceled: true }, body.name);
            options?.onCancel && options?.onCancel();
        }, 300);
        modalRef?.current?.classList.remove('ready');
        return true;
    }

    function modalValidation(name: string, _dataRow: Record<string, any>) { 
        options?.afterChange && options.afterChange(name, _dataRow) && setAffectedData({}); 
        onDataChange.set({name});
    }

    let appearance: ModalControlButtons | undefined | false = false;
    
    if (dataRow) {
        appearance = getStoredAppearance()[body.name];
        appearance = appearance && body.method === 'add' && options.controlsToHideOnCreate?.includes(appearance) ? false : appearance;
        appearance = appearance && body.method === 'edit' && options.controlsToHideOnEdit?.includes(appearance) ? false : appearance;
    }

    return <div className={`modalContainer ${appearance || options.appearance} ${options.sectioned ? 'section' : ''}`} {...cAttr(true, 'name', body.name)} {...cAttr(true, 'method', body.method)} ref={modalRef}>
                { dataRow &&
                    <FlatVPanel classes="modalFrame">
                        <FlatVPanel classes="modalContent">         
                            <FlatWidePanel classes="modalCaption" vertical="center">
                                { getTitle({ caption: title.caption || options.title?.caption, data: dataRow, method: body.method as ModalEditMethods}) }
                                <ControlButtons 
                                    defAppearance={appearance || body.appearance || "popup"} 
                                    hide={body.method === 'add' && options.controlsToHideOnCreate || body.method === 'edit' && options.controlsToHideOnEdit || undefined} 
                                    name={body.name} 
                                    onCancel={onCancel} 
                                    modalRef={modalRef} 
                                >{ title.buttons }</ControlButtons>
                            </FlatWidePanel>
                            <FlatVPanel proportion={1} classes="modalDataContent thinScrollbar">
                                <DataSourceContext.Provider value={dataRow}>
                                    <ModalOperationContext.Provider value={ operationContext }>
                                        { body.method === 'add' && <options.createModal /> || body.method === 'edit' && options.editModal && <options.editModal /> || <options.createModal /> }
                                    </ModalOperationContext.Provider>
                                </DataSourceContext.Provider>
                            </FlatVPanel>
                            { !hideSubmitPanel && !options.hideSubmitPanel && <SubmitPanel onSubmit={ onSubmit } method={body.method} onCancel={ onCancel } labels={{...options.labels, ...title}} onDataChange={onDataChange.store} validations={ validations } dataRow={dataRow}/> }
                        </FlatVPanel>    
                    </FlatVPanel>    
                }    
            </div>
}

function SubmitPanel({ onSubmit, onCancel, onDataChange, validations, dataRow, labels, method } : {onSubmit: () => void, onCancel: () => void, method: ModalMethods, labels?: IFormButtonLabels, onDataChange: Store<{name: string} | null>, validations: ModalValidate<any>[], dataRow: Object | null}) {
    const filedName = useStore(onDataChange)?.name;
    let isValid = false;
    try {
        isValid = validations.every(validate => validate({ ...dataRow }) || false);
    } catch (error) { console.error(`validation error on field [${filedName}]`, error) }
        
    const okLabel = labels?.submit && (typeof labels.submit === 'object' ? labels.submit[method] : labels.submit) || 'Save';

    return (
        <FlatRightPanel classes="submitPanel">
            <Button classes="rounded gray" style={{ marginRight: '0.69em' }} onClick={ onCancel }>{t(`%${labels?.cancel || 'Cancel'}%`)}</Button>
            <Button 
                classes={`rounded red ${!isValid && 'disabled' || ''}`} 
                onClick={ () => isValid && onSubmit() }
                disabled={!isValid}
            >{t(`%${okLabel}%`)}</Button>
        </FlatRightPanel>        
    )    
}

const griderAppearance = 'griderAppearance';

function getStoredAppearance() {
    let storedAppearance: Partial<Record<string, ModalAppearance>> = {};
    try {
        storedAppearance = JSON.parse(localStorage.getItem(griderAppearance) || '{}');
    } catch (error) {}
    return storedAppearance;
} 

function ControlButtons({ onCancel, modalRef, defAppearance, hide, name, children } : { defAppearance: ModalAppearance, children?: React.ReactNode | React.ReactNode[], hide?: ModalControlButtons[], name: string, onCancel: () => void, modalRef: React.RefObject<HTMLDivElement> }) {
    const [appearance, setAppearance] = useState(getStoredAppearance()[name] || defAppearance);

    function onApperance(value: ModalAppearance) {
        modalRef.current!.classList.remove('wide', 'side', 'popup');
        modalRef.current!.classList.add(value);
        const storedAppearance = getStoredAppearance();
        storedAppearance[name] = value;
        localStorage.setItem(griderAppearance, JSON.stringify(storedAppearance))
        setAppearance(value);
    } 

    return (
        <FlatControlsPanel alignment="end" classes="modalControlButtons">
            { children }
            { (!hide || !hide.includes('wide')) &&(appearance === "popup" || appearance === "side") && <IconButton classes="noEdges" icon="expand" onClick={ () => onApperance('wide') }/> }
            { (!hide || !hide.includes('popup')) && (appearance === "wide" || appearance === "side") && <IconButton classes="noEdges" icon="frame" onClick={ () => onApperance('popup') }/> }
            { (!hide || !hide.includes('side')) && (appearance === "popup" || appearance === "wide") && <IconButton classes="noEdges" icon="frameRight" onClick={ () => onApperance('side') }/> }
            {(!hide || !hide.includes('cancel')) && <IconButton icon="cancel" size="1em" onClick={ onCancel }/>}
        </FlatControlsPanel>
    )
}

export const overlappingModal = createActiveStore<{ modal: JSX.Element, zIndex?: number } | null>(null);

export function OverlappingModals() {
    const modalState = useStore(overlappingModal.store);

    return modalState && <div style={{ position: 'absolute', zIndex: modalState?.zIndex || 1000000000, right: 0, bottom: 0, left: 0, top: 0, backgroundColor: '#0000001a' }}>
        { modalState?.modal }
    </div> || null;
}