import { useStore } from 'effector-react';
import { useRef } from 'react';
import { Modal, OverlappingModals } from '../../components/AppComponents/Modals/Modal';
import { ModalClasses, currentUser } from '../../components/AppComponents/variables';
import { Command, setBaseUrl } from '../../components/BCComponents/Navigation';
import { Portal } from '../../components/BCComponents/Portal/Portal';
import { AppHeader } from '../../containers/Framework/AppHeader/AppHeader';
import { Content } from '../../containers/Framework/Content/Content';
import { MainMenu } from '../../containers/Framework/Menu/Menu';

setBaseUrl('/portal');

export function Home() {
    const user   = useStore(currentUser.store);
    const appRef = useRef<HTMLDivElement>(null); 

    return (<>
        <div className='appContainer' ref={appRef} key={user?.currentOrganizationID || -1}>
            <Command action={ ModalClasses.settings }>
                <Modal modalClass={ ModalClasses.settings } />
            </Command>

            <MainMenu />
            
            <div className="mainContent lastChildIsContainer">
                <Command action={ ModalClasses.grider }>
                    <Modal modalClass={ ModalClasses.grider } />
                </Command>
                <Command action={ ModalClasses.dataSet }>
                    <Modal modalClass={ ModalClasses.dataSet } />
                </Command>

                <OverlappingModals />

                <AppHeader />
                <Content />  
            </div>
            <Portal name="popUp" />
        </div>
    </>);
}
