import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox } from '../../components/BCComponents/Controls/Checkbox/Checkbox';
import { AuthStates } from './LoginTypes';
import { useStore } from 'effector-react';
import { LoginContext } from './LoginContext';
import { Store } from 'effector';
import { isValidEmail } from '../../utils/utils';
import { ActiveStore, createActiveStore } from '../../state/ActiveStore';
// import { t } from '../../models/platformitems';
import { t } from "../../locale";
import { imgs } from '../../components/AppComponents/variables';
import { doSignUpGetCode, doMicrosoftIDAuthorization } from './LoginUtils';
import { IsValidPassword, PasswordMust, passwordFailReason } from './Passwords';
import * as Auth from "../../utils/auth";

interface ISignUpFormData {
    email: string;
    password: string;
    repeatPassword: string;
    termsAccepted: boolean;
}

/**
 * Renders the Sign Up component.
 * 
 * This component allows users to create an account by providing their email, password,
 * repeating the password for confirmation, and agreeing to terms and conditions.
 * It also handles user sign up with Microsoft ID and manages navigation to the sign-in page.
 */
export function SignUp() {
    const context   = useContext(LoginContext)!;
    const [credentialsError, setCredentialsError] = useState(false);
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordRepeatRef = useRef<HTMLInputElement>(null);

    const [formData, passwordsEqual, passwordFail] = useMemo<[
                                                                ActiveStore<ISignUpFormData>, 
                                                                ActiveStore<boolean>, 
                                                                ActiveStore<passwordFailReason[]
                                                    >]>(() => {
        return [
            createActiveStore<ISignUpFormData>({ 
                email: '', 
                password: '',
                repeatPassword: '',
                termsAccepted: false,
            }),
            createActiveStore<boolean>(true),
            createActiveStore<passwordFailReason[]>([]),
        ] 
    }, []);

    function onComplete(success: boolean) {
        const _formData = formData.get();
        context.emailProvided = _formData.email;
        context.passwordProvided = _formData.password;
        success && context.authState.set(AuthStates.VerificationRequired);

        setCredentialsError(!success);
    }

    function onBlurPassword() {
        const password = passwordRef.current!.value;
        const repeatPassword = passwordRepeatRef.current!.value;
        passwordsEqual.set(!password || !repeatPassword || password === repeatPassword); 
    }

    function Agreement() {
        const locale = Auth.getLocale() || 'en';
        return (
            <span className='font09 left line14'>
                {t('%By signing up, I agree to%')}&thinsp;
                <a className='link' href={'https://bimcosmos.com/' + `${locale === 'de' ? 'de/datenschutzerklaerung/' : 'privacy-policy/'}`} target='_blank'>{t('%Privacy Policy%')}</a>,&thinsp;
                <a className='link' href={'https://bimcosmos.com/' + `${locale === 'de' ? 'de/nutzungsbedingungen/' : 'terms-of-use/'}`} target='_blank'>{t('%Terms of Use%')}</a>, {t('%and%')}&thinsp;
                <a className='link' href={'https://bimcosmos.com/' + `${locale === 'de' ? 'de/nutzungshinweise-fuer-dritte/' : 'usage-information-for-third-parties/'}`} target='_blank'>{t('%Notice and agreement for the use of BIMcosmos%')}</a>.
            </span>
        )
    }

    return (<>
        <div className="signInLabel">{t('%Sign up%')}</div>
        <div className="vMargin15"></div>
        <input 
            autoFocus
            tabIndex={1}
            type="text" 
            className="editField grayBackground" 
            placeholder={t('%Email%')}
            onChange={e => formData.set({...formData.get(), email: e.target.value})} 
        />
        
        <InvalidEmail formData={formData.store} />
        
        <div className="relative">
            <input 
                tabIndex={2}
                ref={ passwordRef }
                type="password" 
                className="editField grayBackground masked" 
                placeholder={t('%Password%')}
                onChange={e => { 
                    formData.set({...formData.get(), password: e.target.value});
                    (!passwordRef.current!.value || 
                    !passwordRepeatRef.current!.value || 
                    passwordRef.current!.value === passwordRepeatRef.current!.value) && 
                    passwordsEqual.set(true);
                }}
                onBlur={ onBlurPassword }
            />
            <img
                src={`${imgs}/hidden.svg`}
                className="hiddenEye"
                title={t('%Show or hide password%')}
                onClick={() => passwordRef.current!.type = passwordRef.current!.type === 'password' && 'text' || 'password'}
            />            
        </div>
        
        <PasswordsNotEqual equal={ passwordsEqual.store } />
        
        <div className="relative">
            <input 
                tabIndex={3}
                ref={ passwordRepeatRef }
                type="password" 
                className="editField grayBackground masked" 
                placeholder={t('%Repeat password%')}
                onChange={e => {
                    formData.set({...formData.get(), repeatPassword: e.target.value});
                    (!passwordRef.current!.value || 
                    !passwordRepeatRef.current!.value || 
                    passwordRef.current!.value === passwordRepeatRef.current!.value) && 
                    passwordsEqual.set(true);
                }}
                onBlur={ onBlurPassword }
            />
            <img
                src={`${imgs}/hidden.svg`}
                className="hiddenEye"
                title={t('%Show or hide password%')}
                onClick={() => passwordRepeatRef.current!.type = passwordRepeatRef.current!.type === 'password' && 'text' || 'password'}
            /> 
        </div>    
        
        <div className="vMargin10"></div>

        <PasswordMust failReason={passwordFail.store} />
        
        <div className="vMargin10"></div>      

        <div className="agreement-container">
                <Checkbox
                    style={ { display: 'flex', alignItems: 'flex-start' } }
                    labelStyle={ { fontSize: '0.8em', lineHeight: '1.4'} }
                    markStyle={ { marginRight: '0.7em', marginTop: '0.2em' } }
                    onClick={accepted => formData.set({...formData.get(), termsAccepted: accepted})}
                />
                <Agreement/>
        </div>

        <div className="vMargin15"></div>
        
        <SignButton 
            formData={formData.store} 
            onComplete={onComplete} 
            onSubmit={() => setCredentialsError(false)}  
            passwordFail = { passwordFail }
            tbi={4}
        />
        
        {credentialsError && (
            <div className="infoMessageBlock font08">
                { t('%Unable to sign up with provided credentials. Try again or%') } <span className='link'>{ t('%contact support%') }</span>
            </div>
        )}

        <div className="vMargin15"></div>
        
        <div tabIndex={5}
            className="microsoft-block"
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === "Enter" && doMicrosoftIDAuthorization().then(result => result && window.location.replace(result))}
            onClick={() => doMicrosoftIDAuthorization().then(result => result && window.location.replace(result))}
        >
            <img src={`${imgs}/microsoft.svg`} />
            <span>{t('%Sign up with Microsoft ID%')}</span>
        </div>
        
        <div className="vMargin15"></div>
        
        <div className="newTo">
            {t('%Already have an account?%')}&nbsp;&nbsp;&nbsp;
            <span 
                tabIndex={6}
                className="link"
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === "Enter" && context.authState.set(AuthStates.SignIn)}
                onClick={ () => context.authState.set(AuthStates.SignIn) }
            >{t('%Sign in%')}</span>
        </div>
    </>);
}

interface ISignButton { 
    formData: Store<ISignUpFormData>, 
    passwordFail: ActiveStore<passwordFailReason[]>, 
    onSubmit?: () => void, 
    onComplete: (success: boolean) => void,
    tbi: number
}

/**
 * Component for the sign-up button.
 * 
 * Validates the sign-up form data before submission. Triggers the sign-up process
 * and handles the completion status through the `onComplete` callback.
 * 
 * @param formData - Store containing the sign-up form data.
 * @param passwordFail - Store containing reasons for password validation failure.
 * @param onSubmit - Optional callback executed on button click before the sign-up process.
 * @param onComplete - Callback executed after the sign-up process to handle the result.
 */
function SignButton({ formData, onSubmit, onComplete, passwordFail, tbi }: ISignButton) {
    const _formData = useStore(formData);

    function isSignUpEnabled(autoUpdate?: true): 'enabled' | '' {
        return  isValidEmail(_formData.email) && 
                IsValidPassword(_formData.password).length === 0 &&
                _formData.password === _formData.repeatPassword &&
                _formData.termsAccepted && 
                'enabled' || '';
    }
    
    function onSubmitClick() {
        onSubmit && onSubmit();
        isSignUpEnabled() === 'enabled' && 
        doSignUpGetCode(_formData.email, _formData.password)
        .then(result => onComplete(result));
    }

    useEffect(() => {
        IsValidPassword(_formData.password, passwordFail);
    }, [_formData.password]);

    return (
        <div 
            tabIndex={tbi}
            className={`signButton ${isSignUpEnabled()}`} 
            onClick={ onSubmitClick }
            onKeyUp={(e) => e.key === "Enter" && onSubmitClick()}
        >{t('%Sign up%')}</div>
    );
}

/**
 * Component to display a validation message for the email field.
 * 
 * Shows a message if the entered email is invalid.
 * 
 * @param formData - Store containing the sign-up form data.
 */
function InvalidEmail({ formData }: { formData: Store<ISignUpFormData> }) {
    const _formData = useStore(formData);
    return (<>
        <div className="smallSizedInfo redText">{_formData.email && !isValidEmail(_formData.email) && t('%Please enter a valid email address%') || <>&nbsp;</>}</div>
    </>)    
}

/**
 * Component to display a validation message for password fields.
 * 
 * Indicates if the entered passwords in the sign-up form do not match.
 * 
 * @param equal - Store indicating whether the entered passwords are equal.
 */
function PasswordsNotEqual({ equal }: { equal: Store<boolean> }) {
    const _equal = useStore(equal);
    return (<>
        <div className="smallSizedInfo redText">{!_equal && t('%Passwords are not equal%') || <>&nbsp;</>}</div>
    </>)    
}
