import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Event, Store, createEvent, createStore } from "effector";
import apiClient from "../utils/apiclient";

export enum stateActions {
    load        = 'get',
    add         = 'add',
    del         = 'delete',
    updatePost  = 'updatePost',
    updatePut   = 'put',
    updatePatch = 'patch',
    execute     = 'request'
};

export enum pullActions {
    set         = 'set',
    add         = 'insert',
    del         = 'delete',
    update      = 'update',
    replace     = 'replace'
}

const keyIndex = '#id'; 

export type ErrorHandler = (error: any, action: stateActions | pullActions) => void;
  
let defaultErrorHandler: ErrorHandler = (error: any, action: stateActions | pullActions) => {
    console.log(`error occured on action '${action}' with message:`, error)
};

export function setDefaultErrorHandler(handler: ErrorHandler) {
    defaultErrorHandler = handler;
}

type jsonFields = {
    load?: string;
    groupManipulation?: string; 
    manipulation?: string;
}

export interface IRemoteRequest {
    doRemoteCall:    (action: stateActions, value: any, uid: string, searchParams?: Object, onError?: (message: string) => void) =>  Promise<any>;
    setParams:       (params: Object) => void;
    params:          Object;
    urlTemplate:     string;
    searchTemplate?: string;
    url:             string;
    jsonField:       jsonFields,
    dataProvider:    IDataProvider;
}

export interface IDataProvider {
    connection: AxiosInstance;
    requests:   IRemoteRequest[];
    new:        (params: { urlTemplate: string, searchTemplate?: string }) => IRemoteRequest;
    release:    (request: IRemoteRequest) => void;
}

export interface IConnectionConfig {
    baseURL:    string;
    timeout:    number;
}

export function createDataProvider(): IDataProvider {

    const dataProvider: IDataProvider = {       
        connection: apiClient,
        requests:   [],
        new: (params: { urlTemplate: string, searchTemplate?: string }): IRemoteRequest => {
            let request = dataProvider.requests.find(
                (element, index, array) => { return element.url === params.urlTemplate }
            );
                       
            if (request) {
                return request;
            }

            request =
            {
                dataProvider:   dataProvider,
                urlTemplate:    params.urlTemplate,
                searchTemplate: params.searchTemplate,
                jsonField:      {},
                url: '',
                params: {},

                doRemoteCall: async (action: stateActions, data: any, uid: string, searchParams?: Object, onError?: (message: string) => void): Promise<any> => {
                    const promise   = _doRemoteCall(request!, action, data, uid, searchParams, onError);
                    const sync      = syncStateByUID(uid);
                    return promise;
                },

                setParams: (params: Object) => {
                    request!.params = params;
                    request!.url    = resolveParams(request!.urlTemplate, params);
                },
                
            }

            dataProvider.requests.push(request);
            return request;
        },

        release: (request: IRemoteRequest) => {
            dataProvider.requests = dataProvider.requests.filter((element, index, array) => { return element !== request });
        }
    }
    return dataProvider;
}

export interface SyncState<S, T> {
    state:          () => S,
    add:            (value: T | any)              => Promise<any>;
    updatePost:     (value: T | any)              => Promise<any>;
    updatePut:      (value: T | any)              => Promise<any>;
    updatePatch:    (value: T | any)              => Promise<any>;
    del:            (value: T | any)              => Promise<any>;
    active?:        boolean;
    execute:        (params: Object)        => Promise<any>;
    load:           (loadParams?: Object)   => Promise<any>;
    initLoad:       (loadParams?: Object)   => Promise<any>;
    setParams:      (requestParams: Object) => SyncState<S, T>;
    pullData:       (method: pullActions, data: Object, afterId?: any) => void;
    explicitAction?:true,
    request?:       IRemoteRequest;  
    onError?:       (message: string) => void;   

    applyData:      Event<S | string>;                               // loads data from from proper state structure
	
    find:           (key: any, field?: string)  => T[];
    findFirst:      (key: any, field?: string)  => T | undefined,    
    createIndex:    (field?: string)            => boolean;
    rebuildIndexes: ()                          => void; // use only if you use sophisticated manipulations with state, all standard actions rebuild indexes automatically

    free:           () => void;
    store:          Store<S>;
}

type IndexEntire = Map<any, number[]>;

interface orderedData<T>{
    data: T,
    afterId: number
}

interface ISyncStateHelper<S, T> extends SyncState<S, T> {
    uid:                string;
    pullGet:            Event<T>;
    pullAdd:            Event<T>;
    pullAddOrd:         Event<orderedData<T>>
    pullReplace:        Event<T>;
    pullUpdate:         Event<T>;
    pullDel:            Event<T>;
    pullNotification:   Event<T>;
    searchParams?:      Object;
    indexes:            Map<string, IndexEntire>;
    onFree?:            (state: ISyncStateHelper<S, T>) => void;
    doAction:           (action: stateActions, value: T | Object | undefined) => Promise<any>;
    pull:               (action: pullActions, value: S | T, afterId?: number) => S | undefined;
}

let syncStates: ISyncStateHelper<any, any>[] = [];

function applyStateData<S, T>(action: pullActions, syncState: ISyncStateHelper<S, T>, v: any) {
    (action === pullActions.add      && syncState.pullAdd(v))        ||
    (action === pullActions.del      && syncState.pullDel(v))        ||
    (action === pullActions.replace  && syncState.pullReplace(v))    ||
    (action === pullActions.update   && syncState.pullUpdate(v))     ||
    (action === pullActions.set      && syncState.pullGet(v));
}

export interface ISyncStateParams<S, T> {
    store?:         Store<S>,
    uid:            string, 
    explicitAction?:true,
    request?:       IRemoteRequest,                 // if request is empty all the changes will be local and not impact the remote storage data
    jsonField?:     jsonFields,                     // JSON field used for state entity, if jsonField undefined then JSON itself is a sntity of the state
    preCast?:       (value: any)                    => any,     // for occasional using, using this function indicates not the best decomposition of abstract model
    postCast?:      (value: any, state: S, action: pullActions)          => any,     // for occasional using, using this function indicates not the best decomposition of abstract model
    preLoad?:       (value: any)                    => any,
    idValue?:       (value: T)                      => any,     // returns unique ID value of object, example: if "item = {id: number, name: 'Rose'}"" then "idValue: (value) => value.id"
    isObject?:      true,

    onFree?:        (state: ISyncStateHelper<S, T>) => void,
    onError?:       (message: string) => void,

    affector?:      (state: S, 
                     value: T,
                     action: stateActions) => [affected: boolean, effect: S], // don't use in general, only for extraordinary cases  
}

export function syncStateByUID<T = any>(uid: string) {
    return syncStates.find((state: ISyncStateHelper<T[], T>) => state.uid === uid) as SyncState<T[], T>;
}

export function createSyncState<S = any, T = any>(params: ISyncStateParams<S, T>): SyncState<S, T> | undefined {

    const existingState = syncStates.find((state: ISyncStateHelper<any, any>) => state.uid === params.uid);
    if (existingState) { 
        return existingState;
    }

    params.store = params.store || createStore<S>((params.isObject && {} || [] as unknown) as S);

    const syncState: ISyncStateHelper<S, T> = {
        request: 		    params.request,
        pullGet:            createEvent<T>(),
        pullAdd: 	        createEvent<T>(),
        pullAddOrd:         createEvent<orderedData<T>>(),
        pullDel: 	        createEvent<T>(),
        pullReplace:        createEvent<T>(),
        pullUpdate:         createEvent<T>(),
        pullNotification:   createEvent<T>(),
        applyData:          createEvent<S | string>(),
        store:              params.store!,
        uid:                params.uid,
        explicitAction:     params.explicitAction,
        indexes:            new Map<string, IndexEntire>(),
        onError:            params.onError,

        state: (): S => {
            return syncState.store!.getState();
        },

        setParams: (requestParams: Object) => { params.request && params.request.setParams(requestParams); return syncState; },

        pullData: (method: pullActions, data: any, afterId?: any) => {
            (method === pullActions.add     && (afterId || afterId === 0) && syncState.pullAddOrd ({data: data, afterId: afterId})) ||
            (method === pullActions.add     && syncState.pullAdd    (data)) ||
            (method === pullActions.del     && syncState.pullDel    (data)) ||
            (method === pullActions.replace && syncState.pullReplace(data)) ||
            (method === pullActions.update  && syncState.pullUpdate (data)) ||
            (method === pullActions.set     && syncState.pullGet(data)) 
        },

        add:            async (value: T | any)      => { return syncState.doAction(stateActions.add, value) },
        del:            async (value: T | any)      => { return syncState.doAction(stateActions.del, value) },
        updatePost:     async (value: T | any)      => { return syncState.doAction(stateActions.updatePost, value) },
        updatePut:      async (value: T | any)      => { return syncState.doAction(stateActions.updatePut, value) },
        updatePatch:    async (value: T | any)      => { return syncState.doAction(stateActions.updatePatch, value) },
        load:           async (loadParams?: Object) => { syncState.searchParams = loadParams; return syncState.doAction(stateActions.load, undefined) },        
        initLoad:       async (loadParams?: Object) => { return !syncState.active && syncState.load(loadParams) || new Promise<S>((resolve, reject) => { resolve(syncState.state()); }); },    
        execute:        async (o: Object)           => { return syncState.doAction(stateActions.execute, o) },

        find: (key: any, field?: string): T[] => {
            const state = syncState.state() || [];
            const index = syncState.indexes.get(field || keyIndex);

            return Array.isArray(state) && index ? 
                    (index.get(key) || []).map((value) => state[value]) : 
                    params.idValue && Array.isArray(state) ? (state as unknown as any[]).filter((value) => params.idValue!(value) === key) : [];
        },

        findFirst: (key: any, field?: string): T | undefined => {
            const state = syncState.state();
            const index = syncState.indexes.get(field || keyIndex);

            if (Array.isArray(state) && index) {
                const indexes = index.get(key) || [];
                for (let i = 0; i < indexes.length; i++) {
                const value = state[indexes[i]];
                if (value !== undefined) {
                    return value;
                }
                }
            } else if (params.idValue) {
                return (state as unknown as any[]).find((value) => value === key);
            }          
            return undefined;
        },

        createIndex: (field?: string): boolean => {
            const state = syncState.state();
            const indexField = field || (params.idValue && keyIndex);
            const index =   indexField && syncState.indexes.get(indexField)                                         || 
                            indexField && syncState.indexes.set(indexField, new Map<any, number[]>()).get(indexField) || 
                            undefined;

            if (!index || !Array.isArray(state)) {
                return false;
            }

            index.clear();
            return state.every((value, i) => {
                const key = field ? value[field] : params.idValue!(value);
                if (key !== undefined) {
                    index.has(key) ? index.get(key)?.push(i) : index.set(key, [i]); 
                    return true;
                }
                return false;
            });
        },     

        rebuildIndexes: (): void => {
            syncState.indexes.forEach((value, key) => {
                syncState.createIndex(key === keyIndex ? undefined : key);
            });
        },

        free: (): void => {
            freeSyncState(syncState);
        },

        doAction: async (action: stateActions, value: T | Object | undefined): Promise<S> => {
            return doStateAction<S, T>(syncState, params, action, value);
        },

        pull: (action: pullActions, value: S | T, afterId?: number): S | undefined => {
            return _pull<S, T>(params, action, value, afterId);
        },
    }

    bindStoreEvents<S, T>(params.store!, syncState);

    syncStates.push(syncState);
    return syncState;
}

const simpleDP = createDataProvider();

export function simpleSyncState<T extends {id: number}>(url: string, uid?: string) {
    return createSyncState<T[], T>({
        uid: uid || url, 
        request: simpleDP.new({ urlTemplate: url }),
        idValue: value => value.id})!;
}

export function simpleSyncObject<T>(url: string, uid?: string, inactivate?: true, isObject?: true) {
    const sync = createSyncState<T, T>({
        uid: uid || url,
        request: simpleDP.new({ urlTemplate: url }),
        isObject: isObject,
        idValue: value => null,
    })!;
    inactivate && delete sync.active;
    return sync;
}

export function freeSyncState(arg: SyncState<any, any> | string) {
    const state = (typeof arg === 'string' && syncStates.find((state) => state.uid !== arg )) || (arg as ISyncStateHelper<any, any>);
    if (state) {
        syncStates = syncStates.filter((curState) => curState !== state);
        state.indexes.forEach((index) => index.clear());
        state.indexes.clear();
        state.onFree && state.onFree(state);
    }    
}

function bindStoreEvents<S, T>(store: Store<S>, syncState: ISyncStateHelper<S, T>) {
    store
    .on(syncState.pullGet, (state, payload) => {
        return syncState.pull(pullActions.set, payload);
    })
    .on(syncState.pullAdd, (state, payload) => {
        return syncState.pull(pullActions.add, payload);
    })
    .on(syncState.pullAddOrd, (state, payload) => {
        return syncState.pull(pullActions.add, payload.data, payload.afterId);
    })
    .on(syncState.pullReplace, (state, payload) => {
        return syncState.pull(pullActions.replace, payload);
    })    
    .on(syncState.pullDel, (state, payload) => {
        return syncState.pull(pullActions.del, payload);
    })
    .on(syncState.pullUpdate, (state, payload) => {
        return syncState.pull(pullActions.update, payload);
    })
    .on<S | string>(syncState.applyData, (state, payload) => typeof payload === 'string' && JSON.parse(payload) || payload)
    .watch(() => {
        syncState.rebuildIndexes(); // TODO: modify indexes based on action type but not rebuild
    });
}

interface IAction {
    method: pullActions;
    data:   any;
}

interface IPullData extends IAction {
    state:  string;
}

type IPullDataSet = IPullData[];

interface AnyObject {
    [key: string]: any;
  }

function extractPullDataSet(obj: Object): IPullDataSet | undefined {
    if (obj && 'pushData' in obj) {
        const dataset = (obj as Object & Record<"pushData", unknown>).pushData as AnyObject;
        const res: IPullDataSet = [];
        for (const state in dataset) {
            if (Object.prototype.hasOwnProperty.call(dataset, state)) {
                const element = dataset[state] as unknown as IAction[];
                for (let k = 0; k < element.length; k++) {
                    res.push({
                        state:  state,
                        method: element[k].method,
                        data:   element[k].data,
                    });
                }    
            }    
        }
        return res;
    } else {
        return undefined;
    }
}

function stateActionToPullAction(action: stateActions): pullActions {
    return  (action === stateActions.add     && pullActions.add) ||
            (action === stateActions.del     && pullActions.del) ||
            (action === stateActions.load    && pullActions.set) ||
            ((action === stateActions.updatePatch || action === stateActions.updatePost || action === stateActions.updatePut) && pullActions.replace) ||
            pullActions.replace;
} 

async function _doRemoteCall(request: IRemoteRequest, action: stateActions, data: any, uid: string, searchParams?: Object, onError?: (message: string) => void): Promise<any> {
    
    const   url             =   removeUnresolvedParams(
                                ((typeof data === 'object' && resolveParams(request.url || request.urlTemplate, data)) || (request.url || request.urlTemplate)) + 
                                (action === stateActions.load && request.searchTemplate && 
                                    (searchParams && resolveParams(request.searchTemplate, searchParams) || request.searchTemplate) || '')
                            );

    const   dataProvider    = request.dataProvider;

    let     response:   AxiosResponse<any, any> | false = false;
    const   config:     AxiosRequestConfig<Object>      = {};

     if (action === stateActions.execute && typeof data === 'object') {
        config.url      = url;
        config.method   = 'POST';
        config.data     = data;
    };

    try {                                      
        response = 
                    ((action === stateActions.load)          && await dataProvider.connection.get        (url))       ||
                    ((action === stateActions.add)           && await dataProvider.connection.post       (url, data)) ||
                    ((action === stateActions.del)           && await dataProvider.connection.delete     (url, data)) ||
                    ((action === stateActions.updatePost)    && await dataProvider.connection.post       (url, data)) ||
                    ((action === stateActions.updatePut)     && await dataProvider.connection.put        (url, data)) ||
                    ((action === stateActions.updatePatch)   && await dataProvider.connection.patch      (url, data)) ||
                    ((action === stateActions.execute)       && await dataProvider.connection.request    (config));

        if (!response) {
            const message = `"${action}" is not a valid action for the remote call, url ${url}, data: ${data}`;
            safeErrorHandler(defaultErrorHandler, message, action);
            return errorPromise(new Error(message));
        }                                  
    }
    catch(e) {
        if (onError) { try { onError((e as any).message) } catch(err) {} }
        return errorPromise(e);
    }

    if (response.status !== 200) {
        const message = 
            (response.statusText && `remote call error [${response.status}] ${response.statusText}`) ||
            `insufficient/empty status text for status: ${response.status}`;

        safeErrorHandler(defaultErrorHandler, message, action);                            
        return errorPromise(new Error(message));
    }             

    const contentType: string | undefined       = (response.headers && response.headers['content-type']) || undefined;
    let responseData: any                       = undefined;
    let pullDataSet:  IPullDataSet | undefined  = undefined;

    if (contentType && contentType.includes('json')) {
        responseData = response.data;  
        if (typeof responseData === 'object') {
            pullDataSet = extractPullDataSet(responseData);
        }
    }

    const explicitAction = syncStateByUID(uid)!.explicitAction;

    if (!pullDataSet) {
        pullDataSet = [] as IPullDataSet;
        const jsonField =   response.data ?
                            ((action === stateActions.load || !explicitAction) && request.jsonField.load && (response.data[request.jsonField.load] || response.data[request.jsonField.load] === null) && request.jsonField.load) ||
                            (request.jsonField.manipulation && response.data[request.jsonField.manipulation] && request.jsonField.manipulation) || 
                            (request.jsonField.groupManipulation && response.data[request.jsonField.groupManipulation] && request.jsonField.groupManipulation) ||
                            '' : '';
        
        action = (explicitAction && action) || (request.jsonField.load && response.data[request.jsonField.load] && stateActions.load) || action;
        const applyingData = response.data && (jsonField && (response.data[jsonField] || []) || 
                             (action === stateActions.del && typeof response.data.id === 'undefined' && data || response.data) ) || 
                             data;
        if ((action !== stateActions.load && !Array.isArray(applyingData)) || action === stateActions.load) {
            pullDataSet.push({ 
                state:  uid,
                method: stateActionToPullAction(action),
                data:   applyingData,
            })
        } else {
            (applyingData as any[]).forEach((data) => 
                pullDataSet!.push({ 
                    state:  uid,
                    method: stateActionToPullAction(action),
                    data:   data,
                })
            )
        }
    }

    let dataConsumed = false;
    pullDataSet.forEach((pullData) => {
        const syncState = syncStates.find((value) => value.uid === pullData.state);
        dataConsumed = dataConsumed || (syncState && true || false);
        syncState && (
            (pullData.method === pullActions.add     && syncState.pullAdd    (pullData.data)) ||
            (pullData.method === pullActions.del     && syncState.pullDel    (pullData.data)) ||
            (pullData.method === pullActions.replace && syncState.pullReplace(pullData.data)) ||
            (pullData.method === pullActions.update  && syncState.pullUpdate (pullData.data)) || 
            (pullData.method === pullActions.set     && syncState.pullGet    (pullData.data))
        );
    });

    return new Promise<any>((resolve, reject) => {
        resolve((response && response.data) || undefined);
    });
}

export function mergeObjects<T>(item: T, v: Partial<T>): T {
    const mergedObject = item && { ...item } || item;
  
    for (const key in v) {
      if (v.hasOwnProperty(key)) {
        if (Array.isArray(mergedObject[key]) || Array.isArray(v[key])) {
            mergedObject[key] = v[key] || ([] as any);
        } else
            if (typeof v[key] === 'object' && typeof mergedObject[key] === 'object') {
                mergedObject[key] = mergeObjects(mergedObject[key], v[key]!);
            } else {
                mergedObject[key] = v[key]!;
            }
      }
    }
  
    return mergedObject;
}

function _pull<S, T>(params: ISyncStateParams<S, T>, action: pullActions, value: S | T, afterId?: number): S | undefined {
    try {
        let v: any = (params.postCast && action !== pullActions.set && params.postCast(value as T, params.store!.getState(), action)) || value;
        switch (action) {
            case pullActions.set:
                try {
                    v = (params.preLoad && params.preLoad(v)) || v;
                    syncStateByUID(params.uid).active = true;
                    return v as S;
                } catch(e) {
                    console.log('SyncState set operation error', e);
                    return undefined;
                }                
                break;

            case pullActions.add:
                if (Array.isArray(params.store!.getState())) {
                    if (Array.isArray(v)) {
                        return  [...((params.store!.getState()) as unknown as any[])
                                .map(item => 
                                    ({...item, ...((v as any[]).find(value => params.idValue!(item) === params.idValue!(value)) || {} )})
                                )] as unknown as S
                    } else { 
                        if (afterId || afterId === 0) {
                            let state = params.store!.getState() as unknown as any[];
                            const oldItemIndex = state.findIndex(item => params.idValue!(v) === params.idValue!(item));
                            const oldItem = oldItemIndex > -1 && state[oldItemIndex] as Object || {};
                            state = oldItemIndex === -1 ? state : [...state.slice(0, oldItemIndex), ...state.slice(oldItemIndex + 1)];
                            const afterIdIndex = state.findIndex((item) => params.idValue!(item) === afterId);
                            
                            return [...(state.slice(0, afterIdIndex + 1)), {...oldItem, ...v}, ...(state.slice(afterIdIndex + 1))] as unknown as S;
                        } else                    
                            return [...((params.store!.getState()) as unknown as any[]).filter(
                                            (item) => params.idValue!(item) !== params.idValue!(v)
                                        ) as unknown as any[], v] as unknown as S
                    }
                }
                if (typeof params.store!.getState() === typeof v) {
                    return v as unknown as S;
                }     
                break;

            case pullActions.del:
                if (Array.isArray(params.store!.getState())) {
                    if (Array.isArray(v)) {
                        return v as any as S
                    }
                    if (params.idValue) {
                        return  [...((params.store!.getState()) as unknown as any[]).filter(
                                    (item) => params.idValue!(item) !== params.idValue!(v)
                                )] as unknown as S;
                    }
                    return ((params.store!.getState()) as unknown as any[]).filter((item) => item !== v) as unknown as S
                }
                if (typeof params.store!.getState() === typeof v) {
                    return undefined;
                }
                break;

            case pullActions.replace:
                if (params.idValue && Array.isArray(params.store!.getState())) {
                    if (Array.isArray(v)) {
                        return v as any as S
                    } else                    
                        return  [...((params.store!.getState()) as unknown as any[]).map(
                                    (item) => { 
                                        return (params.idValue!(item) !== params.idValue!(v) && item) || v 
                                    }
                                )] as unknown as S;
                }                
                if (typeof params.store!.getState() === typeof v) {
                    return v as unknown as S;
                }     
                break;

            case pullActions.update:
                if (typeof v !== 'object') {
                    safeErrorHandler(defaultErrorHandler, new Error(`update path requires object type argument, real: ${v}`), stateActions.updatePatch);
                }
                if (params.idValue && Array.isArray(params.store!.getState())) {
                    return  [...((params.store!.getState()) as unknown as any[]).map(
                                (item) => { 
                                    return (params.idValue!(item) !== params.idValue!(v) && item) ||
                                           ((typeof v === 'object' && typeof item === 'object' && mergeObjects(item, v)) || item);
                                }
                            )] as unknown as S;
                }                
                if (typeof params.store!.getState() === typeof v) {
                    return v as unknown as S;
                }     
                break;
        }
    }    
    catch(e) {
        safeErrorHandler(defaultErrorHandler, e, action); 
    }
    return params.store!.getState();
}

function doStateAction<S, T>(syncState: ISyncStateHelper<S, T>, params: ISyncStateParams<S, T>, action: stateActions, value: T | Object | undefined): Promise<S> {
    if (action === stateActions.load && !params.request) {
        return errorPromise(new Error(`remote request object not defined for load opearation`));
    }

    let v: any = undefined;
    if (value) {
        try      { v = (params.preCast && params.preCast(value)) || value }    
        catch(e) { return errorPromise(e) }
    }

    if (syncState.request) {
        syncState.request.jsonField = params.jsonField || {};
        return syncState.request.doRemoteCall(action, v, syncState.uid, syncState.searchParams, syncState.onError);
    }

    v && applyStateData(stateActionToPullAction(action), syncState, v);

    return new Promise<S>((resolve, reject) => {
        resolve(v);
    });
}

function resolveParams(template: string, params: Object): string {
    const regex = /\${([\w]+)}/g;    
    return template.replace(regex, (match, param) => {
        if ((params as any).hasOwnProperty(param)) {
            return String((params as any)[param]);
        } else {
            return match; 
        }
    });
}

function removeUnresolvedParams(template: string): string {
    let res = template.replace(/\b[a-zA-Z0-9_]+=\${[^}]+}\s*/g, '');    // delete all a=${b}
    res = res.replace(/\${[^}]+}/g, '');                                // delete all ${b}
    res = res.replace(/&{2,}/g, '&');                                   // replace all repeated ampersands by single &
    res = res.replace(/\/{2,}/g, '/');                                  // replace all repeated slashes by single slash
    res = res.replace(/\?+&+/g, '?').trim();                            // replace ?& (many ampersands) by ? 
    res = res.replace(/\/\?/g, '?');                                    // replce /? by ?
    return (res.endsWith('&') || res.endsWith("/")) && res.slice(0, -1) || res;                // removes tail & and /
}

function errorPromise<T = any, E = any>(error: E): Promise<T> {
    return new Promise((resolve, reject) => {
        reject(error);
    });
}

function safeErrorHandler(errorHandler: ErrorHandler | undefined, error: any, action: stateActions | pullActions): void {
    try {
        errorHandler && errorHandler(error, action);
    } catch (e) {
        console.log(`error occured in error handler on action '${action}' with message:`, error);
    }
}

export async function chainLoad(chain: (SyncState<any, any> | { syncState: SyncState<any, any>, params?: Object })[]): Promise<any | any[]> {
    const res: any[] = []; 
    try {
        for (const item of chain) {
            const syncState = 'syncState' in item ? item.syncState : item;
            const params    = 'params' in item && item.params || undefined;
            res.push(await syncState.load(params));
        }
    } catch (error) {
        return errorPromise(error);
    }  

    return new Promise<any[]>((resolve, reject) => {
        resolve(res);
    });
}