import { createRoot } from 'react-dom/client';
import { Application } from './Application';
import './App.css'
import './css/common.css'

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(<Application />);
}
