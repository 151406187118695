import React, { CSSProperties, useEffect, useState } from 'react';
import './Switcher.css';
import '../../bcUsers.css';

/**
 * Styles for individual switch items.
 */
export interface SwitchItemStyles {
    /** CSS styles for a switch item. */
    switchItemStyle?: CSSProperties;
    /** CSS styles for a switch item when it's checked. */
    switchItemStyleChecked?: CSSProperties;
}

/**
 * Properties for the SwitchItem component.
 */
interface SwitchItemProps<T> extends SwitchItemStyles {
    /** The unique value associated with this switch item. */
    value: T;
    /** Text label displayed on the switch item. */
    label: string;
    /** Indicates whether the switch item is currently selected. */
    checked: boolean;
    /** Callback function invoked when the item is clicked. */
    onClick: (value: T, label: string) => void;
}

/**
 * Component for rendering an individual item in the Switcher component.
 */
export function SwitchItem<T>({
    value,
    label,
    checked,
    onClick,
    switchItemStyle,
    switchItemStyleChecked
}: SwitchItemProps<T>) {
    const className = `switchItem${checked ? " checked" : ""}`;
    const appliedStyle = checked ? switchItemStyleChecked : switchItemStyle;
    const handleClick = () => onClick(value, label);

    return <div className={className} onClick={handleClick} style={appliedStyle}>{label}</div>;
}

/**
 * Defines the structure of an item in the Switcher component.
 */
export interface SwitcherItem<T> {
    /** The value associated with the switcher item. */
    value: T;
    /** Display label for the switcher item. */
    label: string;
    /** Indicates if the item is selected by default. */
    defaultValue?: boolean;
}

/**
 * Properties for the Switcher component.
 */
export interface SwitcherProps<T> extends SwitchItemStyles {
    /** Array of items to be rendered in the switcher. */
    items: SwitcherItem<T>[];
    /** Allows multiple items to be selected if true. */
    multiSelection?: boolean;
    /** Ensures at least one item is always selected if true. */
    notNull?: boolean;
    /** Function called when the selection state changes. */
    onChange: (value: T, label: string, checked: boolean, selectedValues: T[]) => void;
    /** Optional CSS styles for the switcher container. */
    style?: CSSProperties;
}

/**
 * Renders a collection of switchable items, functioning as a customizable switch or toggle group.
 * It supports single or multiple selections based on the `multiSelection` prop.
 * The `Switcher` component is versatile, allowing for a dynamic list of items, each represented by `SwitcherItem`.
 *
 * Props:
 * - `items`: Array of `SwitcherItem<T>` objects, defining the switchable elements.
 * - `multiSelection`: Optional boolean to enable multiple items to be selected simultaneously.
 * - `notNull`: Optional boolean to ensure at least one item remains selected at all times.
 * - `onChange`: Callback function invoked when an item's selection state changes. Provides the value, label,
 *   selection state, and array of selected values.
 * - `style`: Optional `CSSProperties` for custom styling of the switcher container.
 * - `switchItemStyle`: Optional custom styles for individual switch items.
 * - `switchItemStyleChecked`: Optional custom styles for switch items when selected.
 *
 * The component manages the selection states internally and responds to user interactions, making it
 * suitable for various selection scenarios in a user interface.
 */
export function Switcher<T>({
    items,
    multiSelection = false,
    notNull = false,
    onChange,
    style,
    switchItemStyle,
    switchItemStyleChecked
}: SwitcherProps<T>) {
    const [selectedValues, setSelectedValues] = useState<T[]>(() => 
        items.filter(item => item.defaultValue).map(item => item.value)
    );

    useEffect(() => {
        setSelectedValues(items.filter(item => item.defaultValue).map(item => item.value));
    }, [items]);

    const handleItemClick = (value: T, label: string) => {
        setSelectedValues(prev => {
            const newValue = multiSelection
                ? toggleValue(prev, value)
                : [value];
            const isChecked = newValue.includes(value);
            if (notNull && newValue.length === 0 && items.length > 0) {
                const lastValue = items[items.length - 1].value;
                onChange(lastValue, items[items.length - 1].label, true, [lastValue]);
                return [lastValue];
            }
            onChange(value, label, isChecked, newValue);
            return newValue;
        });
    };

    const toggleValue = (values: T[], value: T) => {
        const index = values.indexOf(value);
        return index >= 0 ? values.filter(v => v !== value) : [...values, value];
    };

    return (
        <div className="switcher" style={style}>
            {items.map((item, index) => (
                <SwitchItem
                    key={index}
                    value={item.value}
                    label={item.label}
                    checked={selectedValues.includes(item.value)}
                    onClick={handleItemClick}
                    switchItemStyle={switchItemStyle}
                    switchItemStyleChecked={switchItemStyleChecked}
                />
            ))}
        </div>
    );
}
