import { validateToken } from "./containers/Login/LoginUtils";
// import { getLocaleFx, localeEv } from "./models/platformitems";
import { callRefBooks } from "./utils/refbooks";
import * as Auth from "./utils/auth";
import { getLangResourceInit, t } from "./locale";

export function InitApp(initComplete: (isAuthorized: boolean, error?: boolean, errorMessage?: string) => void) {

    getLangResourceInit()
    .then(st => {
        Auth.setLocaleResources(st);

        if (!Auth.getRefreshToken()) {
            initComplete(false);
            return;
        }

        validateToken()
            .then(result => {
                if (!result) {
                    initComplete(false);
                    return;
                }

                Auth.setUserProfile(result);

                callRefBooks()
                    .then(() => initComplete(true))
                    .catch(reason => initComplete(false, true, reason));
            })
            .catch(reason => initComplete(false));
    })
}