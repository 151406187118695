import { createActiveStore } from "../../state/ActiveStore";
import { IUser } from "./types";

export const imgsPath = 'static/img';
export const imgs = `${window.location.protocol}//${window.location.host}/${imgsPath}`;
export const imgss = `${window.location.protocol}//${window.location.host}/${imgsPath}/`;

export enum ModalClasses {
    '*'          = '*',
    grider       = 'grider',
    dataSet      = 'dataset',
    settings     = 'settings',
    organization = 'organization',
    project      = 'project',
}

export enum ModalNames {
    projects        = 'projects',
    createSampleProject   = 'createSampleProject',
    details         = 'details',
    
    trades          = 'trades',
    roles           = 'roles',
    tradeMembers    = 'trade-members',
    schedules       = 'schedules',
    importSchedule  = 'importSchedule',
    inviteUser      = 'memberships',
    organizationsUsers  = 'organizations/users',

    createMilestone = 'createMilestone',
    editMilestone   = 'editMilestone',
    viewMilestone   = 'viewMilestone',

    createTask      = 'createTask',
    editTask        = 'editTask',
    viewTask        = 'viewTask',

    model           = 'model',

    swimlanes       = 'swimlanes',

    subtasks        = 'subtasks',

    accountSettings = 'accountSettings',
    organizationSettings = 'organizationSettings',
    scheduleSettings = 'scheduleSettings',

    organizations = 'organizations',
    profile = 'orgProfile',

    messagebox = 'messagebox',

    taskAttachments = 'taskAttachments',

    licenses = 'licenses',
}

export enum IconSide {
    OnLeft,
    OnRight,
}

export const currentUser = createActiveStore<IUser | null>(null);

export const urlModuleOrder = 1;

export const urlIdOrder = 3;

export const NoneValue = '%None%';

export const scheduleStatuses: {id: number, name: string}[] = [
    { id: 1, name: "Draft" },
    { id: 2, name: "Ready to look ahead" },
    { id: 3, name: "Ready to schedule work" },
    { id: 4, name: "In progress" },
    { id: 5, name: "Completed" },
    { id: 6, name: "Archived" }
  ];
