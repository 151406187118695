import React, { ReactNode, CSSProperties } from 'react';
import { ControlsPanel } from './ContolsPanel';

/**
 * RightPanel Component - A horizontal panel for aligning content to the right edge (horizontal backward).
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} [props.edges=true] - Whether to add edges (border, padding, and margin) to the panel.
 * @param {CSSProperties} [props.style] - Additional CSS styles to apply to the panel.
 *
 * @returns {JSX.Element} The rendered RightPanel component.
 *
 * @example
 * // Example usage:
 * <RightPanel edges={false} style={{ backgroundColor: 'lightgray' }}>
 *   Your content goes here
 * </RightPanel>
 */
export function RightPanel({ children, edges = true, style, classes }: { children: ReactNode; edges?: boolean; style?: CSSProperties; classes?: string }) {
  return <ControlsPanel 
            children={children} 
            edges={edges} 
            style={style} 
            orientation='horizontal' 
            alignment='end'
            classes={classes} 
        />;
}

/**
 * FlatRightPanel Component - A flat horizontal panel for aligning content to the right edge (horizontal backward) without borders, margins and paddings.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {CSSProperties} [props.style] - Additional CSS styles to apply to the panel.
 *
 * @returns {JSX.Element} The rendered FlatRightPanel component.
 *
 * @example
 * // Example usage:
 * <FlatRightPanel style={{ backgroundColor: 'lightgray' }}>
 *   Your content goes here
 * </FlatRightPanel>
 */
export function FlatRightPanel({ children, style, classes }: { children: ReactNode; style?: CSSProperties; classes?: string }) {
  return (
    <RightPanel edges={false} style={style} classes={classes}>
      {children}
    </RightPanel>
  );
}