import './AppHeader.css';
import { UserResume } from './UserResume';

/**
 * `Header` Component
 * 
 * Serves as the header section of the application, integrating key elements 
 * for user interaction and information display.
 * 
 * Features:
 * - Incorporates the `UserResume` component, displaying user-related details.
 * - Includes a vertical delimiter for visual separation of header elements.
 * - Contains an 'alarm' icon, which can represent notifications or alerts.
 * - Integrates the `SearchField` component, allowing users to input search queries.
 * 
 * The layout and styling are managed via the 'Header.css' file, ensuring a consistent 
 * and clean user interface. The 'alarm' element includes an 'active' state, which can be 
 * used to indicate new notifications or important alerts.
 */
export function AppHeader() {
    return (
        <div className="appHeader">
            <UserResume />
            <div className='vdelimeter'></div>
        </div>
    );
}