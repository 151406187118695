import { Grider } from "../../components/AppComponents/Grider/Grider";
import { ModalClasses, ModalNames } from "../../components/AppComponents/variables";
import { Button } from "../../components/BCComponents/Controls/Buttons/Buttons";
import { Header } from "../../components/BCComponents/Layouts/Layouts";
import { FlatVPanel } from "../../components/BCComponents/Layouts/Panels/ArrangementPanels";
import { FlatRightPanel } from "../../components/BCComponents/Layouts/Panels/RightPanel";
import { OnTabChangeEvent, TabView } from "../../components/BCComponents/Layouts/TabView/TabView";
import { getURLParam, navigateCommand, navigateParam } from "../../components/BCComponents/Navigation";
import { t } from "../../locale";
import { Licenses } from "../Licenses/Licenses";
import './Organizations.css';
import { InitOrganizationsModals, ViewOrganizationProfile } from "./OrganizationsModals";

export function Organizations() {
    return <Grider content='organizations' label={t('%Organizations%')} module="admin" />
}

export function Organization() {
    const onTabChange: OnTabChangeEvent = (source, target, setControlPanel) => {
        navigateParam('tab', target.id.toString());
        // setControlPanel(<FlatRightPanel>
        //     <Button icon="settings" iconColor="var(--highlightedColor)" onClick={() => {
        //         navigateCommand(ModalClasses.settings, { name: ModalNames.organizationSettings, method: 'add' });
        //     }}>{t('%Settings%')}</Button>
        // </FlatRightPanel>);
    }

    return (<>
        <Header label={ t('%Organization%') } />
        <TabView pages={[{id: 'profile', label: t('%Profile%')}]}
                 onChange={ onTabChange }
                 activeTab={ getURLParam('tab') }
                 classes="organizationsPageTabs"
        >
            <FlatVPanel width="100%">
                <Profile />
                <Licenses />
            </FlatVPanel>
        </TabView>
    </>)
}

function Profile() {
    return  (
        <FlatVPanel classes="organizationModalContainer">
            <ViewOrganizationProfile />
        </FlatVPanel>
    )    
}

InitOrganizationsModals();