import { ReactNode, CSSProperties } from 'react';
import { ControlsPanel } from './ContolsPanel';

/**
 * ControlsPanel Component - A horizontal panel for aligning content to the left edge.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} [props.edges=true] - Whether to add edges (border, padding, and margin) to the panel.
 * @param {CSSProperties} [props.style] - Additional CSS styles to apply to the panel.
 *
 * @returns {JSX.Element} The rendered ControlsPanel component.
 *
 * @example
 * // Example usage:
 * <LeftPanel edges={false} style={{ backgroundColor: 'lightgray' }}>
 *   Your content goes here
 * </LeftPanel>
 */
export function LeftPanel({ children, edges = true, style, classes}: { children: ReactNode; edges?: boolean; style?: CSSProperties; classes?: string}) 
{
  return <ControlsPanel 
            children={children} 
            edges={edges} 
            style={style} 
            orientation='horizontal' 
            alignment='start' 
            classes={classes}
        />;
}

/**
 * FlatLeftPanel Component - A flat horizontal panel for aligning content to the left edge without borders, margins and paddings.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {CSSProperties} [props.style] - Additional CSS styles to apply to the panel.
 *
 * @returns {JSX.Element} The rendered FlatLeftPanel component.
 *
 * @example
 * // Example usage:
 * <FlatLeftPanel style={{ backgroundColor: 'lightgray' }}>
 *   Your content goes here
 * </FlatLeftPanel>
 */
export function FlatLeftPanel({ children, style, classes }: { children: ReactNode; style?: CSSProperties; classes?: string }) {
  return (
    <LeftPanel classes={classes} edges={false} style={style}>
      {children}
    </LeftPanel>
  );
}