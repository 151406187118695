import { Store } from "effector";
import { useStore } from "effector-react";
import { ActiveStore } from "../../state/ActiveStore";
// import { t } from "../../models/platformitems";
import { t } from "../../locale";

export enum passwordFailReason {
    oneLetter,
    oneNumber,
    eightChars,
    threeUnique
}

const letters = 1;
const numbers = 1;
const minUniques = 3;
const minChars = 8;

/**
 * React component that renders a list of password requirements.
 * This function creates a checklist indicating the password criteria
 * such as minimum number of letters, numbers, overall length, and unique characters.
 * It utilizes the `t` function for possibly translating or formatting the text.
 * @returns A React JSX element containing the structured list of password requirements.
 */
export function PasswordMust({ failReason }: {failReason: Store<passwordFailReason[]>}) {
    const _failReason = useStore(failReason);
    
    return (
        <div className='checkList font08'>
            <label>{t('%Password must contain%')}</label>
            <div className={`item ${_failReason.includes(passwordFailReason.oneLetter) ? 'fail' : ''}`}>{t(`%At least ${letters} letter%`)}</div>
            <div className={`item ${_failReason.includes(passwordFailReason.oneNumber) ? 'fail' : ''}`}>{t(`%At least ${numbers} number%`)}</div>
            <div className={`item ${_failReason.includes(passwordFailReason.eightChars) ? 'fail' : ''}`}>{t(`%Minimum of ${minChars} characters%`)}</div>
            <div className={`item ${_failReason.includes(passwordFailReason.threeUnique) ? 'fail' : ''}`}>{t(`%At least ${minUniques} unique characters%`)}</div>
        </div>
    )
}

/**
 * Validates a given password against defined criteria.
 * This function checks if the password contains at least a specified number of letters and numbers,
 * a minimum of eight characters, and at least three unique characters.
 * It optionally updates an `ActiveStore` with the failure reasons.
 * The function returns an array of failure reasons based on the `passwordFailReason` enum.
 * @param {string} password - The password string to validate.
 * @param {ActiveStore<passwordFailReason[]>} [failReason] - Optional store to be updated with the failure reasons.
 * @returns {passwordFailReason[]} An array of reasons why the password is invalid, if any.
 */
export function IsValidPassword(password: string, failReason?: ActiveStore<passwordFailReason[]>): passwordFailReason[] {
    const failReasons: passwordFailReason[] = [];
    (password.match(/[a-zA-Z]/g) || []).length < letters && failReasons.push(passwordFailReason.oneLetter);
    (password.match(/\d/g) || []).length < numbers && failReasons.push(passwordFailReason.oneNumber);
    password.length < minChars && failReasons.push(passwordFailReason.eightChars);
    new Set(password).size < minUniques && failReasons.push(passwordFailReason.threeUnique);
    failReason?.set(failReasons);
    return failReasons;
}
