import { ReactNode, useEffect, useMemo, useRef } from "react";
import { ActiveStore, createActiveStore } from "../../../state/ActiveStore";
import { useStore } from "effector-react";
import { Store } from "effector";

interface Envoy {
    id: string,
    unmounted?: true,
    element?: ReactNode | ReactNode[], 
    done?: () => void
}

const portals: Record<string, { portal: ActiveStore<string>, envoys: Record<string, ActiveStore<Envoy>>}> = {};

let counter = 1;

function EnvoyController({ envoyStore }: { envoyStore: Store<Envoy> }) {
    const envoy = useStore(envoyStore);
    useEffect(() => {
        envoy.element && !envoy.unmounted && envoy.done && envoy.done();
    }, [envoy.element, envoy.done, envoy.unmounted]);

    return(<>{ !envoy.unmounted && envoy.element }</>)
}

export function Portal({ name }: {name: string}) {
    const persistentName = useRef(name);
    !portals[name] && (portals[name] = { portal: createActiveStore('0'), envoys: {} });
    useStore(portals[name].portal.store);

    return (<>{portals[name] && Object.keys(portals[name].envoys).map(envoyId => 
        <EnvoyController key={envoyId} envoyStore={portals[name].envoys[envoyId].store} />
    )}</>);
}

export function InPortal({ name, children, done }: {name: string, children?: ReactNode | ReactNode[], done?: () => void}) {
    const id = useMemo(() => (counter++).toString(), []);
    const envoyInited = useRef<boolean>(false);

    useEffect(() => { return () => { 
            const envoy = portals[name] ? portals[name].envoys[id] : undefined; 
            envoy?.set({ id: id, element: undefined, unmounted: true });
            envoy && delete portals[name].envoys[id];
    }}, []);

    useEffect(() => {
        if (portals[name] && !portals[name].envoys[id]) { 
            (portals[name].envoys[id] = createActiveStore<Envoy>({ id: id, element: children, done: done }));
            portals[name].portal.set(id);
            envoyInited.current = true;
        } else {
            envoyInited.current && 
            portals[name] && portals[name].envoys[id] && 
            portals[name].envoys[id].set({ id: id, element: children, done: done });
        }
    });
    return (<></>);
}