import { Navigation } from '../../../components/BCComponents/Navigation';
import { Organization, Organizations } from '../../../modules/Organizations/Organizations';
import { Permissions } from '../../../modules/Security/Permissions/Permissions';
import { Security } from '../../../modules/Security/Security';
import './Content.css';

export function Content() {
    return (
        <div className="appContent lastChildIsContainer">
            <Navigation path='*/#'>
                <h1>Welcome</h1>                  
            </Navigation> 

            <Navigation path='*/organizations'>
                <Organizations />                 
            </Navigation> 

            <Navigation path='*/portal/organizations'>
                <Organization />                 
            </Navigation> 

            <Navigation path='*/security'>
                <Security />                 
            </Navigation> 

            <Navigation path='*/platform/roles'>
                <Permissions />
            </Navigation>
        </div>
    )
}