import React, { useState, useEffect, useRef, KeyboardEvent } from 'react';
import './SuggestionBox.css'
import { t } from '../../../locale';
import { elementRect } from '../../BCComponents/utils';
import { cssVar } from '../../../utils/utils';

const iconType: 'slices' | 'arrow' = 'arrow';

export interface SearchOption {
    key:          string;
    mainValue:    string; 
    preferred?:   true;
}

export interface SuggestionOption extends SearchOption {
  detailValue?: string;
  colorCircle?: string;
  level?:       number;
}

export type SuggestionSearchFunction = (searchValue: string, callback: (options: SuggestionOption[], moreData?: boolean) => void) => void;

export interface SuggestionProps {
    initKey?:       string;
    initValue?:     string;
    deletable?:     boolean;
    placeholder?:   string;
    searchFunction?: SuggestionSearchFunction;
    list?:           { options: SuggestionOption[], maxSuggestionsCount: number };
    delay?:         number;
    dropOnFocus?:   boolean;
    ctrlDelete?:    boolean;
    anyDelIsDel?:   boolean;
    minChars?:      number;
    showKey?:       boolean;
    colorCircle?:   string;
    truncate?:      boolean;
    disabled?:      boolean;
    local?:         boolean;
    dropAfterDelete?: boolean;
    preferredKeys?:  string[];
    onChange?:      (key?: string, mainValue?: string, detailValue?: string, color?: string) => void;
    popUpRef?:      React.MutableRefObject<HTMLDivElement | null>;

}

export const SuggestionBox: React.FC<SuggestionProps> = ( { 
                initKey     = undefined,
                initValue   = undefined,
                deletable   = true,
                colorCircle = undefined,
                dropOnFocus = true,
                ctrlDelete  = undefined,
                anyDelIsDel = undefined,
                placeholder = '',
                delay       = 200,
                minChars    = 0,
                showKey     = false,
                truncate    = true,
                disabled    = false,
                onChange    = undefined,
                dropAfterDelete = false,
                local       = false,
                list        = undefined,
                preferredKeys = [],
                popUpRef,
                searchFunction 
            } ) => {
    minChars = minChars < 0 ? 0 : minChars;
    const [searching, setSearching]                   = useState(false);
    const [options, setOptions]                       = useState<SuggestionOption[]>([]);
    const [selectedOption, setSelectedOption]         = useState<SuggestionOption | null>(null);
    const [highlightedOption, setHighlightedOption]   = useState<SuggestionOption | null>(null);
    const [active, setActive]                         = useState(false);
    const [moreDataState, setMoreDataState]           = useState(false);
    const inputRef                                    = useRef<HTMLInputElement>(null);
    const optionsRef                                  = useRef<HTMLDivElement>(null);
    const timer                                       = useRef<NodeJS.Timeout | null>(null);
    const containerRef                                = useRef<HTMLDivElement>(null);

	useEffect(() => {
		popUpRef && (popUpRef.current = optionsRef.current);
	}, [popUpRef, optionsRef.current]);    

    function listSearch(
        list: SearchOption[],
        maxSuggestionsCount: number,
        searchValue: string,
        callback: (options: SuggestionOption[], moreData?: boolean) => void,
    ) {
        const lowercaseSearch = searchValue.toLowerCase();
        const filteredItems = !lowercaseSearch ? list : list.filter(item => item.mainValue.toLowerCase().includes(lowercaseSearch));
        const res: SuggestionOption[] = [];
        filteredItems.filter(el => preferredKeys.includes(el.key)).forEach(item => res.push({ ...item, preferred: true }));
        filteredItems.slice(0, maxSuggestionsCount).filter(el => !preferredKeys.includes(el.key)).forEach(item => res.push({ ...item }));
        callback(res, filteredItems.length > maxSuggestionsCount);
    };

    function searchCallback(resultOptions: SuggestionOption[], moreData?: boolean) {
        const filteredOptions = resultOptions.filter(option => option.key !== (selectedOption?.key));
        if (selectedOption && resultOptions.some(option => option.key === selectedOption.key)) {
            setOptions([selectedOption, ...filteredOptions]);
        } else {
            setOptions(filteredOptions);
        }
        setMoreDataState(moreData || false);
        setActive(true);
        setSearching(false);
    }

    function reloadOptions(showAll?: boolean) {
        let requestDelay = delay;
        const inputValue = inputRef.current!.value;
        if ((minChars === 0) && (inputValue.trim().length === 0)) {
            requestDelay = 0;
        }
        setActive(false);
        timer.current && clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            if (inputValue.trim().length >= minChars) {
                setSearching(true);
                if (list) 
                    listSearch(list.options, list.maxSuggestionsCount, showAll ? '' : inputValue.trim(), searchCallback)
                else
                    searchFunction && searchFunction(inputValue.trim(), searchCallback);
            } else {
                setActive(false);
            }
        }, requestDelay);
    };

    function setInputValue(value: string) {
        inputRef.current!.value = value;
    }

    const initValueStr = (initValue ? initValue : initKey && list?.options.find(el => el.key === initKey)?.mainValue || '');
    useEffect(() => {
        const initOption = initKey ? 
                           { ...(list && list.options.find(item => item.key === initKey) || { key: initKey, mainValue: initValueStr, colorCircle: colorCircle }) } as SuggestionOption: 
                           null;
        setSelectedOption(initOption);
        setOptions(initOption ? [initOption] : []);
        setInputValue(initValueStr);
    }, [initKey, initValueStr, colorCircle]);

    function showBoxAndHighlight(fromBeginning: boolean) {
        const optionToHighlight = (fromBeginning) ? (options[0] || null) : (options[options.length - 1] || null);
        setHighlightedOption(optionToHighlight);
        setActive(true);
    }

    function selectAndCloseBox(option: SuggestionOption | null) {
        setHighlightedOption(null);
        setOptions(option ? [option] : []);
        setSelectedOption(option);
        setInputValue(option?.mainValue || '');
        if (dropAfterDelete && !option) {
            inputRef.current!.value = '';
            minChars === 0 && inputRef.current && reloadOptions(list ? true : false);
        } else 
            setActive(false)
        onChange && onChange(option?.key, option?.mainValue, option?.detailValue, option?.colorCircle);
    }

    function deleteSelection(e: MouseEvent | React.KeyboardEvent): void {
        if (!deletable) return;
        e.stopPropagation();
        selectAndCloseBox(null);
    }

    function discardAndCloseBox() {
        setActive(false);
        setHighlightedOption(null);
        setOptions(selectedOption ? [selectedOption] : []);
        setInputValue((selectedOption && selectedOption.mainValue) || '');
        inputRef.current!.setSelectionRange(0, 0);
    }

    function handleFocus() {
        inputRef.current!.setSelectionRange(0, inputRef.current!.value.length);
        if (!dropOnFocus && selectedOption !== null) return;
        if (options.length > 0) {
            list ? reloadOptions(true) : setActive(true); 
        }    
        else 
            selectedOption === null && minChars === 0 && reloadOptions(true);
    }  

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                if (!active) {
                    if (options.length > 0) 
                        list ? reloadOptions(true) : showBoxAndHighlight(false);
                    else 
                        minChars === 0 && reloadOptions();
                } else {
                    const currentOptionIndex    = options.findIndex(option => option === highlightedOption);
                    const nextOption            = options[currentOptionIndex - 1] || options[options.length - 1] || null;
                    setHighlightedOption(nextOption);
                }
                break;
            case 'ArrowDown':
                event.preventDefault();
                if (!active) {
                    if (options.length > 0)
                        list ? reloadOptions(true) : showBoxAndHighlight(false);
                    else 
                        minChars === 0 && reloadOptions();
                } else {
                    const currentOptionIndex    = options.findIndex(option => option === highlightedOption);
                    const nextOption            = options[currentOptionIndex + 1] || options[0] || null;
                    setHighlightedOption(nextOption);
                }
                break;
            case 'Tab':    
                if (active) {
                    discardAndCloseBox();
                    setActive(false);
                }                    
                break;
            case 'Escape':
                if (active) {
                    discardAndCloseBox();
                    setActive(false);
                    event.preventDefault();
                    event.stopPropagation();
                }                    
                break;
            case 'Enter':
                if (active) {
                    highlightedOption && selectAndCloseBox(highlightedOption);
                    event.preventDefault();
                    event.stopPropagation();
                }    
                break;
            case 'Delete':
            case 'Backspace':
                (anyDelIsDel || ctrlDelete && event.ctrlKey) && 
                selectedOption !== null && 
                deleteSelection(event);
        }
    };

    function onMouseUpOutside(event: MouseEvent) {
        active && containerRef.current && optionsRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        !optionsRef.current.contains(event.target as Node) && 
        discardAndCloseBox();
    } 

    useEffect(() => {
        if (!optionsRef.current || !inputRef.current || !active) return;
    
        const margin = 16;
        const options = optionsRef.current;
        const input = inputRef.current;
        options.style.setProperty('top', null);
        options.style.setProperty('bottom', null);

        document.body.appendChild(options);

        const inputRect = elementRect(input);
    
        options.style.width = `${inputRect.width - 2}px`;
        options.style.left = `${inputRect.xLeft}px`;
    
        const spaceAbove = inputRect.yTop - margin;
        const spaceBelow = window.innerHeight - inputRect.yBottom - margin;
    
        if (spaceBelow >= options.clientHeight) {
            options.style.top = `${inputRect.yBottom}px`;
        } else if (spaceAbove >= options.clientHeight) {
            options.style.top = `${inputRect.yTop - options.clientHeight}px`;
            options.classList.add('above');
        } else {
            if (inputRect.yTop > (4 / 7) * window.innerHeight) {
                options.style.top = `${inputRect.yTop - spaceAbove}px`;
                options.style.height = `${spaceAbove}px`;
                options.classList.add('above');
            } else {
                options.style.top = `${inputRect.yBottom}px`;
                options.style.height = `${spaceBelow}px`;
            }
        }
    
        window.addEventListener('resize', () => setActive(false));
        window.addEventListener('scroll', () => setActive(false));
        window.addEventListener('mouseup', onMouseUpOutside);
    
        return () => {
            document.body.removeChild(options);
            window.removeEventListener('resize', () => setActive(false));
            window.removeEventListener('scroll', () => setActive(false));
            window.removeEventListener('mouseup', onMouseUpOutside);
        };
    }, [active, inputRef, optionsRef, setActive]);

    
 return (
    <div 
        className="select-container"
        {...(active ? {['active']: ''} : {})}
        ref={containerRef}
    >
        <input
            type="text"
            onFocusCapture  = { handleFocus }
            onClick         = { handleFocus }
            onChange        = { () => { !ctrlDelete && inputRef.current!.value.length === 0 && selectAndCloseBox(null); reloadOptions(); } }
            ref             = {inputRef}
            onKeyDown       = {handleKeyDown}
            placeholder     = {placeholder}
            disabled        = { disabled && true || undefined }
            {...(selectedOption && selectedOption.colorCircle ? {['colorcircle']: ''} : {})}
        />
        {selectedOption && selectedOption.colorCircle && <div
            className='colorcircle'
            style={ {backgroundColor: selectedOption.colorCircle} }
        ></div>}
		{searching && <div className="searching-indicator"><span className="loading-point p1"></span><span className="loading-point p2"></span><span className="loading-point p3"></span></div>}
		{!searching && (selectedOption !== null) && iconType !== 'arrow' && <div className='selectedcheck' onClick={() => { inputRef.current!.focus(); }}>✓</div>}
        <div className={`selecticon ${iconType}`} onClick={() => { inputRef.current!.focus(); }}></div>

        <div
            className   = {`options-container`} 
            {...(active ? {['active']: ''} : {})}
            ref={optionsRef}
            {...(truncate ? {['truncate']: ''} : {})}
        >{active &&
            <> 
                { options.map(option => <Option 
                                                    key={option.key}
                                                    option={option} 
                                                    highlightedOption={highlightedOption} 
                                                    selectedOption={selectedOption} 
                                                    showKey={showKey} 
                                                    onEvent={(event, option) => { event === 'click' ? selectAndCloseBox(option) : setHighlightedOption(option) }} 
                                                    deletable={deletable} 
                                                    deleteSelection={deleteSelection} 
                                        />)}
                { options.length === 0 && <div className={`option moredata`} onClick={ discardAndCloseBox }>{ t('%no data...%') }</div> }
                { moreDataState && <div className={`option moredata`} onClick={ discardAndCloseBox }>{ t('%there are more data...%') }</div> }
            </>
        }</div>
	</div>
)};

function Option({option, showKey, highlightedOption, onEvent, selectedOption, deletable, deleteSelection}: {option: SuggestionOption, showKey: boolean, selectedOption: SuggestionOption | null, deletable: boolean, deleteSelection: (event: MouseEvent) => void, highlightedOption: SuggestionOption | null, onEvent: (event: 'click' | 'mouseOver', option: SuggestionOption) => void}) {
    const optionRef = useRef<HTMLDivElement>(null);
    const delSelectionRef = useRef<HTMLDivElement>(null);
    const detailLabel   = option.detailValue ? <div className="detailvalue">{option.detailValue}</div> : null;

    function onClick() { onEvent('click', option) }
    function onMouseOver() { onEvent('mouseOver', option) }
    function onDeleteSelection(event: MouseEvent) { deleteSelection(event) }

    useEffect(() => {
        const self = optionRef.current;
        const delSelection = delSelectionRef.current; 
        self?.addEventListener('click', onClick);
        self?.addEventListener('mouseover', onMouseOver);
        delSelection?.addEventListener('mouseup', onDeleteSelection);
        return () => {
            self?.removeEventListener('click', onClick);
            self?.removeEventListener('mouseover', onMouseOver);
            delSelection?.removeEventListener('mouseup', onDeleteSelection);
        }
    }, []);

    return (
        <div
            ref={optionRef}
            className={`option${option.preferred && ' preferred' || ''}`}
            {...(highlightedOption  === option ? {['highlighted']: ''} : {})}
            {...(showKey ? {title: option.key} : {})}
            {...(option.colorCircle ? {['colorcircle']: ''} : {})}
            style={{ ...cssVar('--level', option.level || 0) }}
        >
            {option.colorCircle && <div
                className='colorcircle'
                style={ {backgroundColor: option.colorCircle} }
            ></div>}
            <div className="mainvalue" title={ (option.mainValue?.length || 0) > 20 && option.mainValue || undefined }>{option.mainValue}</div>
            { detailLabel }
            { (selectedOption?.key === option.key) && deletable && <div className='deletbutton' ref={delSelectionRef}>🗙</div> }
        </div>
    );
};