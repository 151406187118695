import React, { ReactNode, CSSProperties, LegacyRef, useEffect, useRef, RefObject } from 'react';
import { Color } from '../../../bcGlobals';
import { TooltipHost } from '@fluentui/react';

import '../../ControlsRoot.css';
import './ButtonBase.css';
import '../../../bcUsers.css';

export type ButtonClickEvent = React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>;

/**
 * `IButtonBase` interface - Represents the properties for the base button component.
 * 
 * @typedef {Object} IButtonBase
 * @property {CSSProperties} [style] - Optional CSSProperties for additional inline styling.
 * @property {(event: React.MouseEvent<HTMLDivElement>) => void} [onClick] - Optional click event handler.
 * @property {boolean} [disabled] - Optional boolean to disable the button.
 * @property {string} [padding] - Optional padding around the button, setting the CSS variable `--padding`.
 * @property {string} [hoverBorderColor] - Optional color for the border on hover, linked to `--hover-border-color`.
 * @property {string} [hoverBorder] - Optional border style for the hover state, applied as `--hover-border`.
 * @property {string} [hoverColor] - Optional color for the button on hover, which sets the CSS `color` property when the button is hovered.
 * @property {string} [color] - Optional color for the button, which sets the CSS `color` property.
 * @property {string} [label] - Optional tooltip text that appears when the button is hovered.
 */
export interface IButtonBase {
    /**
     * Optional CSSProperties for additional inline styling.
     */
    style?: CSSProperties;

    /**
     * Optional click event handler.
     * @param {event: React.MouseEvent<HTMLDivElement>} event - The click event.
     */
    onClick?: (event: ButtonClickEvent) => void;

    /**
     * Optional boolean to disable the button.
     */
    disabled?: boolean;

    /**
     * Optional padding around the button, setting the CSS variable `--padding`.
     */
    padding?: string;

    /**
     * Optional color for the border on hover, linked to `--hover-border-color`.
     */
    hoverBorderColor?: Color;

    /**
     * Optional border style for the hover state, applied as `--hover-border`.
     */
    hoverBorder?: Color;

    /**
     * Optional color for the button on hover, which sets the CSS `color` property when the button is hovered.
     */
    hoverColor?: Color;

    /**
     * Optional color for the button, which sets the CSS `color` property.
     */
    color?: Color;

    /**
     * Optional tooltip text that appears when the button is hovered.
     */
    label?: string;

    classes?: string;

    refCallback?: ((ref: RefObject<HTMLDivElement>) => void) | undefined;

    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
}

/**
 * `ButtonBase` Component - A base button component for creating custom buttons with flexible styling.
 * 
 * @component
 * @param {Object} props - The component's props.
 * @param {string} [props.classes] - Optional classes to be applied to the button.
 * @param {CSSProperties} [props.style] - Optional CSSProperties for additional inline styling.
 * @param {function} [props.onClick] - Optional click event handler.
 * @param {boolean} [props.disabled] - Optional boolean to disable the button.
 * @param {ReactNode} props.children - The content to be displayed within the button.
 * @param {string} [props.padding] - Optional padding around the button, setting the CSS variable `--padding`.
 * @param {string} [props.hoverBorderColor] - Optional color for the border on hover, linked to `--hover-border-color`.
 * @param {string} [props.hoverBorder] - Optional border style for the hover state, applied as `--hover-border`.
 * @param {string} [props.hoverColor] - Optional color for the button on hover, which sets the CSS `color` property when the button is hovered.
 * @param {string} [props.color] - Optional color for the button, which sets the CSS `color` property.
 * @param {string} [props.label] - Optional tooltip text that appears when the button is hovered.
 * 
 * @returns {JSX.Element} The rendered ButtonBase component.
 * 
 * @example
 * // Example usage:
 * <ButtonBase classes="custom-button" onClick={handleClick} padding="10px" hoverBorderColor="blue" hoverBorder="2px solid red">
 *     Custom Button
 * </ButtonBase>
 */
export function ButtonBase({
    classes,
    style,
    onClick,
    disabled,
    children,
    padding,
    hoverBorderColor,
    hoverBorder,
    hoverColor,
    color,
    label,
    refCallback,
    onKeyDown
}: IButtonBase & {
    children: ReactNode | ReactNode[];
}) {
    const buttonRef = useRef<HTMLDivElement>(null);
    const buttonClasses = `button ${classes || ''}`;

    useEffect(() => {
        refCallback && refCallback(buttonRef) 
    }, [refCallback]);

    const cssVariables: CSSProperties = {
        ...(padding && { '--button-padding': padding }),
        ...(hoverBorderColor && { '--hover-button-border-color': hoverBorderColor }),
        ...(hoverBorder && { '--hover-button-border': hoverBorder }),
        ...(hoverColor && { '--hover-button-color': hoverColor }),
        ...(color && { color }),
    };

    const button = 
        <div
            className={buttonClasses}
            onKeyDown={ onKeyDown }
            style={{ ...style, ...cssVariables }}
            onClick={(event) => !disabled && onClick && onClick(event)}
            onKeyUp={(event) => !disabled && event.key === "Enter" && onClick && onClick(event)}
            aria-disabled={disabled}
            tabIndex={disabled && -1 || 0}
            ref={ buttonRef }
        >
            { children && <span className="buttonContent">{children}</span> }
        </div>;

    return label && <TooltipHost content={label}>{ button }</TooltipHost> || button;
}