import { useContext, useMemo, useState } from 'react';
import { AuthStates } from './LoginTypes';
import { useStore } from 'effector-react';
import { LoginContext } from './LoginContext';
import { Store } from 'effector';
import { ActiveStore, createActiveStore } from '../../state/ActiveStore';
import { t } from "../../locale";
import { doVerification, isValidVerificationCode, doEmailAuthorizationAndLogin, doUserCall } from './LoginUtils';

interface IVerificationFormData {
    verificationCode: string;
}

/**
 * Renders the verification step in the authentication flow.
 * 
 * This component displays a form for the user to enter a verification code sent to their email.
 * It handles the submission of the verification code and subsequent steps based on the verification result.
 * 
 * The component also provides an option to go back to the sign-up stage if the user needs to change their email.
 */
export function VerificationRequired() {
    const context = useContext(LoginContext)!;
    const method = useStore(context.authState.store);

    const formData = useMemo<ActiveStore<IVerificationFormData>>(() => {
        return createActiveStore<IVerificationFormData>({
            verificationCode: '',
        });
    }, []);

    function onComplete(success: boolean, msg: string) {
        success && 
        doUserCall()
        .then(result => {
            result === 'OK' && context.isAuthorized.set(true);
            result === 'NEW' && context.authState.set(AuthStates.AccountVerified);
            !result && console.log('Failed to get user profile.');
        });
        !success && console.log(msg);
    }

    return (<>
        <div className="signInLabel font20i">{t('%Verification required%')}</div>

        <div className="vMargin15"></div>

        <div className='line16'>
            {t('%Check your inbox for a 6-digit verification code sent to:%')}
            <div className='greyText'>{context.emailProvided}</div>
        </div>

        <div className="vMargin15"></div>

        <input
            type="text"
            className="editField grayBackground"
            placeholder={t('%Verification code%')}
            style={{ textAlign: 'center', fontWeight: '600' }}
            onChange={e => formData.set({ ...formData.get(), verificationCode: e.target.value })}
        />

        <div className="vMargin15"></div>

        <VerifyButton formData={formData.store} onComplete={onComplete} />

        <div className="vMargin15"></div>

        <div className="newTo">
            {t('%Wrong email?%')}&nbsp;&nbsp;&nbsp;
            <span
                className="link"
                onClick={() => context.authState.set(AuthStates.SignUp)}
            >{t('%Back%')}</span>
        </div>
    </>);
}

/**
 * Component for the verification submit button.
 * 
 * Validates the entered verification code and triggers the verification process.
 * Calls the `onComplete` callback with the result of the verification process.
 * 
 * @param formData - Store containing the verification form data.
 * @param onComplete - Callback function executed after the verification process to handle the result.
 */
function VerifyButton({ formData, onComplete }: { formData: Store<IVerificationFormData>, onComplete: (success: boolean, msg: string) => void }) {
    const _formData = useStore(formData);
    const context = useContext(LoginContext)!;


    function onSubmitClick() {
        isValidVerificationCode(_formData.verificationCode) &&
            doVerification(context.emailProvided, _formData.verificationCode)
                .then(result => {
                    result && doEmailAuthorizationAndLogin(context.emailProvided, context.passwordProvided)
                    .then(result => result.length === 2 && onComplete(result[0], result[1]));
                    !result && onComplete(false, 'Failed to verify email address.');
                })
    }

    return (
        <div
            className={`signButton ${isValidVerificationCode(_formData.verificationCode) && 'enabled' || ''}`}
            onClick={onSubmitClick}
        >{t('%Send%')}</div>
    );
}