import React, { ReactNode, CSSProperties } from 'react';
import './Panels.css';
import './ControlsPanel.css';
import '../../bcUsers.css';

/**
 * `IFlatControlsPanel` interface - Represents the properties for the IFlatControlsPanel component.
 */
interface IFlatControlsPanel {
  /**
   * The child elements of the panel.
   */
  children: ReactNode;

  /**
   * The orientation of the panel, default is 'horizontal'.
   */
  orientation?: 'horizontal' | 'vertical';

  /**
   * The alignment of child elements within the panel, default is 'start'.
   */
  alignment?: 'start' | 'center' | 'end';

  /**
   * Additional CSS styles to apply to the panel.
   */
  style?: CSSProperties;

  classes?: string;
}

/**
 * `IControlsPanel` interface - Represents the properties for the IControlsPanel component.
 */
interface IControlsPanel extends IFlatControlsPanel {
  /**
   * Determines whether to add edges (border, padding, and margin) to the panel, default is true.
   */
  edges?: boolean;
}

/**
 * `ControlsPanel` Component - A panel for arranging control elements centered in either a horizontal or vertical line.
 * 
 * @component
 * @param {ReactNode} children - The child elements of the panel.
 * @param {('horizontal' | 'vertical')} [orientation='horizontal'] - The orientation of the panel, default is 'horizontal'.
 * @param {('start' | 'center' | 'end')} [alignment='start'] - The alignment of child elements within the panel, default is 'start'.
 * @param {boolean} [edges=true] - Determines whether to add edges (border, padding, and margin) to the panel, default is true.
 * @param {CSSProperties} [style] - Additional CSS styles to apply to the panel.
 * 
 * @returns {JSX.Element} The rendered ControlsPanel component.
 * 
 * @example
 * Example usage: Arranges content with vertical rendering, centered horizontally, and aligned to the top side of the panel vertically ("start").
 * <ControlsPanel orientation="vertical" alignment="start" edges={false}>
 *   <Elements />
 * </ControlsPanel>
 * @example
 * Example usage: Arranges content with horizontal rendering, centered horizontally, and aligned to the right side of the panel horizontally ("end").
 * <ControlsPanel orientation="horizontal" alignment="end">
 *   <Elements /> 
 * </ControlsPanel>
 */
export function ControlsPanel({ children, orientation = 'horizontal', alignment = 'start', edges = true, style, classes }: IControlsPanel) {
  return (
    <div className={`controls panel ${orientation} ${alignment} ${edges ? 'withEdges' : ''} ${classes || ''}`} style={style}>
      { children }
    </div>
  );
}

/**
 * FlatControlsPanel Component - A panel for arranging control elements centered in either a horizontal or vertical line without borders and margins.
 *
 * @component
 * @param {Object} props - The props for the FlatControlsPanel component.
 * @param {ReactNode} props.children - The child elements to be rendered inside the FlatControlsPanel.
 * @param {('horizontal' | 'vertical')} [props.orientation='horizontal'] - The orientation of the FlatControlsPanel, default is 'horizontal'.
 * @param {('start' | 'center' | 'end')} [props.alignment='start'] - The alignment of child elements within the FlatControlsPanel, default is 'start'.
 * @param {CSSProperties} [props.style] - Additional CSS styles to apply to the FlatControlsPanel.
 *
 * @returns {JSX.Element} The rendered FlatControlsPanel component.
 *
 * @example
 * // Example usage:
 * <FlatControlsPanel orientation="vertical" alignment="center">
 *   <Elements />
 * </FlatControlsPanel>
 */
export function FlatControlsPanel({ children, orientation = 'horizontal', alignment = 'start', style, classes }: IFlatControlsPanel) {
  return (
    <ControlsPanel orientation={orientation} alignment={alignment} classes={classes} edges={false} style={style}>
      {children}
    </ControlsPanel>
  );
}
