import { useStore } from 'effector-react';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { logOut, upgradeProfile } from '../../../components/AppComponents/aaa';
import { ModalClasses, ModalNames, currentUser } from '../../../components/AppComponents/variables';
import { IDropdownItem } from '../../../components/BCComponents/Controls/DropDown/DropDown';
import { IListViewItem } from '../../../components/BCComponents/Controls/ListView/ListView';
import { Tabber } from '../../../components/BCComponents/Controls/Tabber/Tabber';
import { FlatRightPanel } from '../../../components/BCComponents/Layouts/Panels/RightPanel';
import { FlatWidePanel } from '../../../components/BCComponents/Layouts/Panels/StretchedPanels';
import { navigate, navigateCommand } from '../../../components/BCComponents/Navigation';
import { retrieveAndSetLangResource, t } from "../../../locale";
import * as Auth from "../../../utils/auth";
import { getNameInitials } from '../../../utils/utils';

/**
 * `UserResume` Component
 * 
 * Renders a user resume interface, including user details and organizational information.
 * It uses the `currentUser` store from Effector to fetch and display user information.
 * 
 * Features:
 * - Displays user's name, surname initials, and current organization.
 * - Shows additional user details and organizational options in a dropdown when the userResume area is clicked.
 * - Implements a toggle functionality to show or hide detailed user resume content.
 * - Includes an outside click handler to automatically close the dropdown if clicked outside of the component.
 * - Allows selection of an organization from a list, which updates the user's current organization in the `currentUser` store.
 */
export function UserResume() {
    const userInfo = useStore(currentUser.store);
    const [isUserResumeContentVisible, setIsUserResumeContentVisible] = useState<boolean>(false);
    const userResumeRef = useRef<HTMLDivElement>(null);
    const userResumeContentRef = useRef<HTMLDivElement>(null);

    const toggleUserResumeContent = (): void => {
        setIsUserResumeContentVisible(prev => !prev);
    };

    const handleClickOutside = (event: MouseEvent): void => {
        if (userResumeContentRef.current && !userResumeContentRef.current.contains(event.target as Node) && 
            userResumeRef.current && !userResumeRef.current.contains(event.target as Node)) {
            setIsUserResumeContentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (): void => { document.removeEventListener('mousedown', handleClickOutside) };
    }, []);

    const onSelectOrganization = (item: IListViewItem | IDropdownItem) => {
        upgradeProfile(item.id, (orgUid) => { navigate(orgUid) });
        setIsUserResumeContentVisible(false);
    };

    function onKeyDown(event: KeyboardEvent<HTMLElement>) {
        event.key === 'Escape' && setIsUserResumeContentVisible(false);
    }

    return (<>
        <div className='userResume' ref={userResumeRef} onClick={toggleUserResumeContent} onKeyDown={(event) => { onKeyDown(event); event.key === 'Tab' && event.shiftKey && setIsUserResumeContentVisible(false); }}>
            <Tabber />
            <div className='userAvatar'>{ getNameInitials(userInfo?.firstName, userInfo?.lastName) }</div>
            <div className='userDetails'>
                <div className='userName'>{ userInfo?.firstName || ''} {userInfo?.lastName || '' }</div>
                <div className='organization'>{ userInfo && userInfo.currentOrganizationID && userInfo.organizations.find(org => org.id === userInfo.currentOrganizationID)?.name || '' }</div>
            </div>
        </div>

        {isUserResumeContentVisible && (
            <div className='userResumeContent' ref={userResumeContentRef} onKeyDown={onKeyDown}>
                <div className='userName'>{ userInfo?.firstName || ''} {userInfo?.lastName || '' }</div>
                <div className='email'>{ userInfo?.email || ''}</div>
                
                <div className='hdelimiter'></div>
                
                <FlatWidePanel classes='blockLabel' vertical='center'>
                    {t('%My Profile%')}
                    <Flags />
                </FlatWidePanel>
                {/* <div 
                    className='settings menuItem'
                    onClick={() => { 
                        navigateCommand(ModalClasses.settings, { name: ModalNames.accountSettings, method: 'add' });
                        setIsUserResumeContentVisible(false);
                    }}                
                ><Tabber />{t('%My settings%')}</div>
                <div className='refreshPermissions menuItem'><Tabber />{t('%Refresh permissions%')}</div> */}
                
                {/* <div className='hdelimiter'></div>
                
                <div className='blockLabel'>{t('%Organization profile%')}</div> */}
                
                {/* <ListView 
                    items={ userInfo?.organizations.map(organization => ({
                        id: organization.id,
                        label: organization.name
                    }))}
                    onSelect={onSelectOrganization} 
                    defaultValues={[userInfo?.currentOrganizationID]}
                    style={{ width: 'var(--organization-select-width)', margin: '0.5em 0' }}
                    addMore={{ label: t('%Add new organization%'), 
                               onClick: () => { setIsUserResumeContentVisible(false); navigateCommand('dataset', { name: ModalNames.organizations, method: 'add' }); }
                            }}
                    classes='thinScrollbar'
                /> */}

                {/* <div 
                    className='settings menuItem'
                    onClick={() => { 
                        navigateCommand(ModalClasses.settings, { name: ModalNames.organizationSettings, method: 'add' });
                        setIsUserResumeContentVisible(false);
                    }}
                ><Tabber />{t('%Organization settings%')}</div> */}
                
                <div className='hdelimiter'></div>

                <div
                    className='logOut menuItem'
                    onClick={() => logOut()}
                    onKeyDown={(event) => event.key === 'Tab' && !event.shiftKey && setIsUserResumeContentVisible(false) }
                >
                    <Tabber />
                    {t('%Sign out%')}
                </div>
            </div>
        )}
    </>);
}

function Flag({lang} : {lang: string}) {
    return ( 
        <div 
            className={`inCircle ${Auth.getLocale() === lang && 'selected' || ''}`} 
            onClick={() => retrieveAndSetLangResource(lang).then(newLang => currentUser.set({...currentUser.get()!}) )}
        >
            <div className={`flagSVG ${lang}`}></div>
        </div>);
}

function Flags() {
    return <FlatRightPanel>
        <Flag lang='en' />
        <Flag lang='de' />
    </FlatRightPanel>
}

// InitSettingsModals();
// InitOrgSettingsModals();