import React, { useState, useEffect, CSSProperties } from 'react';
import './Checkbox.css';
import '../../bcUsers.css';


/**
 * Checkbox component properties.
 * 
 * @interface CheckboxProps
 * @property {string} [label] - Optional label text for the checkbox.
 * @property {boolean} [defaultValue] - Optional default value of the checkbox.
 * @property {(checked: boolean) => void} [onClick] - Optional function to handle checkbox clicks.
 * @property {CSSProperties} [style] - Optional CSS properties for the checkbox container.
 * @property {CSSProperties} [markStyle] - Optional CSS properties for the checkbox mark.
 * @property {CSSProperties} [labelStyle] - Optional CSS properties for the checkbox label.
 */
export interface CheckboxProps {
    /**
     * Optional label text for the checkbox.
     */
    label?: string;

    /**
     * Optional default value of the checkbox.
     */
    defaultValue?: boolean;

    /**
     * Optional function to handle checkbox clicks.
     */
    onClick?: (checked: boolean) => void;

    /**
     * Optional CSS properties for the checkbox container.
     */
    style?: CSSProperties;

    /**
     * Optional CSS properties for the checkbox mark.
     */
    markStyle?: CSSProperties;

    /**
     * Optional CSS properties for the checkbox label.
     */
    labelStyle?: CSSProperties;

    kind?: 'checkbox' | 'slider';

    readOnly?: boolean,

    disabled?: boolean;
}

/**
 * Checkbox component.
 * 
 * @component
 * @param {CheckboxProps} props - The component's properties.
 * @returns {JSX.Element} The rendered Checkbox component.
 */
export function Checkbox({ label, defaultValue, onClick, style, markStyle, labelStyle, kind = 'slider', disabled, readOnly }: CheckboxProps) {
    const [checked, setChecked] = useState(defaultValue || false);

    useEffect(() => {
        setChecked(defaultValue || false);
    }, [defaultValue]);

    const toggleCheckbox = () => {
        if (disabled || readOnly) return;
        const newChecked = !checked;
        setChecked(newChecked);
        if (onClick) {
            onClick(newChecked);
        }
    };

    return (
        <div className="checkbox" aria-disabled={disabled} onClick={toggleCheckbox} style={style}>
            { kind === 'checkbox' && <div className={`mark${checked ? " checked" : ""}`} style={markStyle}></div> }
            { kind === 'slider' && <div className={`slider${checked ? " checked" : ""}`} style={markStyle}></div> }
            <label style={labelStyle} tabIndex={0} onKeyUp={ e => (e.key === ' ' || e.key === 'Enter') && toggleCheckbox() }>{label || ""}</label>
        </div>
    );
}
