import { useMemo } from "react";
import { Login } from "../../containers/Login/Login";
import { ILoginContext, LoginContext } from "../../containers/Login/LoginContext";
import { AuthStates } from "../../containers/Login/LoginTypes";
import { ActiveStore, createActiveStore } from "../../state/ActiveStore";
import * as Auth from "../../utils/auth";

export function SignIn({ isAuthorized }: { isAuthorized: ActiveStore<boolean> }) {    
    const loginContext = useMemo<ILoginContext>(() => {    
        return { 
          isAuthorized:   isAuthorized,
          authState:      createActiveStore<AuthStates>(AuthStates.SignIn),
          language:       createActiveStore<string>(Auth.getLocale() || 'en'),
          emailProvided:  '',
          passwordProvided:  '',
          firstNameProvided:  '',
          lastNameProvided:  '',
        };
      }, []);

    return (
        <LoginContext.Provider value={ loginContext }>
            <Login />
        </LoginContext.Provider>
    );
}
