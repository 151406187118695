import apiClient from "../../../utils/apiclient";
import { Icons } from "../../BCComponents/Controls/SVGIcons/icons";
import { paramsToQuery } from "../funcs";
import { IGridMetaData } from "../types";

const defaults = {
    pageSize: 25,
    sortBy: ''
}    

const iconMapList: { [key: string]: string } = {
    'plus-box': 'add',
    'ChatInviteFriend': 'invite',
}

export function deepCopy<T = any>(obj: T, excludeFromIteration: string[] = ['children', 'parent'], depth: number = 5): T {
    if (depth === 0 || !obj || typeof obj !== 'object' || obj === null) return obj as T;

    if (Array.isArray(obj)) return obj.map(item => deepCopy(item, excludeFromIteration, depth - 1)) as unknown as T;

    const copy: Record<string, any> = {};
    Object.entries(obj as Record<string, any>).forEach(([key, value]) => {
        excludeFromIteration.includes(key) ? copy[key] = value : 
            typeof value === 'object' && value !== null ? copy[key] = deepCopy(value, excludeFromIteration, depth - 1) : 
                copy[key] = value;
    });
    return copy as T;
}

export function iconMap(icon: string): Icons {
    return (iconMapList[icon] || icon) as Icons; 
}

export async function requireGrid(url: string, params?: Record<string, any>, pageNumber?: number, pageSize?: number, sortBy?: string) {
    const paramsStr = paramsToQuery(params);
    return await apiClient.get<IGridMetaData>(`${url}?${paramsStr ? paramsStr + '&': ''}pageNumber=${pageNumber || 0}&pageSize=${pageSize || defaults.pageSize}&sortBy=${sortBy || defaults.sortBy}`);
}

export function applyHrefParams(ref: string, rowData: Record<string, string>) {
    return ref.replace(/\{([^}]+)\}/g, (_, param) => rowData[param] || '');
}  