import * as Auth from "../../../src/utils/auth";
import apiClient from "../../utils/apiclient";
import { IOrganization } from "./types";
import { currentUser } from "./variables";

export async function upgradeProfile(organizationId: number, success?: (orgUid: string) => void, fail?: () => void) {
    const val = organizationId.toString();
    localStorage.setItem('orgcur', val);
    localStorage.setItem('orgsv', val);
    localStorage.setItem('orgid', val);

    const res = await apiClient.post("/aaa/api/v1/auth/tokens/upgrade", { organizationId: organizationId });
    if (res.status === 200) {
        Auth.setAccessToken(res.data.accessToken);
        Auth.setRefreshToken(res.data.refreshToken);
        const user = Auth.getUserProfile();
        const orgUid = user && user.organizations.find((org: IOrganization) => org.id === organizationId)?.uid;
        Auth.setOrgUid(orgUid || null);
        user && currentUser.set({ ...user, currentOrganizationID: organizationId });
        success && orgUid && success(orgUid); 
    } else fail && fail();
}

/**
 * Logs out to the landing page.
 */
export async function logOut(cleanupCache: boolean = true) {
    try {
        const res = await apiClient.post("/aaa/api/v1/auth/log-out", { refreshToken: Auth.getRefreshToken() });
        cleanupCache && Auth.cleanupAll();
        if (Auth.getIsFlexible() !== null) {
            Auth.delOrgKeys();
            Auth.delTokens();
        }
        window.location.href = window.location.origin;
    } catch (err) { console.log(err) }
};