import apiClient from "./apiclient";
import * as Auth from "./auth";
import { hash } from "../utils/funcs";
import { v4 as uuidv4, parse as uuidParse } from "uuid";

export async function callLoginEmail(email, password, mode) {

    const url = "/aaa/api/v1/auth";
    const headers = new Headers({
        'X-Bc-Request-Id': uuidv4()
    });
    const body = JSON.stringify({
        "email": email,
        "password": mode === true ? password : hash(password),
    });

    const options = {
        method: "POST",
        headers: headers,
        body: body
    };

    return fetch(url, options)
        .then(response => response.json())
};

export async function callSignUp(email, password) {

    const url = "/aaa/api/v1/sign-up";
    const headers = new Headers({
        'X-Bc-Request-Id': uuidv4()
    });
    const body = JSON.stringify({
        "email": email,
        "hashedPassword": hash(password),
    });

    const options = {
        method: "POST",
        headers: headers,
        body: body
    };

    return fetch(url, options)
        .then(response => response)
};

export async function callSignUpVerify(email, code) {

    const url = "/aaa/api/v1/sign-up/verify";
    const headers = new Headers({
        'X-Bc-Request-Id': uuidv4()
    });
    const body = JSON.stringify({
        "email": email,
        "code": code,
    });

    const options = {
        method: "POST",
        headers: headers,
        body: body
    };

    return fetch(url, options)
        .then(response => response)
};

export async function callLoginOrganization(organizationId) {

    try {
        const res = await apiClient.post("/aaa/api/v1/auth/tokens/upgrade", { organizationId: organizationId });
        //console.info("res(SO): ", res);
        if (res.status === 200) {
            Auth.setAccessToken(res.data.accessToken);
            Auth.setRefreshToken(res.data.refreshToken);
            if (res.data.allowedPermissions) {
                Auth.setAllowedPermissions(res.data.allowedPermissions);
            }
            return "OK";;
        } else {
            return "FAIL";
        }
    } catch (error) {
        console.log(error);
        return "FAIL";
    }
}

export async function callUser() {
    try {
        const res = await apiClient.get("/pfm/api/v1/user");
        if (res.status === 200) {
            if (res.data) {
                const shname = res.data.firstName.substring(0, 1).toUpperCase() + res.data.lastName.substring(0, 1).toUpperCase();
                Auth.setUserShortName(shname);
                Auth.setUserProfile(res.data);
            }
            return "OK";
        } else if (res.status === 204) {
            return "NEW";
        } else {
            return "FAIL";
        }
    } catch (error) {
        console.log(error);
        return "FAIL";
    }
}

export async function logOutFlex(rt) {
    const res = await apiClient.post("/aaa/api/v1/auth/log-out", {
        refreshToken: rt,
    });
    console.info("res(LOF): ", res);
    if (res.status === 200) {
        return "OK";
    } else {
        return "FAIL";
    }
}

export const getAllowedPerms = async () => {
    const url = "/aaa/api/v1/auth/tokens/refresh";
    const headers = new Headers({
        'X-Bc-Request-Id': uuidv4()
    });
    const body = JSON.stringify({
        "refreshToken": Auth.getRefreshToken(),
    });

    const options = {
        method: "POST",
        headers: headers,
        body: body
    };

    return fetch(url, options)
        .then(response => response.json())
}

export async function callOAuthMicrosoft() {
    const url = "/aaa/api/v1/oauth2/microsoft";
    const headers = new Headers({
        'X-Bc-Request-Id': uuidv4()
    });

    const options = {
        method: "GET",
        headers: headers,
    };

    return fetch(url, options).then(response => response.json())
}

export async function callOAuthExchange(code) {
    const url = "/aaa/api/v1/oauth2/exchange";
    const headers = new Headers({
        'X-Bc-Request-Id': uuidv4()
    });
    const body = JSON.stringify({
        "code": code,
    });

    const options = {
        method: "POST",
        headers: headers,
        body: body
    };

    return fetch(url, options).then(response => response.json())
};

export async function callAskResetPassword(email) {

    const url = "/aaa/api/v1/auth/reset-password/start";
    const headers = new Headers({
        'X-Bc-Request-Id': uuidv4()
    });
    const body = JSON.stringify({
        "email": email,
    });

    const options = {
        method: "POST",
        headers: headers,
        body: body
    };

    return fetch(url, options)
        .then(response => response)
};

export async function callVerifyResetCode(code) {

    const url = "/aaa/api/v1/auth/reset-password/verify-code";
    const headers = new Headers({
        'X-Bc-Request-Id': uuidv4()
    });
    const body = JSON.stringify({
        "resetCode": code,
    });

    const options = {
        method: "POST",
        headers: headers,
        body: body
    };

    return fetch(url, options)
        .then(response => response)
};

export async function callResetPassword(code, password) {

    const url = "/aaa/api/v1/auth/reset-password/complete";
    const headers = new Headers({
        'X-Bc-Request-Id': uuidv4()
    });
    const body = JSON.stringify({
        "newHashedPassword": hash(password),
        "resetCode": code,
    });

    const options = {
        method: "POST",
        headers: headers,
        body: body
    };

    return fetch(url, options)
        .then(response => response)
};

