import { useStore } from "effector-react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { upgradeProfile } from "../../components/AppComponents/aaa";
import { getUrlId } from "../../components/AppComponents/funcs";
import { RegisterModal } from "../../components/AppComponents/Modals/Modal";
import { DataSourceContext, LabelField, ModalOperationContext, StringField, UserField } from "../../components/AppComponents/Modals/ModalFields";
import { ModalVOrganizer, ModalVRow } from "../../components/AppComponents/Modals/ModalUtils";
import { IModalOptions, IOrganization, IURLAction, ModalOnCreateEdit } from "../../components/AppComponents/types";
import { currentUser, ModalClasses, ModalNames } from "../../components/AppComponents/variables";
import { Button, ButtonWithCancel } from "../../components/BCComponents/Controls/Buttons/Buttons";
import { IconButton } from "../../components/BCComponents/Controls/Buttons/IconButton/IconButton";
import { Label } from "../../components/BCComponents/Controls/Label/Label";
import { HeaderButtonsInContainer } from "../../components/BCComponents/Layouts/Layouts";
import { FlatHPanel, FlatVPanel } from "../../components/BCComponents/Layouts/Panels/ArrangementPanels";
import { FlatWidePanel } from "../../components/BCComponents/Layouts/Panels/StretchedPanels";
import { getURLCommand, navigateCommand, useNavigation } from "../../components/BCComponents/Navigation";
import { t } from "../../locale";
import { createActiveStore } from "../../state/ActiveStore";
import { simpleSyncObject } from "../../state/SyncStore";
import { reloadUserProfile } from "../../utils/DataSets/DataSets";
import { isValidDomain } from "../../utils/utils";


function CreateOrganization() {
    return(<>
        <StringField label='Display name' name="name" required={true} />
        <UserField><Label icon="info">{t('%You can add more organization details later%')}</Label></UserField>
    </>);
} 

function afterChange(fieldname: string, data?: IOrganization): boolean {
    (data && fieldname === 'name') && (data.shortName = data.name);
    return (data && fieldname === 'name') || false;
}

const onCreate: ModalOnCreateEdit<IOrganization> = (data: IOrganization) => {
    reloadUserProfile().then(result => upgradeProfile(data.id));
    return true;
}

const simpleCreateOptions: IModalOptions<IOrganization> = {
    appearance: "popup",
    createModal: CreateOrganization,
    afterChange: afterChange,
    labels: { submit: {add: 'Create'} },
    controlsToHideOnCreate: ['wide', 'side', 'popup'],
    onCreate: onCreate,
    title: { caption : { add: 'Create organization' } },
}

// #######################################################################################

export const profileEditAction = 'organization-profile-edit';

function ProfileEditButton() {
    useNavigation();
    const actionBody: IURLAction = { method: "edit", name: ModalNames.profile, params: { ID: currentUser.get()?.currentOrganizationID } }
    return (getURLCommand().action !== profileEditAction && 
            <HeaderButtonsInContainer buttons={[{
                label: t('%Edit%'), 
                icon: 'edit', 
                classes: 'editButton', 
                onClick: () => navigateCommand(ModalClasses.dataSet, actionBody, true) }]}
            /> || null);
}

const orgSync = simpleSyncObject<IOrganization>('/pfm/api/v1/organizations/${ID}', 'orgProfile');
const orgUpdated = createActiveStore({});

export function ViewOrganizationProfile() {
    const updated = useStore(orgUpdated.store);
    const [org, setOrg] = useState<IOrganization | null>(null);
    const neds = <span style={{ color: '#999' }}>{t('%No email domains specified%')}.</span>;
    const naaa = <span style={{ color: '#999' }}>{t('%No additional admins added%')}.</span>;

    const anweacaj = <div className="inlineHint tiny">{t('%Anyone with email addresses at these domains can automatically join your organization%')}.</div>

    // const user = currentUser.get();
    const orgID = getUrlId()

    useEffect(() => {
        orgID && orgSync.setParams({ ID: orgID }).load().then(res => setOrg(res));
    }, [orgID, updated])
    
    return <DataSourceContext.Provider value={org || {}}>
            <FlatWidePanel style={{ backgroundColor: 'white', padding: '1em', borderRadius: '0.5em', marginBottom: '0.5em' }}>
                <FlatHPanel>
                    <ModalVOrganizer classes="organization-profile">
                        <ModalVRow colSpan={true}>
                            <FlatVPanel width="100%">
                                <FlatWidePanel>
                                    <div className="orgHeader">{org?.['name'] || ''}</div>                                
                                </FlatWidePanel>    
                                <FlatHPanel><span style={{  color: '#999' }}>
                                    {`${t('%Organization ID%')}:`}</span>
                                    <FlatHPanel style={{ color: '#292929', marginLeft: '0.5em' }}>{org?.uid || ''}<IconButton icon="clipboard" color="#999" size="1.5em" style={{ marginLeft: '0.25em' }} onClick={() => navigator.clipboard.writeText(org?.uid || '') } /> </FlatHPanel>
                                </FlatHPanel>
                            </FlatVPanel>
                        </ModalVRow>

                        <ModalVRow label='Allowed email domains'>
                            { org?.domains && org.domains.length > 0 && <FlatHPanel classes="allowedDomainsSet">
                                { org.domains.map((domain: {name: string}, index: number) => <Button classes="font08 rounded functional gray" key={index}>{domain.name || ''}</Button>) }
                            </FlatHPanel> || neds }
                            { anweacaj }                       
                        </ModalVRow>

                        <ModalVRow label='Owner'>
                            { org?.owner && <LabelField name="owner" /> }
                        </ModalVRow>
                        <ModalVRow label='Additional admins'>
                            { org?.administrators && <LabelField name="administrators"/> || naaa }
                        </ModalVRow>
                    </ModalVOrganizer>
                </FlatHPanel>
                {/* <ProfileEditButton /> */}
            </FlatWidePanel>
        </DataSourceContext.Provider>    
}

export function EditOrganizationProfile() {
    const _org = useStore(orgSync.store);
    const emailRef = useRef<HTMLInputElement>(null);
    const naaa = <span style={{ color: '#999' }}>{t('%No additional admins added%')}.</span>;
    const operation = useContext(ModalOperationContext);
    const org = useMemo(() => ({ ..._org }), [_org]);
    const [domains, setDomains] = useState<{id?: number, name: string}[]>(org.domains || []);
    const orgID = getUrlId();

    const anweacaj = <div className="inlineHint tiny">{t('%Anyone with email addresses at these domains can automatically join your organization%')}.</div>

    org.domains = domains;

    operation.onEdit = (data: IOrganization) => {
        if (!org || !orgID) return false;
        orgSync
        .setParams({ ID: orgID })
        .updatePut({ ...org, domains: org.domains?.map(domain => domain.name).join(';') || '' })
        .then(() => orgUpdated.set({}));
        return true;
    };

    useEffect(() => {
        orgID && orgSync.setParams({ ID: orgID }).load();
    }, [orgID])
    
    return <DataSourceContext.Provider value={org || {}}>            
            <ModalVOrganizer classes="organization-profile">
                <ModalVRow label='Display name' required={true}>
                    <StringField name="name" required={true} />
                </ModalVRow>

                <ModalVRow label='Allowed email domains'>
                    <FlatHPanel verticalAlign="center">
                        <input type="text" className="editField hRMargin10" placeholder='domain.net' ref={emailRef} />
                        <Button classes="functional rounded submit" onClick={() => {
                            if (!isValidDomain(emailRef.current!.value) || domains.find(domain => emailRef.current!.value.trim() === domain.name)) return; 
                            domains.push({name: emailRef.current!.value.trim()}); 
                            setDomains([...domains]);
                        }}>{t('%Add%')}</Button>
                    </FlatHPanel>
                    { anweacaj }
                    { domains.length > 0 && <FlatVPanel style={{ marginTop: '1em' }}>
                        <div className="alloweddomains">{t('%Allowed%')}</div>
                        <FlatHPanel classes="allowedDomainsSet">
                            { domains.map((domain, index) => <ButtonWithCancel classes="font08 rounded functional gray" key={index} onCancelClick={() => {
                                if (!domains.includes(domain)) return;
                                domains.splice(domains.indexOf(domain), 1);
                                setDomains([...domains]);
                            } }>{domain.name}</ButtonWithCancel>) }
                        </FlatHPanel>
                    </FlatVPanel> }
                </ModalVRow>

                <ModalVRow label='Owner'>
                    { org.owner && <LabelField name="owner" /> }
                </ModalVRow>

                <ModalVRow label='Additional admins'>
                    { org.administrators && <LabelField name="administrators"/> || naaa }
                </ModalVRow>
            </ModalVOrganizer>
        </DataSourceContext.Provider>    
} 

function beforeSubmit(fieldname: string, data?: IOrganization): boolean {
    return data && fieldname === "--edit" && data.domains && 
           (data.domains = (data.domains.map(domain => domain.name).join(';') as any)) && true || false;
}

const profileOptions: IModalOptions<IOrganization> = {
    appearance: "popup",
    afterChange: beforeSubmit,
    createModal: EditOrganizationProfile,
    editModal:   EditOrganizationProfile,
    controlsToHideOnEdit: ['popup', 'side', 'wide'],
    labels: {submit: {edit: 'Save changes'} },
    title:  { caption: { edit: 'Edit organization profile' } },
    local: true,
}

export const InitOrganizationsModals = () => {
    RegisterModal(ModalClasses.dataSet, ModalNames.organizations, simpleCreateOptions);
    RegisterModal(ModalClasses.dataSet, ModalNames.profile, profileOptions);
}

