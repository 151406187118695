import { useContext, useMemo, useRef, useState } from 'react';
import { Checkbox } from '../../components/BCComponents/Controls/Checkbox/Checkbox';
import { AuthStates } from './LoginTypes';
import { useStore } from 'effector-react';
import { LoginContext } from './LoginContext';
import { ActiveStore, createActiveStore } from '../../state/ActiveStore';
import { Store } from 'effector';
import { isValidEmail } from '../../utils/utils';
import { imgs } from '../../components/AppComponents/variables';
import { t } from "../../locale";
import { doEmailAuthorizationAndLogin, doMicrosoftIDAuthorization,
        doUserCall, doAcceptInvitationCall, doJoinOrganization } from './LoginUtils';
import * as Auth from "../../utils/auth";

interface ISignInFormData {
    email: string;
    password: string;
}

/**
 * Renders the Sign In component for email-based authentication.
 * 
 * Allows users to sign in using their email and password. It also includes options to 
 * sign in using Microsoft ID, sign up for a new account, or reset the password.
 * 
 * The component handles validation of the email address and shows an error message if 
 * the credentials are incorrect or if there is an issue during the sign-in process.
 */
export function SignInByEmail() {
    const context = useContext(LoginContext)!;
    const [credentialsError, setCredentialsError] = useState(false);

    const passwordRef = useRef<HTMLInputElement>(null);

    const formData = useMemo<ActiveStore<ISignInFormData>>(() => {
        return createActiveStore<ISignInFormData>({ email: '', password: '' });
    }, []);

    function onComplete(success: boolean, msg: string) {
        if (success) {
            doUserCall()
            // .then(result => {
            //     result === 'OK' && context.isAuthorized.set(true);
            //     result === 'NEW' && context.authState.set(AuthStates.AccountVerified);
            //     !result && console.log('Failed to get user profile.');
            // });
            .then(result => {
                if (result === 'OK') {
                    const ic = Auth.getInvitationCode() || '';
                    const org = Auth.getInvitationOrg() || '';
                    if (ic && org) {
                        doAcceptInvitationCall(ic)
                        .then(resa => {
                            resa && doJoinOrganization(org)
                            .then(resj => {
                                resj && context.isAuthorized.set(true);
                                !resj && console.log('Failed to join organization.');
                            });
                            !resa && console.log('Failed to accept invitation.');
                        })
                    } else {
                        context.isAuthorized.set(true);
                    }               
                } else if (result === 'NEW') {
                    context.authState.set(AuthStates.AccountVerified);
                };
                !result && console.log('Failed to get user profile.');
            });            
        } else {
            setCredentialsError(!success);
            console.log(msg);
        }
    }

    function onStayConnectedChange(isChecked: boolean) {

    };

    const locale = Auth.getLocale() || 'en';

    return (
        <>
            <div className="signInLabel">{t('%Sign in%')}</div>
            <div style={{ marginTop: '0.5em' }}>Administrator desk</div>

            <div className="vMargin15"></div>

            <input
                autoFocus
                tabIndex={1}
                type="text"
                className="editField grayBackground"
                placeholder={t('%Email%')}
                onChange={e => formData.set({ ...formData.get(), email: e.target.value })}
            />

            <InvalidEmail formData={formData.store} />

            <div className="relative">
                <input
                    tabIndex={2}
                    ref={passwordRef}
                    type="password"
                    className="editField masked grayBackground"
                    placeholder={t('%Password%')}
                    onChange={e => formData.set({ ...formData.get(), password: e.target.value })}
                />
                <img
                    src={`${imgs}/hidden.svg`}
                    className="hiddenEye"
                    title={t('%Show or hide password%')}
                    onClick={() => passwordRef.current!.type = passwordRef.current!.type === 'password' && 'text' || 'password'}
                />
            </div>

            <SignButton formData={formData.store} onComplete={onComplete} onSubmit={() => setCredentialsError(false)}  tbi={3} />


            {credentialsError && <div className="infoMessageBlock font09">
                {t('%Unable to log in with provided credentials. Try again or%')}&nbsp;
                <span><a className='link' href={'https://bimcosmos.com/' + `${locale === 'de' ? 'de/standard-support/' : 'standard-support/'}`} target='_blank'>{t('%contact support%')}</a>
            </span></div>}

            <div className="vMargin15"></div>
        </>
    );
}

/**
 * Component for the sign-in button.
 * 
 * Validates the sign-in form data and initiates the sign-in process.
 * Executes the `onComplete` callback with the result of the sign-in process, 
 * and an optional `onSubmit` callback before the process.
 * 
 * @param formData - Store containing the sign-in form data.
 * @param onSubmit - Optional callback function executed on button click.
 * @param onComplete - Callback function executed after the sign-in process.
 */
function SignButton({ formData, onSubmit, onComplete, tbi }: { formData: Store<ISignInFormData>, onSubmit?: () => void, onComplete: (success: boolean, msg: string) => void, tbi: number }) {
    const context = useContext(LoginContext)!;
    const _formData = useStore(formData);

    function isSignInEnabled(): 'enabled' | '' {
        return isValidEmail(_formData.email) && _formData.password.length >= 6 && 'enabled' || '';
    }

    function onSubmitClick() {
        onSubmit && onSubmit();
        isSignInEnabled() === 'enabled' &&
            doEmailAuthorizationAndLogin(_formData.email, _formData.password)
            .then(result => result.length === 2 && onComplete(result[0], result[1]));
    }

    return (
        <div
            tabIndex={tbi}
            className={`signButton ${isSignInEnabled()}`}
            onClick={onSubmitClick}
            onKeyUp={(e) => e.key === "Enter" && onSubmitClick()}
        >{t('%Sign in%')}</div>
    );
}

/**
 * Component to display a validation message for the email field.
 * 
 * Shows a message if the entered email is not in a valid format.
 * 
 * @param formData - Store containing the sign-in form data.
 */
function InvalidEmail({ formData }: { formData: Store<ISignInFormData> }) {
    const _formData = useStore(formData);
    return (<>
        <div className="smallSizedInfo redText">{_formData.email && !isValidEmail(_formData.email) && t('%Please enter a valid email address%') || <>&nbsp;</>}</div>
    </>)
}
