import { v4 as uuidv4 } from "uuid";
import * as Auth from "./utils/auth";


async function callLangResource() {
    const options = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Bc-Language': Auth.getLocale() || 'en',
            'X-Bc-Request-Id': uuidv4(),
        },
    }

    try {
        return fetch('/pfm/api/v1/language-resources', options).then(response => response.json())
    } catch (error) {
        console.log('Language resources load error', error);
    }
    return
}

export async function retrieveAndSetLangResource(languageCode: string) {
    const options = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Bc-Language': languageCode || 'en',
            'X-Bc-Request-Id': uuidv4(),
        },
    }

    try {
        const response = await fetch('/pfm/api/v1/language-resources', options);
        const json = await response.json(); 
        Auth.setLocaleResources(json);
        Auth.setLocale(languageCode);
        return languageCode;
    } catch (error) {
        console.log('Language resources load error', error);
    }
    return Auth.getLocale() || 'en';
}

export async function getLangResource() {
    callLangResource()
        .then(st => {
            Auth.setLocaleResources(st);
        })
}

export function getLangResourceInit() {
    return callLangResource()
}

export const t = (s: string) => {
    let st = Auth.getLocaleResources();
    if (!st || Object.keys(st).length === 0) {
        return s
    }
    if (s && s in st) {
        const p: string = (st as any)[s].v;
        return p;
    } else {
        return s
    }
};