import { useStore } from 'effector-react';
import { useContext, useEffect, useState } from 'react';
import { imgs } from '../../components/AppComponents/variables';
import { Switcher } from '../../components/BCComponents/Controls/Switcher/Switcher';
import { retrieveAndSetLangResource, t } from "../../locale";
import * as Auth from "../../utils/auth";
import { AccountVerified } from './AccountVerified';
import { ChangePassword, ResetPassword, SendEmail } from './ChangePassword';
import { Invite } from './Invite';
import './Login.css';
import { LoginContext } from './LoginContext';
import { AuthStates } from './LoginTypes';
import {
    doAcceptInvitationCall,
    doJoinOrganization,
    doLoginByOAuth, doVerifyResetPassword
} from './LoginUtils';
import { SignInByEmail } from './SignInByEmail';
import { SignUp } from './SignUp';
import { VerificationRequired } from './VerificationRequired';

/**
 * Main login component of the application.
 * It handles OAuth callbacks, resets password flows, and renders the login UI layout.
 * 
 * - Manages OAuth login flow if the URL includes an OAuth callback with a code.
 * - Clears invitation code if no 'code' parameter is found in the URL.
 * - Switches to reset password flow if URL indicates a password reset.
 * - Controls the visibility of a covering screen during certain auth states.
 */
export function Login() {
    const context = useContext(LoginContext)!;
    useStore(context.language.store);

    const code = new URLSearchParams(window.location.search).get('code');
    const org = new URLSearchParams(window.location.search).get('org');
    if (!code) {
        // Auth.clearInvitationCode();
        // Auth.clearInvitationOrg();
    }
    const isMicrosoftAuthCallbackURL = window.location.href.includes('/oauth2/callback?code=');
    const isResetPasswordURL = window.location.href.includes('/verify-reset?code=');
    const isInvitationURL = window.location.href.includes('/invite?code=');
    const [showCover, setShowCover] = useState(isMicrosoftAuthCallbackURL && code);

    useEffect(() => {
        code && isMicrosoftAuthCallbackURL && doLoginByOAuth(code).then(result => {
            if (!result.success) {
                console.log(result.errorMsg);
                setShowCover(false);
                window.history.replaceState({}, '', '/portal');
                context.authState.set(AuthStates.SignIn);
                return;
            } 
            switch (result.userResponse) {
                case 'OK':
                    const ic = Auth.getInvitationCode() || '';
                    const org = Auth.getInvitationOrg() || '';
                    if (ic && org) {
                        doAcceptInvitationCall(ic)
                        .then(resa => {
                            resa && doJoinOrganization(org)
                            .then(resj => {
                                window.history.replaceState({}, '', '/portal-old');
                                resj && context.isAuthorized.set(true);
                                !resj && console.log('Failed to join organization.');
                            });
                            !resa && console.log('Failed to accept invitation.');
                        })
                    } else {
                        window.history.replaceState({}, '', '/portal-old');
                        context.isAuthorized.set(true);
                    } 

                    break;
                case 'NEW':
                    setShowCover(false);
                    window.history.replaceState({}, '', '/portal');
                    context.emailProvided = result.authResponse.email;
                    context.firstNameProvided = result.authResponse.firstName;
                    context.lastNameProvided = result.authResponse.lastName;
                    context.authState.set(AuthStates.AccountVerified);
                    break;
                default:
                    context.authState.set(AuthStates.SignIn);
                    console.log(result.userResponse);
                    break;
            }
        });
        code && isResetPasswordURL && doVerifyResetPassword(code).then(result => {
            if (result) {
                context.authState.set(AuthStates.ChangePassword);
            } else {
                context.authState.set(AuthStates.SignIn);
            }
        });
        code && org && isInvitationURL && context.authState.set(AuthStates.Invite);
    }, [code]);
    
    return (
        <> 
            { showCover && LoginCover() }
            <div className="loginContainer">
                <div className="circle leftTop"></div>
                <LeftBlock />
                <RightBlock />
            </div>
        </>    
    );
};

/**
 * Renders the left block of the login screen.
 * 
 * Includes:
 * - Company logo and branding information.
 * - A language switcher to toggle between German and English.
 * - A brief description of the platform.
 * 
 * The language switch triggers an update in the application's locale settings.
 */
function LeftBlock() {
    const context = useContext(LoginContext)!;
    const languageKey = Auth.getLocale();
    const switcherItems = [
        { label: 'Deutsch', value: 'de', defaultValue: languageKey === 'de' },
        { label: 'English', value: 'en', defaultValue: !languageKey || languageKey === 'en' }
    ];
    
    const onLanguageChange = (value: string) => {
        retrieveAndSetLangResource(value).then(value => context.language.set(value));
    };

    return (
        <div className="left-block" style={{ backgroundImage: `url('${imgs}/bg_image.png')` }}>
            <img 
                src={`${imgs}/bimcosmos.svg`} 
                className="company-logo" 
                style={{ cursor: 'pointer' }} 
                onClick={() => window.open(`https://bimcosmos.com${ languageKey === 'de' ? '/de' : '' }`, '_blank')} 
            />
            <div className="bimcosmosLabel">BIMCOSMOS</div>
            <div className="commonDesciption">{t('%The ultimate platform%')}<br />{t('%for efficient building and planning%')}</div>
            <Switcher
                items={switcherItems}
                notNull={true}
                onChange={onLanguageChange}
            />
        </div>
    );
}

/**
 * Renders the right block of the login screen.
 * 
 * Based on the current authentication state, this block dynamically renders different
 * components such as SignIn, SignUp, VerificationRequired, AccountVerified, ResetPassword, and ChangePassword.
 */
function RightBlock() {
    const context = useContext(LoginContext)!;
    const state  = useStore(context.authState.store);
    return (
        <div className="right-block">
            <div className="circle rightBottom"></div>
            <div className="control-block font09">
                { state === AuthStates.SignIn && <SignInByEmail /> }
                { state === AuthStates.SignUp && <SignUp /> } 
                { state === AuthStates.VerificationRequired && <VerificationRequired /> }
                { state === AuthStates.AccountVerified && <AccountVerified /> }
                { state === AuthStates.ResetPassword && <ResetPassword /> }
                { state === AuthStates.ChangePassword && <ChangePassword /> }
                { state === AuthStates.SendEmail && <SendEmail /> }
                { state === AuthStates.Invite && <Invite /> }
            </div>
        </div>
    );
}

/**
 * Creates a cover screen for the login page.
 * 
 * This is used during certain authentication flows to obscure the main login interface.
 * It includes the company logo and branding label.
 */
export function LoginCover() {
    return (
        <div className='loginCover font09' style={{ backgroundImage: `url('${imgs}/bg_image.png')` }}>
            <img src={`${imgs}/bimcosmos.svg`} className="company-logo" />
            <div className='bimcosmosLabel'>BIMCOSMOS</div>
        </div>
    );    
}