import apiClient from "../../utils/apiclient";
import * as Auth from "../../utils/auth";
import {
    callAskResetPassword,
    callLoginEmail,
    callLoginOrganization,
    callOAuthExchange, callOAuthMicrosoft,
    callResetPassword,
    callSignUp, callSignUpVerify,
    callUser,
    callVerifyResetCode
} from "../../utils/login";
import { callRefBooks } from "../../utils/refbooks";


/**
 * Validates the access token and retrieves user data from the database.
 * The access token is automatically attached in the apiClient call.
 * @returns {Object|boolean} The user profile if valid, otherwise false.
 */
export async function validateToken() {
    var res;
    try {
        res = await apiClient.post("/aaa/api/v1/auth/tokens/validate");
        if (res.status !== 200) {
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }

    try {
        res = await apiClient.get("/pfm/api/v1/user");
        if (res.status !== 200 || !res.data) {
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }

    return res.data;
};

/**
 * Authenticates a user via email/password and retrieves permissions, access, and refresh tokens.
 * Sets permissions on the client side.
 * @param {string} email - User's email.
 * @param {string} password - User's password.
 * @returns {Array} [Success status (boolean), Empty string if successful or Error message (string) if failed].
 */
export async function doEmailAuthorizationAndLogin(email: string, password: string) {
    var authResponse;
    try {
        authResponse = await callLoginEmail(email, password, false);
        if (authResponse.statusCode && authResponse.statusCode !== 200) {
            return [false, authResponse.msg];
        }
    } catch (error) {
        console.log(error);
        return [false, ''];
    }    
    Auth.setUserPerms(authResponse.allowedPermissions);
    Auth.setAccessToken(authResponse.accessToken);
    Auth.setRefreshToken(authResponse.refreshToken);
    return [true, ''];
}

/**
 * Fetches the user profile and saves it locally.
 * Also retrieves and locally saves reference books.
 * @returns {string|boolean} Returns the user response status ('OK', 'NEW') if successful, or false if not successful.
 */
export async function doUserCall() {
    const userResponse = await callUser();
    if (userResponse === "OK" || userResponse === "NEW") {
        await callRefBooks();
        return userResponse;
    }
    return false;
}


/**
 * Checks the validity of the refresh token, if available.
 * Deletes local token if invalid, refreshes reference books if valid.
 * Attempts MicrosoftID authentication if previous token is unusable.
 * @returns {boolean} True if the previous token is usable, false otherwise.
 */
export async function doMicrosoftIDAuthorization() {
    if (Auth.getRefreshToken()) {
        const validateResult = await validateToken();
        if (validateResult) {
            try {
                await callRefBooks();
            } catch (error) {
                console.log(error);
            }
            Auth.setUserProfile(validateResult);
            return '';
        }    
        Auth.delTokens();
    }

    const microsoftResponse = await callOAuthMicrosoft();
    return microsoftResponse.authUri;
};

/**
 * Performs authorization using an authentication code from MicrosoftID.
 * @param {string} code - Authentication code from MicrosoftID.
 * @returns {Object} [User response status (string), Auth response (Object), or Error message (string)].
 */
export async function doLoginByOAuth(code: string) {
    const authResponse = await callOAuthExchange(code);
    if (!authResponse || !authResponse.allowedPermissions || !authResponse.accessToken) return { success: false, errorMsg: 'Authentication code is invalid or outdated' };
    Auth.setUserPerms(authResponse.allowedPermissions);
    Auth.setAccessToken(authResponse.accessToken);

    const userResponse = await callUser();
    if (userResponse === "OK" || userResponse === "NEW") {
        Auth.setRefreshToken(authResponse.refreshToken);
        try {
            await callRefBooks();
        } catch (error) {
            console.log(error);
            return { success: false, errorMsg: 'callRefBooks error' }
        }
    } else {
        return { success: false, errorMsg: 'callUser error' }
    }
    return { success: true, userResponse, authResponse };
};

/**
 * Creates a new user with provided email and password.
 * Sends a verification code to the user.
 * @param {string} email - User's email.
 * @param {string} password - User's password.
 * @returns {boolean} True if successful, false otherwise.
 */
export async function doSignUpGetCode(email: string, password: string) {
    try {
        const authResponse = await callSignUp(email, password);
        if (authResponse.status && authResponse.status !== 200) {
            const js = await authResponse.json();
            js.statusCode && js.msg && console.log(js.msg);
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }    
    return true;
}

/**
 * Verifies the authenticity of the verification code.
 * @param {string} email - User's email.
 * @param {string} code - Verification code.
 * @returns {boolean} True if the code is valid, false otherwise.
 */
export async function doVerification(email: string, code: string) {
    try {
        const authResponse = await callSignUpVerify(email, code);
        return authResponse.status === 200;
    } catch (error) {
        console.log(error);
    }

    return false;
}

/**
 * Checks if the format of the verification code is correct.
 * @param {string} code - Verification code.
 * @returns {boolean} True if the code format is valid, false otherwise.
 */
export function isValidVerificationCode(code: string) {
    return /^\d{6}$/.test(code);
}

interface ITimezone {
    id: number,
    name: string
}

export interface IUserProfile {
    firstName: string,
    lastName: string,
    shortName: string,
    countryId: number,
    timezoneId: number,
    languageId: string,
    avatarUrl: string,
    utm?: any,
    email: string,
    invitationCode: string
}

interface IOrganizationItem {
    id: number,
    membershipLevel: number,
    name: string,
    shortName: string,
    subscriptionStatusId: number,
    uid: string,
    isApprovalRequired?: boolean,
    trialExpirationAt?: Date,
}

/**
 * Saves user data (user profile) to the database and locally.
 * @param {string} userName - User's first name.
 * @param {string} userSurname - User's last name.
 * @param {number} countryId - User's country ID.
 * @param {string} languageId - User's language ID.
 * @param {string} email - User's email.
 * @returns {boolean} True if successful, false otherwise.
 */
export async function doUserProfileSave(userName: string, 
                                        userSurname: string,
                                        countryId: number, 
                                        languageId: string, 
                                        email: string) {
    const shortName = `${userName && userName.substring(0, 1).toUpperCase() || ''}${userSurname && userSurname.substring(0, 1).toUpperCase()}` || 'U';
    const ic = Auth.getInvitationCode() || '';
    const tzc = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let tzcId = (Auth.getTimezones().items as ITimezone[]).find(e => e.name === tzc)?.id || 420;
    
    const postData: IUserProfile = {
        firstName:  userName,
        lastName:   userSurname,
        shortName:  shortName, 
        countryId:  countryId,
        timezoneId: tzcId,  
        avatarUrl:  "",
        languageId: languageId,
        email:      email,
        invitationCode: ic,
    };
    try {        
        const res = await apiClient.post("/pfm/api/v1/user", postData);
        if (res.status === 200) {
            Auth.clearInvitationCode();
            Auth.clearInvitationOrg();
            Auth.setUserShortName(shortName);
            Auth.setLocale(languageId);
            res.data && Auth.setUserProfile(res.data);
            return true;
        }
    } catch (error) {
        console.log(error);
    } 

    return false;
}


/**
 * Creates organization.
 * @param {string} organization - User's organization name.
 * @returns {boolean} True if successful, false otherwise.
 */
export async function doCreateOrganization(organization: string) {
 
    const postData = {
        name: organization,
        shortName: organization,
        domains: "",
    };
    try {        
        const res = await apiClient.post("/pfm/api/v1/organizations", postData);
        if (res.status === 200) {

            const res = await apiClient.get("/pfm/api/v1/user");
            if (res.status === 200) {
                if (res.data.organizations) {
                    Auth.setUserProfile(res.data);
                    let o: IOrganizationItem[] = res.data.organizations;
                    o.forEach(org => {
                        if (organization === org.name) {
                            callLoginOrganization(org.id).then(result => {
                                return (result === "OK");
                            });
                        }
                    });
                }
            }

            return true;
        }
    } catch (error) {
        console.log(error);
    } 

    return false;
}

/**
 * Switches to organization.
 * @param {string} organization - User's organization name.
 * @returns {boolean} True if successful, false otherwise.
 */
export async function doJoinOrganization(organization: string) {

    try {        
        const res = await apiClient.get("/pfm/api/v1/user");
        if (res.status === 200) {
            if (res.data.organizations) {
                Auth.setUserProfile(res.data);
                let o: IOrganizationItem[] = res.data.organizations;
                o.forEach(org => {
                    if (organization === org.name) {
                        callLoginOrganization(org.id).then(result => {
                            return (result === "OK");
                        });
                    }
                });
            } else {
                console.log("error: the user is not a member of any organization");
            }
            
            return true;
        }

    } catch (error) {
        console.log(error);
    } 

    return false;
}


/**
 * Ask permission to change password.
 * @param {string} email - User's email.
 * @returns {boolean} True if permission is received, false otherwise.
 */
export async function doAskResetPassword(email: string) {
    const authResponse = await callAskResetPassword(email);
    return authResponse.status === 200;
}

/**
 * Change login password.
 * @param {string} password - User's password.
 * @param {string} code - Verification code.
 * @returns {boolean} True if the password has changed, false otherwise.
 */
export async function doResetPassword(password: string, code: string) {
    const authResponse = await callResetPassword(password, code);
    return authResponse.status === 200;   
}

/**
 * Verify reset password code.
 * @param {string} code - Verification code.
 * @returns {boolean} True if the code is applicable, false otherwise.
 */
export async function doVerifyResetPassword(code: string) {
    const authResponse = await callVerifyResetCode(code);
    return authResponse.status === 200; 
}

/**
 * Apply invitation acceptance..
 * @param {string} code - Verification code.
 * @returns {boolean} True if the code is applicable, false otherwise.
 */
export async function doAcceptInvitationCall(ic: string) {

    try {        
        const res = await apiClient.post("/pfm/api/v1/invitations/apply", {code: ic});
        // console.info("res(AI): ", res);
        if (res.status === 200) {
            if (res.data.organizationId) {
                return true
            }
        }
    } catch (error) {
        console.log(error);
    } 

    return false;
}
