import { Grider } from "../../components/AppComponents/Grider/Grider";
import { Header } from "../../components/BCComponents/Layouts/Layouts";
import { FlatVPanel } from "../../components/BCComponents/Layouts/Panels/ArrangementPanels";
import { t } from "../../locale";
import { InitSecurityModals } from "./SecurityModals";

export function Security() {
    return <FlatVPanel height="100%" width="100%">
            <Header label={t('%AdminSecurity%')} />
            <Grider tabs={[
            {
                content: 'users',
                label: 'Users',
                module: 'admin'
            }, 
            {
                content: 'roles',
                label: 'Roles',
                serviceDomain: 'aaa',
                module: 'admin'
            },
            {
                content: 'permissions',
                label: 'Permissions ',
                serviceDomain: 'aaa',
                module: 'admin'
            }
        ]} />       
    </FlatVPanel>
}

InitSecurityModals();
