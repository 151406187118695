import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import './RTable.css'; // Создайте файл стилей RTable.css

const minWidth = 40;

interface ITableElement { 
    children: ReactNode | ReactNode[]; 
    style?: CSSProperties; 
    classes?: string; 
    onClick?: (event: React.MouseEvent) => void 
}

type IHCell = ITableElement; 
type IDCell = ITableElement & { contentStyle?: CSSProperties };
type IDRow  = ITableElement;

interface IHead { children: React.ReactElement<IHCell> | React.ReactElement<IHCell>[]; style?: CSSProperties; classes?: string }
interface IData { children: React.ReactElement<IDRow> | (React.ReactElement<IDCell> | null)[]; style?: CSSProperties; classes?: string }

interface IRTable {
    children: [React.ReactElement<IHead>, React.ReactElement<IData>];
    style?: CSSProperties;
    group?: string;
    name?: string;
    classes?: string;
}

interface thEntity { 
    th: HTMLTableCellElement,
    nextTh?: HTMLTableCellElement,
    resizer: HTMLElement,
    mouseDownHandler?: (e: MouseEvent) => void,
}     

type rtablesRecord = Record<string, Record<string, number>>;

function getTableHeadersTotalWidth(table: HTMLTableElement) {
    return Array.from(table.tHead?.rows[0]?.querySelectorAll(':scope>th') || []).reduce((acc, el) => acc + (el as HTMLElement).getBoundingClientRect().width, 0) || 1;
}

function RTable({ children, style, name, group, classes } : IRTable) {
    const refTable = useRef<HTMLTableElement>(null);
  
    let startX = 0;
    let initialWidth = 0;
    let initialTotalWidth = 0;
    let delta = 0;
    let columnIndex = -1;
    let timeout: NodeJS.Timeout | null = null;
    let thEntities: thEntity[] = [];
    let cellsContent: Element[] = [];
    let cellsAdjacentContents: Element[] = [];

    function getFieldsTitleList() {
        const fields = Array.from(refTable.current?.tHead?.rows[0]?.querySelectorAll(':scope>th') || []).map(el => (el as HTMLElement).innerText.toUpperCase()).join('#');
        return fields ? `FIELDS_${fields}`.toUpperCase() : '';
    }

    function getTableUID() {
        return name ? `name_${name}` : group ? `group_${group}` : getFieldsTitleList();
    }

    function getFieldsPercentWidths(asArray?: boolean) {
        if (!refTable.current) return {};    
        const totalWidth = getTableHeadersTotalWidth(refTable.current);
    
        return asArray && Array.from(refTable.current.tHead?.rows[0]?.querySelectorAll(':scope>th') || []).map(el => ((el as HTMLElement).getBoundingClientRect().width / totalWidth) * 100)
        || 
        Array.from(refTable.current.tHead?.rows[0]?.querySelectorAll(':scope>th') || []).reduce((acc, el, index) => { 
            acc[(el as HTMLElement).innerText || `field_${index}`] = ((el as HTMLElement).getBoundingClientRect().width / totalWidth) * 100; 
            return acc; 
        }, {} as Record<string, number>);
    }
    
    function setFieldsWidthsInPercent() {
        if (!refTable.current) return {};    
        const headerWidths = getFieldsPercentWidths(true) as number[];

        Array.from(refTable.current.tHead?.rows[0]?.querySelectorAll(':scope>th') || []).forEach((el, index) => { 
            (el as HTMLElement).style.width = `${headerWidths[index]}%`
        });
    }

    function restoreColumnWidthsFromStorage() {
        const tableUID = getTableUID();
        if (!tableUID || !refTable.current) return;
    
        try {
            const rtables = JSON.parse(localStorage.getItem('rtables') || '{}') as rtablesRecord;
    
            const tableWidths = rtables[tableUID];
            if (tableWidths) {
                const currentFields = Array.from(refTable.current?.tHead?.rows[0]?.querySelectorAll(':scope>th') || []).map(el => (el as HTMLElement).innerText.toUpperCase());
                if (JSON.stringify(Object.keys(tableWidths).sort()) === JSON.stringify([...currentFields].sort())) {

                    Array.from(refTable.current?.tHead?.rows[0]?.querySelectorAll(':scope>th') || []).forEach((el, index) => {
                        (el as HTMLElement).style.width = `${tableWidths[ currentFields[index]]}%`;
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    function saveColumnWidthsToStorage() {
        const tableUID = getTableUID();
        if (!tableUID) return;

        try {
            const rtables = JSON.parse(localStorage.getItem('rtables') || '{}') as rtablesRecord;    
            rtables[tableUID] = getFieldsPercentWidths() as Record<string, number>;
            localStorage.setItem('rtables', JSON.stringify(rtables));
        } catch (error) {
            console.error(error);
        }
    }

    const onInterval = () => {
        const ent = thEntities[columnIndex];        
        if (!ent || !ent.nextTh || !refTable.current || !refTable.current.tHead) return;

        let newWidth = initialWidth + delta;
        newWidth = newWidth < minWidth ? minWidth + 0.01 : newWidth;
        let newAdjacentWidth = initialTotalWidth - newWidth;
        newAdjacentWidth = newAdjacentWidth < minWidth ? minWidth + 0.01 : newAdjacentWidth; 
        
        if (minWidth <= newWidth && newWidth <= initialTotalWidth - minWidth && minWidth <= newAdjacentWidth && newAdjacentWidth <= initialTotalWidth - minWidth) {
            ent.th.style.width = `${newWidth}px`;
            ent.nextTh.style.width = `${newAdjacentWidth}px`;
        }
        
        timeout = timeout && setTimeout(onInterval, 50);
    };

    function handleMouseDown(th: HTMLTableCellElement, nextTh: HTMLTableCellElement) {
        if (!nextTh) return;
        initialWidth = th?.getBoundingClientRect().width || 0;
        initialTotalWidth = initialWidth + (nextTh?.getBoundingClientRect().width || 0);
        delta = 0;
        
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        refTable.current?.classList.add('isResizing');

        timeout = setTimeout(onInterval, 90);
    };

    const onMouseMove = (e: MouseEvent) => { delta = e.pageX - startX };
  
    const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        refTable.current?.classList.remove('isResizing');

        timeout && clearTimeout(timeout);
        timeout = null;
        onInterval();
        setFieldsWidthsInPercent();
        saveColumnWidthsToStorage();
    };

    useEffect(() => {
        const thsList = Array.from(refTable.current?.tHead?.rows[0]?.cells || []);
        thEntities = thsList.map((th, index) => {
            const thNext = thsList[index + 1];
            const _entity: thEntity = {
                th:  th,
                nextTh: thNext,
                resizer: th.querySelector(":scope>.resizer") as HTMLElement,
            }
            return _entity;
        });

        thEntities.forEach((ent, index) => {
            if (index === thEntities.length - 1) return;
            ent.mouseDownHandler = (e: MouseEvent) => {
                setFieldsWidthsInPercent();
                startX = e.pageX;
                columnIndex = index;
                ent.nextTh && handleMouseDown(ent.th, ent.nextTh);
            };
            ent.resizer?.addEventListener("mousedown", ent.mouseDownHandler);
        });
        
        setFieldsWidthsInPercent();
      
        restoreColumnWidthsFromStorage();

        return () => thEntities.forEach(ent => { ent.mouseDownHandler && ent.resizer?.removeEventListener("mousedown", ent.mouseDownHandler) });
    }); 
  
    return <table 
        ref={ refTable }
        className={`resizable ${classes || ''}`} 
        style={ style }
    >{ children }</table>;
};
  
function RHeader({ children, classes, style } : IHead) { return <thead className={classes} style={style}><tr>{children}</tr></thead> };

function RData({ children, classes, style } : IData) { return <tbody className={classes} style={style}>{children}</tbody> };

function RRow({ children, classes, style, onClick } : IDRow) { return <tr className={classes} style={style} onClick={onClick}>{children}</tr> }

function HCell({ children, style, classes, onClick } : IHCell) {
    return (
        <th style={ style } className={classes} onClick={onClick}>
            <div className="resizer"></div>
            <div className="cellContent">
                {children}
            </div>
        </th>
    );
};
    
function DCell({ children, style, classes, onClick, contentStyle } : IDCell) {
    return (
        <td style={ style } className={classes} onClick={onClick}>
            <div className="cellContent" style={contentStyle}>
                {children}
            </div>
        </td>
    );
};
  
export { RTable, RHeader, HCell, RData, RRow, DCell };
