import { useState } from 'react';
import { Button } from '../../BCComponents/Controls/Buttons/Buttons';
import { IconButton } from '../../BCComponents/Controls/Buttons/IconButton/IconButton';
import { FlatHPanel } from '../../BCComponents/Layouts/Panels/ArrangementPanels';
import './Paginator.css';
import { Label } from '../../BCComponents/Controls/Label/Label';

interface IPaginatorProps {
    total: number;
    pageSize: number;
    currentPage?: number;
    onChange: (page: number) => void;
    maxPagesCount?: number;
}

export function Paginator({ total, pageSize, currentPage = 0, onChange, maxPagesCount = 7 }: IPaginatorProps) {
    const [page, setPage] = useState(currentPage);

    const totalPages = Math.ceil(total / pageSize);

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
        onChange(newPage);
    };

    const renderPages = () => {
        let pages = [];
        let startPage = Math.max(1, page + 1 - Math.floor(maxPagesCount / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesCount - 1);

        if (endPage - startPage + 1 < maxPagesCount) {
            startPage = Math.max(1, endPage - maxPagesCount + 1);
        }

        if (startPage > 1) {
            pages.push(<Button key={1} onClick={() => handlePageChange(0)}>{1}</Button>);
            if (startPage > 2) {
                pages.push(<Label key="start-ellipsis" >...</Label>);
            }
        }

        for (let i = (page > totalPages - maxPagesCount && (totalPages - maxPagesCount + 1 > 0) ? totalPages - maxPagesCount + 1 : startPage); i <= (page < (maxPagesCount - 1) && (maxPagesCount < endPage) ? maxPagesCount : endPage); i++) {
            pages.push(
                <Button key={i} onClick={() => handlePageChange(i - 1)} classes={`${i - 1 === page && 'active' || ''}`}>
                    {i}
                </Button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(<Label key="end-ellipsis">...</Label>);
            }
            pages.push(<Button key={totalPages} onClick={() => handlePageChange(totalPages - 1)}>{totalPages}</Button>);
        }

        return pages;
    };

    return (
        <FlatHPanel classes="paginator" verticalAlign='center'>
            <IconButton
                icon="arrowLeft"
                disabled={page === 0}
                onClick={() => page > 0 && handlePageChange(page - 1)}
            />
            {renderPages()}
            <IconButton
                icon="arrowRight"
                disabled={page === totalPages - 1}
                onClick={() => page < totalPages - 1 && handlePageChange(page + 1)}
            />
        </FlatHPanel>
    );
}