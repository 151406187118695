import React, { useState, ReactNode, KeyboardEvent, CSSProperties, useMemo, useEffect } from 'react';
import './TabView.css';
import '../../bcUsers.css';
import { Store } from 'effector';
import { useStore } from 'effector-react';
import { createActiveStore } from '../../../../state/ActiveStore';
import { Attr, cssProp } from '../../../../utils/utils';

export type PageId = string | number;

/**
 * Interface representing a tab page with an ID and an optional label.
 *
 * @interface ITabPage
 */
export interface ITabPage {
    /**
     * The unique identifier of the tab page.
     *
     * @type {PageId}
     */
    id: PageId;
  
    /**
     * An optional label for the tab page.
     *
     * @type {string | undefined}
     */
    label?: string;
}

export type OnTabChangeEvent = (source: ITabPage | undefined, target: ITabPage, setControlPanel: (controlsPanel: JSX.Element) => void) => void;

/**
 * Properties for the TabView component.
 *
 * @interface ITabViewProps
 */
export interface ITabViewProps {
    /** An array of tab pages to display. */
    pages: ITabPage[];
    
    /** The ID of the currently active tab page. */
    activeTab?: PageId;
    
    /** CSS styles to apply to the outer container of the TabView. */
    style?: CSSProperties;
    
    /** CSS styles to apply to individual tab pages. */
    pageStyle?: CSSProperties;
    
    /** CSS styles to apply to individual tab items in the header. */
    tabItemStyle?: CSSProperties;
    
    /**
     * A function called before a tab change. It takes two parameters, source tab and target tab,
     * and should return `true` to allow the change or `false` to prevent it.
     */
    beforeChange?: (source: ITabPage, target: ITabPage) => boolean;
    
    /**
     * A function called after a tab change. It receives the source tab (if any) and the target tab as parameters.
     */
    onChange?: OnTabChangeEvent;

    classes?: string;
    
    /** The content to be displayed within the tab pages. */
    children: ReactNode | ReactNode[];
}

/**
 * TabView Component - A tabbed view for switching between pages.
 *
 * @component
 * @param {ITabPage[]} [pages] - An array of tab pages to be displayed.
 * @param {PageId} [activeTab] - The currently active tab page's ID.
 * @param {CSSProperties} [style] - Additional CSS styles to apply to the tab view container.
 * @param {CSSProperties} [pageStyle] - Additional CSS styles to apply to the tab page container.
 * @param {CSSProperties} [tabItemStyle] - Additional CSS styles to apply to individual tab items.
 * @param {(source: ITabPage, target: ITabPage) => boolean} [beforeChange] - A function called before changing the active tab.
 * @param {(source: ITabPage | undefined, target: ITabPage) => void} [onChange] - A function called after changing the active tab.
 *
 * @returns {JSX.Element} The rendered TabView component.
 *
 * @example
 * // Example usage:
 * <TabView
 *   pages={[
 *     { id: 'page1', label: 'Page 1' },
 *     { id: 'page2', label: 'Page 2' },
 *     { id: 'page3', label: 'Page 3' }
 *   ]}
 *   activeTab="page1"
 *   beforeChange={(source, target) => true}
 *   onChange={(source, target) => {}}
 *   style={{ backgroundColor: 'lightgray' }}
 * >
 *   <div>Content for Page 1</div>
 *   <div>Content for Page 2</div>
 *   <div>Content for Page 3</div>
 * </TabView>
 */
export function TabView({ pages, activeTab, classes, beforeChange, children, onChange, pageStyle, style, tabItemStyle }: ITabViewProps) {
    const [currentTab, setCurrentTab] = useState<PageId>(activeTab ?? pages[0].id);
    const controlsPanel = useMemo(() => createActiveStore<JSX.Element | null>(null), []);
    const [injectedTabs, setInjectedTabs] = useState<Set<PageId>>(new Set([currentTab]));

    const childrenArray = React.Children.toArray(children);

    const handleTabChange = (targetId: PageId) => {
        const sourceTab = pages.find(page => page.id === currentTab);
        const targetTab = pages.find(page => page.id === targetId);
        if (!targetTab || (sourceTab && beforeChange && !beforeChange(sourceTab, targetTab))) return;
        setCurrentTab(targetId);
        setInjectedTabs(new Set(injectedTabs).add(targetId));
        controlsPanel.set(null);
        onChange && onChange(sourceTab, targetTab, (panel) => controlsPanel.set(panel));
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        const currentIndex = pages.findIndex(page => page.id === currentTab);
        const lastTabIndex = pages.length - 1;
        if (lastTabIndex === -1) return;
    
        if (event.key === 'ArrowLeft' && currentIndex > 0) {
            handleTabChange(pages[currentIndex - 1].id);
        } else if (event.key === 'ArrowRight' && currentIndex < lastTabIndex) {
            handleTabChange(pages[currentIndex + 1].id);
        } else if (event.key === 'ArrowUp') {
            handleTabChange(pages[0].id); 
        } else if (event.key === 'ArrowDown') {
            handleTabChange(pages[lastTabIndex].id); 
        }
    };
    
    useEffect(() => { handleTabChange(activeTab ?? pages[0].id) }, [activeTab]);

    return (
        <div className={`tab-view ${classes || ''}`} style={style}>
            <div tabIndex={0} className="tab-header" onKeyDown={handleKeyDown}>
                {pages.map((page, index) => (
                    <div
                        key={`${index}#${page.id}`}
                        className={`tab-item ${currentTab === page.id ? 'active' : ''}`}
                        style={tabItemStyle}
                        onClick={() => handleTabChange(page.id)}
                    >
                        {page.label || page.id}
                    </div>
                ))}
                <ExtraControls contolPanelStore={controlsPanel.store} />
            </div>
            <div className="tab-content" >
                {pages.map((page, index) => (
                    <div
                        key={`${index}#${page.id}`}
                        className={`tab-page ${currentTab === page.id ? 'visible' : ''}`}
                        style={{ ...pageStyle }}
                        {...Attr('data-page-id', true, page.id)}
                    >
                        {injectedTabs.has(page.id) && (index < childrenArray.length ? childrenArray[index] : <div></div>)}
                    </div>
                ))}
            </div>
        </div>
    );
}

function ExtraControls({ contolPanelStore } : { contolPanelStore: Store<JSX.Element | null> }) {
    return useStore(contolPanelStore);
}
