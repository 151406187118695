import { CSSProperties } from "react"
import { t } from "../../../locale"
import { cssProp } from "../../../utils/utils"
import './Modals.css'

interface ICommonJSXContainer { 
    classes?: string, 
    children?: React.ReactNode | React.ReactNode[] 
}

export function ModalVOrganizer({ classes, children, labelWidth } : ICommonJSXContainer & { labelWidth?: string }) {
    return (<table className={`modalVOrganizer ${classes || ''}`} style={{...cssProp('--label-width', labelWidth, labelWidth)}} ><tbody>
        { children }
    </tbody></table>)
}

export function ModalVRow({ classes, children, label, colSpan, required } : ICommonJSXContainer & { colSpan?: boolean, required?: boolean, label?: string | JSX.Element }) {
    return (<tr className={`modalVRow ${classes || ''}${required && ' required' || ''}`} style={ {['--required']: `'${t('%required%')}'` } as CSSProperties }>
        {!colSpan && <>
            <ModalVLabel>{typeof label === 'object' && label || <div className="fieldLabel"  ><div style={{ marginRight: '0.5em' }}>{label && t(`%${label}%`) || ''}</div></div>}</ModalVLabel>
            <ModalVData>{children}</ModalVData>
        </> || <ModalVSpan>{ children }</ModalVSpan>}
    </tr>)
}

function ModalVLabel({ classes, children } : ICommonJSXContainer) {
    return (<td className={`modalVLabel ${classes || ''}`}>
        { children }
    </td>)
}

function ModalVData({ classes, children } : ICommonJSXContainer) {
    return (<td className={`modalVData ${classes || ''}`}>
        <div className="modalVDataContainer">{ children }</div>
    </td>)
}

function ModalVSpan({ classes, children } : ICommonJSXContainer) {
    return (<td colSpan={2}>
        <div className="modalVSpanContainer">{ children }</div>
    </td>)
}

export function prefferedList(list?: number[] | string[]): string[] | undefined {
    return list?.map(item => typeof item === 'number' ? item.toFixed(0) : item);
} 