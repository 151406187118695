import { useEffect, useState, KeyboardEvent, ClipboardEvent, useRef, useMemo, CSSProperties, useLayoutEffect } from "react";
import './DatePickerEasy.css';
import { FontIcon, FontSizes, TooltipHost } from "@fluentui/react";
import { InPortal } from "../Portal/Portal";
import { elementRect } from "../utils";

export interface IDateEasy {
    year:   number,
    month:  number,
    day:    number
}

export type IDateFormatEasy = string | Date | IDateEasy;

export enum ShowCalendarEasy {
    noShow = 1,
    onFocus,
    onPressEnter,
    onClick,
}

export enum OnExitInvalidation {
	invalidate = 1,
	correct,
}

const defaultDateBehaviourEasy: ShowCalendarEasy[] = [ShowCalendarEasy.onFocus, ShowCalendarEasy.onClick, ShowCalendarEasy.onPressEnter];

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const ep = ' ';
const epMask = `${ep}.${ep}${ep}.${ep}${ep}${ep}${ep}`;


export interface ISpecialDayEasy<T extends IDateFormatEasy> {
	dt:     T,
    name?:  string
}

// day in format dd.mm.yyyy
let DefaultSpecialDaysEasy: ISpecialDayEasy<string>[] = []; 

export function SetDefaultSpecialDaysEasy(value: ISpecialDayEasy<string>[]) {
	DefaultSpecialDaysEasy = value;
}

export interface    IBaseDateEasy<T extends IDateFormatEasy> {
    date?:         	T | null,
    specialDays?:   ISpecialDayEasy<T>[],
	style?:			CSSProperties;

    onChange?:   	(newDate: Date | null, newDateStr: string | null, newDateEasy: IDateEasy | null) => void,
	onCreate?:		() => void,

	minDate?:		T,
	maxDate?:		T,
}

export interface    IDatePickerEasy<T extends IDateFormatEasy> extends IBaseDateEasy<T> {
    placeHolder?:   string | undefined,
    showCalendar?:  ShowCalendarEasy[] | undefined, 
	notNull?:		boolean;
	markInvalid?:	boolean;
	calendarStyle?: CSSProperties;
	calendarHolderStyle?:  CSSProperties;
	onExitValidation?: OnExitInvalidation; 
	classes?: string;
	popUpRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export interface	ICalendarEasy<T extends IDateFormatEasy> extends IBaseDateEasy<T> {
	specialDays?: ISpecialDayEasy<T>[],
	controller?: (value?: any) => void 
}

function isDate(value: any): value is Date { 
    return value instanceof Date; 
}

function lz(v: number) {
	const str = '0' + v.toString()
	return str.substring(str.length - 2);	
}

const dt = new Date();

function getStringDate<T extends IDateFormatEasy>(value: T | undefined | null): string {
    if (!value) return '';
    return  typeof value === 'string' && dt.setDDMMYYYY(value).DDMMYYYY() ||
            isDate(value) && value.DDMMYYYY() ||
            (typeof value === 'object') && (new Date((value as IDateEasy).year, (value as IDateEasy).month - 1, (value as IDateEasy).day)).DDMMYYYY() || 
            '';
}

type DateChangeHandler = (newDate: string) => void;

const invalidated = 'invalidated';

export function DatePickerEasy<T extends IDateFormatEasy = Date>({date, classes, placeHolder, popUpRef, onChange, onCreate, minDate, maxDate, showCalendar, specialDays, style, calendarStyle, calendarHolderStyle, notNull, markInvalid, onExitValidation}: IDatePickerEasy<T>) {
    const [curDate, setCurDate]                 = useState<string>(getStringDate(date));
    const [calendarVisible, setCalendarVisible] = useState(false);
	const inputRef 				= useRef<HTMLInputElement>(null);
	const calendarRef			= useRef<HTMLDivElement>(null);
	const focusPendingStatus 	= useRef<'controlling' | 'idle'>('idle');
	const showCal 				= showCalendar || defaultDateBehaviourEasy;
	const timerRef				= useRef<{timer?: NodeJS.Timeout}>({timer: undefined});
	const isValid				= useRef<boolean>(true);
	const actionOnExit			= onExitValidation || OnExitInvalidation.correct;
	const focusedNow			= useRef<boolean>(false);
	const mounted				= useRef<boolean>(true);
	const firstOnChange			= useRef(true);
	
	const minDt					= useRef(new Date().setDDMMYYYY(getStringDate(minDate) || '01.01.1000').getTime());
	const maxDt					= useRef(new Date().setDDMMYYYY(getStringDate(maxDate) || '01.01.9999').getTime());

	useEffect(() => {
		popUpRef && (popUpRef.current = calendarRef.current);
	}, [popUpRef, calendarRef.current]);

    useEffect(() => {
		inputRef.current && (inputRef.current.value = curDate);
		markValidity(true);
		if (!firstOnChange.current && onChange) {
			const newDt = curDate && (new Date()).setDDMMYYYY(curDate) || null;
			onChange(newDt, newDt && curDate || null, newDt && {day: newDt.getDay(), month: newDt.getMonth(), year: newDt.getFullYear()} || null);
		}	
		firstOnChange.current  = false;
    }, [curDate]);

    useEffect(() => {
		setCurDate(getStringDate(date));
		setCalendarVisible(false);
    }, [getStringDate(date)]);
	
	useEffect(() => {
		onCreate && setTimeout(onCreate, 0);
		return () => { mounted.current = false; }
	}, []);

	function markValidity(valid: boolean, forceInvalidate?: boolean) {
		isValid.current = valid || !markInvalid;
		isValid.current = forceInvalidate ? false : isValid.current; 
		!isValid.current && inputRef.current!.setAttribute(invalidated, '');
		isValid.current && inputRef.current!.removeAttribute(invalidated);
	}

	function validatedSetCurDate(date:  string): boolean {
		if (!date) {
			if (notNull) { 
				markValidity(false);
				return false; 
			} 
			setCurDate('');
			return true;	
		}	 
		const dt = new Date();
		try { 
			dt.setDDMMYYYY(date); 
			if (dt.DDMMYYYY() === date && minDt.current <= dt.getTime() && dt.getTime() <= maxDt.current ) {
				setCurDate(date);
				markValidity(true);
				return true;
			}
			markValidity(false);	  
		} catch (error) { 
			markValidity(false);
		}
		return false;
	}

	function initTimer() {
		timerRef.current.timer && clearTimeout(timerRef.current.timer);
		timerRef.current.timer = setTimeout(() => {
			inputRef.current!.focus();
			timerRef.current.timer = undefined;
		}, 100);
	}

	function calendarButtonMouseDown() {
		if (showCal.indexOf(ShowCalendarEasy.onClick) === -1) return;
		focusPendingStatus.current = 'controlling'; 
		setCalendarVisible(!calendarVisible);
		initTimer();
	}

	function handleFocus(event: any) {
		focusedNow.current = true;
		const input = event.target as HTMLInputElement;
		if (focusPendingStatus.current === 'idle') {
			input.select();
			showCal.indexOf(ShowCalendarEasy.onFocus) > -1 && setCalendarVisible(true);		
		} else {
			input.selectionEnd = input.value.length;
			input.selectionStart = input.selectionEnd;
			focusPendingStatus.current = 'idle';
		}
	}

	function handleFocusLose(event: any) {		
		focusPendingStatus.current === 'idle' && setCalendarVisible(false);
		focusedNow.current = false;
		setTimeout(() => {
			const dt = inputRef.current?.value;
			if (!focusedNow.current && mounted.current && inputRef.current && dt) {
				if (actionOnExit === OnExitInvalidation.correct) {
					event.target.value = validatedSetCurDate(dt) ? dt : curDate;					;
					if (notNull && !event.target.value) { 
						markValidity(false, true); 
					} else
						markValidity(true);
				} else 
					(event.target.value !== dt || (notNull && !dt)) && markValidity(false, true);
			}
		}, 50);
	}

	function onCalendarController() { 
		focusPendingStatus.current = 'controlling';
		initTimer();
	}	

	function onPaste(event: ClipboardEvent) {
		event.preventDefault();
		const input = event.target as HTMLInputElement;
		const clipboardData = event.clipboardData || (window as any).clipboardData;
		const pastedText = clipboardData.getData('text/plain');
	
		if (/^\d{2}\.\d{2}\.\d{4}$/.test(pastedText)) {
			input.value = pastedText;
		}
		// call onChange here
	}
	
	function replaceDigitsWithUnderscore(input: HTMLInputElement, start: number, finish: number) {
		const value = input.value;
		if (finish > start) {
			const prefix = value.substring(0, start);
			const suffix = value.substring(finish);
			const range = value.substring(start, finish);
			const replacedRange = range.replace(/\d/g, ep);
			input.value = `${prefix}${replacedRange}${suffix}`;
		}
	}
	
	function onKeyDown(event: KeyboardEvent) {
		const input = event.target as HTMLInputElement;
		const key = event.key;
	
		if (key === 'Tab') return;
	
		const value = input.value;
		let start = input.selectionStart || 0;
		let finish = input.selectionEnd || 0;
		const ctrlKey = event.ctrlKey || event.metaKey;
	
		const allowedKeys = [ "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "PageUp", "PageDown","Backspace", "Delete", "Enter", "Escape", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
	
		if ((ctrlKey && ['a', 'A', 'c', 'C', 'v', 'V'].indexOf(key) > -1) || (ctrlKey && key === 'x' && value.length > 0)) {
			return;
		}
	
		if (!allowedKeys.includes(key)) {
			event.preventDefault();
			return;
		}

		function daysUpDown(dir: number) {
			const dt = curDate && (new Date()).setDDMMYYYY(curDate) || new Date();
			dt.setDate(dt.getDate() + dir);
			validatedSetCurDate(dt.DDMMYYYY());
			event.preventDefault();
		}
	
		if (key === 'PageUp' || key === 'ArrowLeft' && ctrlKey) { daysUpDown(-1); return; }
		if (key === 'PageDown'|| key === 'ArrowRight' && ctrlKey) { daysUpDown(1); return;}
		if (key === 'ArrowUp' && ctrlKey) { daysUpDown(-7); return; }
		if (key === 'ArrowDown' && ctrlKey) { daysUpDown(7); return; }

		if (key === 'Escape') {
			setCalendarVisible(false);
			input.value = curDate;
			focusPendingStatus.current = 'idle'; 
			event.preventDefault();
			calendarVisible && event.stopPropagation(); 
			return; 
		}

		if (ctrlKey && key === 'Delete') {
			validatedSetCurDate('');
			event.preventDefault();
			return;
		}

		if (key === 'Enter') {
			if (showCal.indexOf(ShowCalendarEasy.onPressEnter) === -1) return;
			setCalendarVisible(!calendarVisible);
			input.value = curDate;
			focusPendingStatus.current = 'idle'; 
			event.preventDefault(); 
			return; 
		}

		if (value === `${ep}${epMask}`) {
			validatedSetCurDate('');
			input.value = '';
			return;
		}
	
		if (value.length === 0 && /\d/.test(key)) {
			input.value = `${key}${epMask}`;
			input.setSelectionRange(1, 1);
			event.preventDefault();
			return;
		}
	
		if (key === 'Backspace') {
			if (start === 0 && finish === 0) {
				// setCurDate('');
				return; 
			}
			if (finish > start) {
				replaceDigitsWithUnderscore(input, start, finish);
				input.setSelectionRange(start, start);
				event.preventDefault();
				return;
			}
	
			if (start === finish) {
				if (start === 3 || start === 6) {
					start--;
					finish--;
				}
				input.value = value.substring(0, start - 1) + ep + value.substring(start);
				input.setSelectionRange(start - 1, start - 1);
				event.preventDefault();
				return;
			}
	
			event.preventDefault();
			return;
		}
	
		if (key === 'Delete') {
			if (start === value.length) { return; }
			if (start === finish) {
				if (start === 2 || start === 5) {
					start++;
					finish++;
				}
				input.value = value.substring(0, start) + ep + value.substring(start + 1);
				input.setSelectionRange(start, start);
				event.preventDefault();
				return;
			}
	
			if (finish > start) {
				replaceDigitsWithUnderscore(input, start, finish);
				input.setSelectionRange(start, start);
				event.preventDefault();
				return;
			}
	
			event.preventDefault();
			return;
		}
	
		if (/\d/.test(key)) {
			if (start < 10) {
				if (finish > start) replaceDigitsWithUnderscore(input, start, finish);
				const position = start;
				start = (start === 2 || start === 5) ? start + 1 : start;
				input.value = input.value.substring(0, start) + key + input.value.substring(start + 1);
				start = (position === 1 || position === 4) ? start + 1 : start;
				input.setSelectionRange(start + 1, start + 1);
			}
			event.preventDefault();
			return;
		}
	}
	
	function onKeyUp(event: KeyboardEvent) {
		const input = event.target as HTMLInputElement;
		if (input.value === `${ep}${epMask}`) { input.value = ''; validatedSetCurDate(''); }
		input.value.length === 10 && /^\d{2}\.\d{2}\.\d{4}$/.test(input.value) && validatedSetCurDate(input.value);
	}

	try { curDate && dt.setDDMMYYYY(curDate); } catch (error) { }

	function calendarPortalDone() {
		const input = inputRef.current;
		const calendar = calendarRef.current;
		popUpRef && (popUpRef.current = calendar);
		if (calendarVisible && calendar && input) {
			const inputRect = input && elementRect(input);
			const calendarRect = elementRect(calendar);
			calendar.style.left = `unset`;
			calendar.style.top = 'unset';

			if ((inputRect.yBottom + 1.25 * calendarRect.height) > document.body.clientHeight) { 
				calendar.classList.add('abovePicker');
				calendar.classList.remove('underPicker');
				calendar.style.bottom 	= `${document.body.clientHeight - inputRect.yTop + 2}px`;
			} else {
				calendar.classList.add('underPicker');
				calendar.classList.remove('abovePicker');
				calendar.style.top 		= `${inputRect.yBottom + 2}px`;
			}	

			(inputRect.xLeft + 1.1 * calendarRect.width) > document.body.clientWidth ?
				calendar.style.right 	= `${document.body.clientWidth - inputRect.xRight + 2}px`:
				calendar.style.left 		= `${inputRect.xLeft + 2}px`;

			calendar.style.opacity = '1';	
		};
	};

    return(
        <div className={`datePickerEasy ${classes || ''}`}>
			<div className="datePickerContainer" style={style}>
				<div className="inEdit" onMouseDown={ calendarButtonMouseDown }>
					{ curDate && <div className="detailedDateInfo">{`${weekDays[dt.getDay()]} [${dt.getWeekNumber()}]`}</div>}
					<FontIcon iconName="Calendar" style={{ fontSize: '1em', marginTop: '0.2em', color: '#999' }} />
				</div>
				<input 
					ref			= { inputRef }
					type        = "text" 
					onPaste     = { onPaste } 
					onKeyDown   = { onKeyDown } 
					onKeyUp     = { onKeyUp } 
					onFocus     = { handleFocus }
					onBlur		= { handleFocusLose }
					defaultValue= { curDate }
					placeholder = { placeHolder } 
					style		= { style }
					{ ...(!isValid.current && {[invalidated]: ''} || {}) }

				/>
			</div>	
			<InPortal name="popUp" done={ calendarPortalDone }>
				{calendarVisible && showCal.indexOf(ShowCalendarEasy.noShow) < 0 &&
					<div ref={ calendarRef } className={`calendarHolder ${classes || ''}`} style={ { ...calendarHolderStyle, opacity: 0, left: 0, top: 0 } }>
						<CalendarEasy<string>
							date		= { curDate } 
							minDate		= { getStringDate(minDate) }
							maxDate		= { getStringDate(maxDate) }
							specialDays	= { specialDays && specialDays.map(v => ({ dt: getStringDate(v.dt), name: v.name })) || DefaultSpecialDaysEasy }
							style		= { calendarStyle }
							onChange	= { (newDate) => { 
								setCalendarVisible(false);
								setCurDate(newDate?.DDMMYYYY() || ''); 
								focusPendingStatus.current = 'controlling';
								initTimer();
							}} 
							controller  = { onCalendarController }
						/>
					</div>
				}
			</InPortal>	
        </div>
    )
}

// Calendar Component
export function CalendarEasy<T extends IDateFormatEasy = Date>({ date, onChange, onCreate, minDate, maxDate, specialDays, style, controller }: ICalendarEasy<T>) {
	const containerRef 						= useRef<HTMLDivElement>(null);
	const argDate							= useRef(date);
	const [selectedDate, setSelectedDate] 	= useState<string>(getStringDate(date));
	const [currentDate, setCurrentDate] 	= useState<string>(getStringDate(date || new Date()));
	const today 							= (new Date()).DDMMYYYY();
	const firstOnChange						= useRef(true);

	const minDt					= useRef(new Date().setDDMMYYYY(getStringDate(minDate) || '01.01.1000').getTime());
	const maxDt					= useRef(new Date().setDDMMYYYY(getStringDate(maxDate) || '01.01.9999').getTime());

	const curDT = new Date();
 	curDT.setDDMMYYYY(currentDate); 

	const sdStr	= useMemo<{dt: string, name?: string}[]>(() => {
		return specialDays && specialDays.map(v => ({ dt: getStringDate(v.dt), name: v.name })) || DefaultSpecialDaysEasy
	}, [specialDays]);

	useEffect(() => {
		setCurrentDate(selectedDate || today);
		if (!firstOnChange.current && onChange) {
			const newDt = selectedDate && (new Date).setDDMMYYYY(selectedDate) || null;
			onChange(newDt, selectedDate, newDt && {day: newDt.getDay(), month: newDt.getMonth(), year: newDt.getFullYear()});
		}
		firstOnChange.current  = false;
	}, [selectedDate]);

	useEffect(() => {
		setSelectedDate(getStringDate(date));
	}, [getStringDate(date)]);

	useEffect(() => onCreate && onCreate(), []);

	const renderCalendar = useMemo(() => {
		const curDT = new Date();
		curDT.setDDMMYYYY(currentDate);
		const year = curDT.getFullYear();
		const month = curDT.getMonth();
		const firstMonthDate = new Date(year, month, 1);
		const daysInMonth = new Date(year, month + 1, 0).getDate();
		const firstDayOfMonth = firstMonthDate.getDay() || 7;
		const lastDayOfMonth = new Date(year, month, daysInMonth).getDay() || 7;

		const calendarRows: JSX.Element[] = [];
		let dayCount = 1;

		const monday = new Date(firstMonthDate);
		monday.setDate(monday.getDate() - firstDayOfMonth + 1);
		for (let i = 0; i < 6; i++) {
			const calendarCells: JSX.Element[] = [];
			calendarCells.push(<td key={-1} className="ordNum">{(i && monday || firstMonthDate).getWeekNumber()}</td>);
			monday.setDate(monday.getDate() + 7);
			for (let j = 0; j < 7; j++) {
				if (i === 0 && j < firstDayOfMonth - 1) {
					const prevMonthDay = new Date(year, month, 0).getDate() - firstDayOfMonth + j + 2;
					const cellType = (j === 5 || j === 6) ? "weekend" : "";
					calendarCells.push(<td 
						key={j} 
						className="out-of-month"
						{ ...(cellType && {[cellType]: ''} || {}) }
					>{prevMonthDay}</td>);
				} else if (dayCount > daysInMonth) {
					const nextMonthDay = dayCount - daysInMonth;
					const cellType = (j === 5 || j === 6) ? "weekend" : "";
					calendarCells.push(<td 
						key={j} 
						className="out-of-month"
						{ ...(cellType && {[cellType]: ''} || {}) }
					>{nextMonthDay}</td>);
					dayCount++;
				} else {
					const cellDate = new Date(year, month, dayCount);
					const dateInRange = minDt.current <= cellDate.getTime() && cellDate.getTime() <= maxDt.current; 
					const cellType = !dateInRange ? 'outofrange' : (j === 5 || j === 6) ? 'weekend' : '';
					const formattedDate = cellDate.DDMMYYYY();
					const specialDay = sdStr.find(v => v.dt === formattedDate);
					calendarCells.push(
						<td
							key={j}
							onMouseDown={() => dateInRange && handleCellClick(formattedDate)}
							{ ...(cellType && {[cellType]: ''} || {}) }
							{ ...(selectedDate === formattedDate ? { ['selecteddate']: '' } : {}) }
							{ ...(today === formattedDate ? { ['today']: '' } : {}) }
							{ ...(specialDay ? { ['specialday']: ''} : {}) }
						>
							{ (specialDay || today === formattedDate) && <TooltipHost content={(today === formattedDate ? 'Today'  + (specialDay?.name ? ' | ' : '') : '') + (specialDay?.name || '')}>{dayCount}</TooltipHost> || dayCount}
						</td>
					);
					dayCount++;
				}
			}
			calendarRows.push(<tr key={i}>{calendarCells}</tr>);
			if (dayCount > daysInMonth && (i >= 4 || (i === 3 && lastDayOfMonth === 6))) {
				break;
			}
		}
		return calendarRows;
	}, [currentDate]);

	const handleCellClick = (dateString: string) => {
		const newDate = new Date();
		newDate.setDDMMYYYY(dateString);
		setSelectedDate(newDate.DDMMYYYY());
	};

	const prevYear = () => {
		const newDate = new Date();
		newDate.setDDMMYYYY(currentDate).setDate(1);
		newDate.setFullYear(newDate.getFullYear() - 1);
		setCurrentDate(newDate.DDMMYYYY());
	};

	const prevMonth = () => {
		const newDate = new Date();
		newDate.setDDMMYYYY(currentDate).setDate(1);
		newDate.setMonth(newDate.getMonth() - 1);
		setCurrentDate(newDate.DDMMYYYY());
	};

	const nextMonth = () => {
		const newDate = new Date();
		newDate.setDDMMYYYY(currentDate).setDate(1);
		newDate.setMonth(newDate.getMonth() + 1);
		setCurrentDate(newDate.DDMMYYYY());
	};

	const nextYear = () => {
		const newDate = new Date();
		newDate.setDDMMYYYY(currentDate).setDate(1);
		newDate.setFullYear(newDate.getFullYear() + 1);
		setCurrentDate(newDate.DDMMYYYY());
	};

	const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {		
		event.deltaY > 0 && nextMonth();
		event.deltaY < 0 && prevMonth();
	};	
	
	const handleWheelWrapper = (event: Event) => { event.preventDefault(); };

	useEffect(() => {
		containerRef.current?.addEventListener('wheel', handleWheelWrapper, { passive: false });
		return () => { containerRef.current?.removeEventListener('wheel', handleWheelWrapper); };
	}, []);

	return (
		<div
			ref			= { containerRef }
			className	= "calendareasy"
			onWheel		= { handleWheel }
			style		= { style }	
		>
			<div className="header">
				<div className="arrows left">
					<FontIcon iconName="DoubleChevronLeft" onMouseDown={() => { prevYear(); controller && controller(); }} style={{marginRight: '6px', marginLeft: '-6px'}}/>
					<FontIcon iconName="ChevronLeft" onMouseDown={() => { prevMonth(); controller && controller(); }} />
				</div>
				<span className="currentMonth">{ `${monthNames[curDT.getMonth()]} ${curDT.getFullYear()}` }</span>
				<div className="arrows right">
					<FontIcon iconName="ChevronRight" onMouseDown={() => { nextMonth(); controller && controller(); }} />
					<FontIcon iconName="DoubleChevronRight" onMouseDown={() => { nextYear(); controller && controller(); }} style={{marginRight: '-6px', marginLeft: '6px'}}/>
				</div>
			</div>
			<table>
				<thead>
					<tr>
						<th className="ordNum">&nbsp;</th>
						<th>Mo</th>
						<th>Tu</th>
						<th>We</th>
						<th>Th</th>
						<th>Fr</th>
						<th className="weekend">Sa</th>
						<th className="weekend">Su</th>
					</tr>
				</thead>
				<tbody>
					{renderCalendar}
				</tbody>
			</table>
			<div className="calendarFooter">
				{ (currentDate.substring(3, 24) !== today.substring(3, 24)) &&
				<div
					className	= "goToDate selectToday"
					onMouseDown 	= { () => { 
						setCurrentDate(today);
						controller && controller(new Date()); 
					}}
				>Today</div>
	}
				{selectedDate && selectedDate.substring(3) !== currentDate.substring(3) && <div
					className	= "goToDate goToSelectedDate"
					onMouseDown = { () => { 
						setCurrentDate(selectedDate);
						controller && controller((new Date()).setDDMMYYYY(selectedDate)); 
					}}
				>{selectedDate}</div>}
			</div>
		</div>
	);
}

export const monthNames = [
	"January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
];
