import { FontIcon, TooltipHost } from "@fluentui/react";
import { useStore } from 'effector-react';
import { useContext, useMemo, useState } from "react";
import { createActiveStore } from "../../state/ActiveStore";
import * as Auth from "../../utils/auth";
import { LoginContext } from './LoginContext';
import { doCreateOrganization, doJoinOrganization, doUserProfileSave } from './LoginUtils';
// import { t } from "../../models/platformitems";
import { t } from "../../locale";
import { SearchOption, SuggestionBox } from "../../components/AppComponents/Suggestion/SuggestionBox";

interface IAccountVerifiedFormData {
    firstName: string,
    surname: string,
    country: string,
    countryId: number,
    organization: string,
}

interface ICountry {
    id: number,
    name: string,
}

export function AccountVerified() {

    const context = useContext(LoginContext)!;

    const org = Auth.getInvitationOrg() || '';

    const formData = useMemo(() => {
        return createActiveStore<IAccountVerifiedFormData>({
            firstName: context.firstNameProvided,
            surname: context.lastNameProvided,
            country: 'Germany',    
            countryId: 82,   
            organization: org,
        });
    }, []);

    const _formData = useStore(formData.store);

    const isFormComplete = () => {
        return _formData.firstName && _formData.surname && _formData.country && _formData.organization;
    };

    const [orgName, setOrgName] = useState<string>(org);


    const handleFinishClick = () => {
        if (isFormComplete()) {
            doUserProfileSave(
                    _formData.firstName,
                    _formData.surname,
                    _formData.countryId,
                    context.language.get(),
                    context.emailProvided)
            .then(result => {
                if (result) {
                    if (_formData.organization && org === '') {
                        doCreateOrganization(_formData.organization)
                        .then(res => {
                            res && context.isAuthorized.set(true);
                        })
                    } else {
                        doJoinOrganization(_formData.organization)
                        .then(res => {                        
                            res && context.isAuthorized.set(true);
                        })
                    }
                }
            });
        } else {

        }
    };

    const countries = useMemo(() => {
        return (Auth.getCountries()?.items as ICountry[])?.map<SearchOption>(country => ({ key: country.id.toString(), mainValue: country.name })) || []
    }, [Auth.getCountries()?.items?.length])

    return(<>
        <div className="signInLabel">{t('%Account verified%')}</div>

        {t('%Complete your profile to get started%')}

        <div className="vMargin15"></div>

        <div className="newTo" style={{ paddingTop: '1.5em' }}></div>

        <div className="font12 w500 left">{t('%My profile%')}</div>

        <div className="vMargin12"></div>

        <input 
            type="text"
            defaultValue={context.firstNameProvided}
            className="editField grayBackground" 
            placeholder={t('%First name%')} 
            onChange={e => formData.set({..._formData, firstName: e.target.value})}
        />

        <div className="vMargin15"></div>

        <input 
            type="text"
            defaultValue={context.lastNameProvided}
            className="editField grayBackground" 
            placeholder={t('%Last name%')} 
            onChange={e => formData.set({..._formData, surname: e.target.value})}
        />

        <div className="vMargin15"></div>

        <SuggestionBox 
            initKey={_formData.countryId.toString()}
            initValue={_formData.country}
            placeholder={t('%Country%')} 
            onChange={(key, mainValue) => formData.set({..._formData, country: mainValue || '', countryId: key && parseInt(key) || 0})}
            list={ { options: countries, maxSuggestionsCount: 5 } }
        />

        <div className="vMargin15"></div>

        <div className="font12 w500 left">
            {t('%Organization%')}&nbsp;&nbsp;
            <TooltipHost 
                content={t("%This is your organization's account. As the first Administrator, please enter the display name of your organization. You can change it later in the Organization Profile%")}
            ><FontIcon iconName="Info" className="link" style={ { fontSize: '0.8em' } } /></TooltipHost>
        </div>

        <div className="vMargin10"></div>

        <input 
            type="text"
            defaultValue={orgName}
            disabled={orgName !== ''}
            className="editField grayBackground"
            placeholder={t('%Organization%')}
            onChange={e => formData.set({..._formData, organization: e.target.value})}
        />

        <div className="vMargin15"></div>
        <div className="vMargin10"></div>

        <div 
            className={`signButton ${isFormComplete() ? 'enabled' : ''}`} 
            onClick={handleFinishClick}
        >
            {t('%Finish%')}
        </div>
    </>);
}
