import { TooltipHost } from "@fluentui/react";
import { useStore } from "effector-react";
import { CSSProperties, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { t } from "../../../locale";
import { SyncState, createDataProvider, createSyncState } from "../../../state/SyncStore";
import { cAttr } from "../../../utils/helpers";
import { cssProp } from "../../../utils/utils";
import { IconButton } from "../../BCComponents/Controls/Buttons/IconButton/IconButton";
import { DCell, HCell, RData, RHeader, RRow, RTable } from "../../BCComponents/Controls/Grids/RTable";
import { Icons } from "../../BCComponents/Controls/SVGIcons/icons";
import { Header, HeaderButtonsInContainer, IHeaderBackButton, IHeaderButton } from "../../BCComponents/Layouts/Layouts";
import { Panel, VPanel, Vertical } from "../../BCComponents/Layouts/Panels/ArrangementPanels";
import { FlatControlsPanel } from "../../BCComponents/Layouts/Panels/ContolsPanel";
import { FlatLeftPanel } from "../../BCComponents/Layouts/Panels/LeftPanel";
import { FlatWidePanel } from "../../BCComponents/Layouts/Panels/StretchedPanels";
import { ITabPage, PageId, TabView } from "../../BCComponents/Layouts/TabView/TabView";
import { useListen } from "../../BCComponents/Messages";
import { getURLParam, navigateCommand, navigateParam } from "../../BCComponents/Navigation";
import { tableMessages } from "../../BCComponents/bcGlobals";
import { Paginator } from "../Paginator/Paginator";
import { GridURL, SyncStateURL, navigateInOrg } from "../funcs";
import { GridName, IGridMetaData, IURLAction, ModalAppearance, ServiceRestDomains } from "../types";
import './Grider.css';
import { applyHrefParams, deepCopy, iconMap, requireGrid } from "./griderUtils";

enum GriderDefaultActions {
    'export',
    'reload'
}

interface IGridRowData { [key: string]: string }

export interface IGriderSpecialAction {
    icon?: Icons,
    label: string,
    name: string,
    classes?: string,
    style?: CSSProperties, 
    onClick?: (action: string, griderName: string, event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void,
}

export type OnGriderDeafultAction = (name: GridName, action: string, appearance?: ModalAppearance) => void;
export type OnGriderAction = (action: string, defaultAction: OnGriderDeafultAction) => void;

export interface IGrider {
    content: GridName | string | { uid: string, element: JSX.Element },
    uid?: string, 
    label: string, 
    idField?: string, 
    onAction?: OnGriderAction,
    appearance?: ModalAppearance,
    specialActions?: IGriderSpecialAction[],
    serviceDomain?: ServiceRestDomains,
    module?: string,
    params?: Record<string, any>,
    noActions?: boolean,
    children?: ReactNode | ReactNode[],
    onRowClick?: (grid: GridName, id: number) => void,
}

export type IGriderTabs = { tabs: IGrider[], activeTab?: PageId, children?: ReactNode | ReactNode[] };

export interface IGriderWithBackButton extends IGrider {
    backButton?: IHeaderBackButton
} 

export type IGriders = IGriderWithBackButton | IGriderTabs;

const dataProvider = createDataProvider();

export function Grider(props : IGriders) {
    const tabs = (props as IGriderTabs).tabs; 
    const children = (props as IGriderTabs).children; 
    return (tabs && Array.isArray(tabs)) && <MultiGrider tabs={tabs} children={children} /> || <SingleGrider {...(props as IGrider)} withHeader={true} />;
}

export function griderTab({name, label, onAction, specialActions, serviceRestDomain, onRowClick} : { name: GridName, label: string, onAction?: OnGriderAction, specialActions?: IGriderSpecialAction[], serviceRestDomain?: ServiceRestDomains, onRowClick?: (grid: GridName, id: number) => void} ): IGrider {
    return {
        content: name, 
        label: t(`%${label}%`), 
        onAction: onAction,
        specialActions: specialActions,
        serviceDomain: serviceRestDomain,
        onRowClick: onRowClick,
    }
}

let griderDefaultAction: OnGriderDeafultAction = (name: GridName, action: string, appearance?: ModalAppearance) => {
    const splitted = action.split('.');
    if (splitted[splitted.length - 1]?.toUpperCase() === 'NEW') { 
        const action: IURLAction = {
            name: name,
            appearance: appearance,
            method: 'add',
        }
        navigateCommand('grider', action, true);
    } else if (action === 'Action.User.Invite') { 
        const action: IURLAction = {
            name: 'memberships',
            appearance: appearance,
            method: 'add',
        }
        navigateCommand('grider', action, true);
    }
};

export function SetGriderDefaultOnAction(func: OnGriderDeafultAction) {
    griderDefaultAction = func;
}

function MultiGrider({ tabs, children } : IGriderTabs) {
    const tabPanelSetter = useRef<{ method: ((controlsPanel: JSX.Element) => void) | null }>({method: null});
    const griderButtons  = useMemo<Record<string, IHeaderButton[]>>(() => ({}), []);

    function getTabUID(tab: IGrider) {
        return typeof tab.content === "object" && tab.content.uid || tab.content as GridName;
    }

    return (
        <TabView 
            pages={tabs.map<ITabPage>(tab => ({
                    id: getTabUID(tab), 
                    label: tab.label
            }))}
            activeTab={ getURLParam('tab') }
            onChange={ (source, target, setControlPanel) => {
                tabPanelSetter.current.method = setControlPanel;
                griderButtons[target.id] && setControlPanel(<HeaderButtonsInContainer buttons={griderButtons[target.id]} />);
                navigateParam('tab', target.id.toString(), true, '#');
            }}
        >
            { 
                tabs.map((tab, index) => (typeof tab.content === "object") && tab.content.element || 
                                         <SingleGrider 
                                            key={index}
                                            {...tab} 
                                            children={children}
                                            griderButtons={(name: string, buttons: IHeaderButton[]) => { 
                                                griderButtons[getTabUID(tab)] = buttons;
                                                if (name === getURLParam('tab'))
                                                    tabPanelSetter.current.method && tabPanelSetter.current.method(<HeaderButtonsInContainer buttons={buttons} />); 
                                            }} />)
            }
        </TabView>
    )
}

const defaultPageSize = 25;

function SingleGrider({content, label, uid, params, idField, children, onAction, noActions, withHeader, griderButtons, specialActions, backButton, module, serviceDomain, onRowClick, appearance  = 'side'} : IGriderWithBackButton & { withHeader?: true, appearance?: ModalAppearance, onRowClick?: (grid: GridName, id: number) => void, griderButtons?: (tabName: string, buttons: IHeaderButton[]) => void }) {
    const name = content as GridName;
    const gridDataStore = useMemo(() => createSyncState<IGridRowData[], IGridRowData>({
        uid: uid || name,
        request: dataProvider.new({ urlTemplate: SyncStateURL(name, undefined, serviceDomain) }),
        idValue: (item) => item[idField || 'ID'],
    })!, []);
    const gridData = useStore(gridDataStore.store);

    const msg = useListen<{ lastAffectedId?: number, operationCanceled?: boolean }>(tableMessages.rowAffected, name);

    const [affecting, setAffecting] = useState<{editingNow?: number, lastEdited?: number}>({editingNow: -1, lastEdited: -1});

    const gridMetaDataRef = useRef<IGridMetaData | null>(null);
    const gridMetaData = gridMetaDataRef.current;
    let visibleColumns = gridMetaData?.columns.filter(column => !column.hidden);

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        const lastId = msg?.message?.lastAffectedId;
        (!msg || !msg.message || !msg.message.operationCanceled || lastId) ?
            requireGrid(GridURL(name, serviceDomain, module), params, pageNumber, defaultPageSize)
            .then(res => {
                gridMetaDataRef.current = deepCopy<IGridMetaData>(res.data, [], 10);
                lastId && setAffecting({ lastEdited: lastId }); 
                extractGridData(res.data, gridDataStore);
                msg && (msg.message = {});
            })
            .catch(reason => console.log(reason)) : setAffecting({ lastEdited: lastId });
            msg && (msg.message = {});
    }, [name, msg, pageNumber]);

    useEffect(() => {
        gridMetaData && griderButtons && griderButtons(name, buttons());
    }, [gridMetaData]);

    function onActionClick(action: string) {
        onAction ? 
        onAction(action, (callBackAction) => griderDefaultAction && griderDefaultAction(name, callBackAction, appearance)) :
        griderDefaultAction && griderDefaultAction(name, action, appearance);
    }

    function buttons() {
        if (!gridMetaData) return [];
        const mainButtons = gridMetaData.gridActions?.map<IHeaderButton>((action, index) => ({
            icon: iconMap(gridMetaData!.action![action].icon.name),
            classes: index === 0 && "red rounded" || 'rounded',
            label: t(`%${gridMetaData!.action![action].title}%`),
            onClick: () => onActionClick(action),
        })) || [];      
        return [...mainButtons, ...(specialActions?.map<IHeaderButton>(a => ({...a, onClick: e => a.onClick && a.onClick(a.name, name, e) })) || [])];
    }

    function onRowAction(action: string, row: IGridRowData, rowIndex: number) {
        gridMetaData!.action && gridMetaData!.rowIds && gridMetaData!.action[action].directCall?.method === 'DELETE' && 
        gridDataStore.setParams({ID: gridMetaData!.rowIds[rowIndex]}).del(row);

        const splitted = action.split('.');

        if (splitted[splitted.length - 1]?.toUpperCase() === 'EDIT' && gridMetaData!.rowIds) { 
            const action: IURLAction = {
                name: name,
                method: 'edit',
                appearance: appearance,
                params: {ID: gridMetaData!.rowIds[rowIndex]},
            }
            setAffecting({ editingNow: gridMetaData!.rowIds[rowIndex] })
            navigateCommand('grider', action, true);
        }

        if (splitted[splitted.length - 1]?.toUpperCase() === 'CHANGEROLE' && gridMetaData!.rowIds && gridMetaData!.rows) { 
            const action: IURLAction = {
                name: name,
                method: 'add',
                appearance: appearance,
                params: {ID: gridMetaData!.rowIds[rowIndex], Role: gridMetaData!.rows[rowIndex][4] },
            }
            setAffecting({ editingNow: gridMetaData!.rowIds[rowIndex]})
            navigateCommand('grider', action, true);
        }
    }

    const actionsPresented = gridMetaData?.rowActions?.some(rowActionsSet => rowActionsSet?.length);

    return gridMetaData && visibleColumns && 
        <Vertical style={{ width: '100%' }}>

            {withHeader && <Header 
                label   = { label }
                classes = "griderHeader" 
                buttons = { buttons() }
                back={ backButton }
            />}

            <VPanel classes="griderContent">
                <FlatWidePanel classes="griderViewControls">
                    <FlatLeftPanel classes="rowsCount">{t('%Rows count%')}: { gridMetaData.paginationType === 'server' ? (gridMetaData.totalCount || '?') : gridData.length }</FlatLeftPanel>
                </FlatWidePanel>

                <Panel width="100%" height="100%" classes="thinScrollbar" style={{ flexGrow: 1 }}> 
                    <RTable>
                        <RHeader>{[
                            ...(visibleColumns.map((column, index) => 
                                <HCell key={ index }>
                                    {t(`%${column.caption || column.title}%`)}
                                </HCell>
                            )),
                            ...(!noActions && actionsPresented && [<HCell key="Actions">
                                {t("%Actions%")}
                            </HCell>] || [])
                        ]}</RHeader>

                        <RData>{ 
                            gridData.map((row, rowIndex) =>  
                                <RRow 
                                    classes={`${row['ID'] === affecting.editingNow?.toString() && 'editingNow' || row['ID'] === affecting.lastEdited?.toString() && 'lastEditedRow' || ''}`} 
                                    onClick={ (event) =>  onRowClick && onRowClick(name, parseInt(row['ID'])) } 
                                    key={ rowIndex }
                                >{[
                                    ...(visibleColumns!.map((column, colIndex) => 
                                        <DCell 
                                            key={ colIndex } 
                                            classes={ (column.href ? 'ref' : '') + (column.bgColor ? ' colorMark' : '').trim() } 
                                            onClick={(e) => { if (column.href) {
                                                const target = applyHrefParams(column.href, row);  
                                                target && navigateInOrg(target);
                                                e.stopPropagation(); 
                                            }}}
                                            style={ column.bgColor && {...cssProp('--colorMark', column.bgColor, row[column.bgColor])} || {} }
                                            { ...cAttr(column.bgColor, 'colorMark') }
                                        >
                                            { row[column.name] || '' }
                                        </DCell>
                                    )), 
                                    ...(!noActions && actionsPresented && [<DCell key="Actions">
                                        {gridMetaData?.rowActions &&
                                        <FlatControlsPanel>{ gridMetaData.rowActions[rowIndex]?.map((action, actionIndex) => 
                                            gridMetaData.action &&
                                            <TooltipHost content={t(`%${ gridMetaData.action[action].title }%`)}> 
                                                <IconButton  
                                                    key={ actionIndex }
                                                    icon={ iconMap(gridMetaData.action[action].icon.name)}
                                                    onClick={(e) => { onRowAction(action, row, rowIndex); e.stopPropagation(); }} 
                                                />
                                            </TooltipHost>
                                        )}</FlatControlsPanel>}
                                    </DCell>] || [])
                                ]}</RRow>
                        )}</RData>
                    </RTable>
                    { gridData.length === 0 && children && <>{ children }</> }
                </Panel>
                { gridMetaData.paginationType === 'server' && <Paginator total={ gridMetaData.totalCount || 1 } maxPagesCount={5} pageSize={defaultPageSize} onChange={(page => setPageNumber(page) )} /> }
            </VPanel>
        </Vertical> || null;
}

function extractGridData(source: IGridMetaData, destination: SyncState<IGridRowData[], IGridRowData>) {
    const result: IGridRowData[] = [];
    let rowData: IGridRowData;
    source?.rows?.forEach(row => { 
        rowData = {}; 
        row.forEach((fieldValue, fieldIndex) => rowData[source.columns[fieldIndex].name] = fieldValue);
        result.push(rowData);
    });
    destination.applyData(result);
}