/**
 * Utility functions for various common operations in the application.
 */

/**
 * Checks if the given email string is valid.
 * 
 * @param email The email address to validate.
 * @returns true if the email is valid, false otherwise.
 */
export function isValidEmail(email: string) {
  return /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
}

export function isValidDomain(domain: string) {
  return /^(?!.*\.\.)([a-zA-Z0-9._-]+)$/.test(domain);
}


/**
* Generates initials from a given name and surname.
* 
* @param name First name of the person.
* @param surname Last name of the person.
* @returns A string containing the initials of the name and surname.
*/
export function getNameInitials(name?: string, surname?: string): string {
  return `${name ? name.charAt(0).toUpperCase() : ''}${surname ? surname.charAt(0).toUpperCase() : ''}`.trim();
}

/**
* Compares two arrays to check if they are equal.
* 
* @param a First array to compare.
* @param b Second array to compare.
* @returns true if arrays are equal, false otherwise.
*/
export const areEqualArrays = (a: any[], b: any[]): boolean => {
  if (a.length !== b.length) {
      return false;
  }

  for (let item of a) {
      if (!b.includes(item)) {
          return false;
      }
  }

  for (let item of b) {
      if (!a.includes(item)) {
          return false;
      }
  }

  return true;
};

/**
* Creates an attribute object based on the provided key and value.
* 
* @param key The attribute key.
* @param add Boolean flag to determine whether to add the attribute.
* @param value The value of the attribute.
* @returns An object with the key and value if add is true, otherwise an empty object.
*/
export function Attr(key: string, add: any, value?: string | number) {
  return add ? { [key]: value || '' } : {}
}

/**
* Alias for the Attr function, specifically used for CSS properties.
*/
export const cssProp = Attr;

export function cssVar(name: string, value: string | number) {
  return Attr(name, 1, value);
}

let em = -1;

export function calcEm(): number {
  if (em > 0) return em;
  const element = document.createElement('div');
  element.style.fontSize = '1em';
  document.body.appendChild(element);
  const fontSize = Math.round(parseFloat(window.getComputedStyle(element).fontSize));
  document.body.removeChild(element);
  em = fontSize;
  return fontSize;
}