import { Store } from "effector";
import React, { CSSProperties, ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { t } from "../../../locale";
import { RetrieveDataset } from "../../../utils/DataSets/DataSets";
import { SuggestionBox, SuggestionOption } from "../../AppComponents/Suggestion/SuggestionBox";
import { Checkbox } from "../../BCComponents/Controls/Checkbox/Checkbox";
import { ColorSelect } from "../../BCComponents/Controls/ColorSelect/ColorSelect";
import { DatePickerEasy } from "../../BCComponents/DatePickerEasy/DatePickerEasy";
import { FlatHPanel, FlatVPanel } from "../../BCComponents/Layouts/Panels/ArrangementPanels";
import { FlatWidePanel } from "../../BCComponents/Layouts/Panels/StretchedPanels";
import { numberAsFixed } from "../../BCComponents/utils";
import { getDDMMYYYY } from "../../../utils/helpers";
import { IModalTitle, ModalOnCreateEdit, ModalOnSubmitOrCancel, ModalValidate } from "../types";

export const DataSourceContext = createContext<Record<string, any>>({});

export interface IModalOperationContext {
    __fieldValidations: ModalValidate<any>[],
    __changeNotification: (name: string, dataRow: Record<string, any>) => void,

    userValidate: () => void;
    close: ModalOnSubmitOrCancel,
    submit: ModalOnSubmitOrCancel
    setTitle: (title: IModalTitle) => any;
    setSyncParams: (params: Record<string, any>) => void,

    onCreate?: ModalOnCreateEdit<any>,
    onEdit?: ModalOnCreateEdit<any>,
    onCancel?: ModalOnSubmitOrCancel,
    params?: Record<string, any>,
}

export const ModalOperationContext = createContext<Partial<IModalOperationContext>>({});

export interface IField {
    name: string;
    record?: string | number;
    label?: string;
    required?: boolean;
    children?: ReactNode | ReactNode[];
    after?:    ReactNode | ReactNode[];
    readOnly?: boolean;
    disabled?: boolean;
    classes?:  string;
}

export function FieldContainer({ label, required, children, classes }: Partial<IField> & { children: React.ReactNode | React.ReactNode[] }) {
    return (
        <FlatVPanel 
            width="100%" 
            style={{ ['--required']: `'${t('%required%')}'` } as CSSProperties} 
            classes={`fieldContainer${required ? ' required' : ''} ${classes || ''}`}
        >
            {label && <div className="fieldLabel">{t(`%${label || ''}%`)}</div>}
            {children}
        </FlatVPanel>
    )
}

export function StringField({ name, record, label, required, children, after, disabled, readOnly, placeholder }: IField & { placeholder?: string }) {
    const dataSet = useRef<Record<string, any>>({}); 
    dataSet.current = useContext(DataSourceContext) || {};
    (record || record === 0) && (dataSet.current[record] || (dataSet.current[record] = {})) && (dataSet.current = dataSet.current[record]);

    const [_, setValue] = useState<string>(dataSet.current?.[name]);

    const operation = useContext(ModalOperationContext);
    useMemo(() => {
        const validate = () => !required || (dataSet.current?.[name] ? dataSet.current?.[name]?.trim() !== '' : false);

        operation.__fieldValidations?.push(validate);
        return () => { 
            const index = operation.__fieldValidations?.findIndex(validate) || -1; 
            index > -1 && operation.__fieldValidations?.splice(index, 1); 
        }
    }, [operation]);

    const input = <input type="text" placeholder={placeholder} disabled={disabled} readOnly={readOnly} className="editField" value={dataSet.current?.[name] || ''} onChange={e => {
        setValue(e.target.value);
        dataSet.current && (dataSet.current[name] = e.target.value);
        operation.__changeNotification && operation.__changeNotification(name, dataSet.current)
    }} />;

    return <FieldContainer {...{ label, required }}>
        {(children || after) && <FlatHPanel width="100%" verticalAlign="center">
            {children}
            {input}
            {after}
        </FlatHPanel> || input}
    </FieldContainer>
}

export function NumberField({ name, record, label, required, children, decimals = 0, disabled, readOnly, classes }: IField & { decimals?: number }) {
    const dataSet = useRef<Record<string, any>>({}); 
    const originalDataset = dataSet.current = useContext(DataSourceContext) || {};
    (record || record === 0) && (dataSet.current[record] || (dataSet.current[record] = {})) && (dataSet.current = dataSet.current[record]);

    const initValue = numberAsFixed(dataSet.current?.[name], decimals)
    const [value, setValue] = useState<string>(initValue);

    useEffect(() => setValue(initValue), [initValue]);

    const operation = useContext(ModalOperationContext);
    useMemo(() => {
        operation.__fieldValidations?.push(() => { return !required || typeof dataSet.current?.[name] === 'number' })
    }, [operation]);

    const invalidStyle = isNaN(parseFloat(value)) ? { color: 'red' } as CSSProperties : undefined;

    const input = <input 
        type        = "text"
        readOnly    = { readOnly } 
        disabled    = { disabled } 
        className   = "editField" 
        value       = { value }
        style       = { invalidStyle }
        onChange    = { e => {
            const number = parseFloat(e.target.value);
            setValue(e.target.value);
            dataSet.current && (dataSet.current[name] = !isNaN(number) ? number : undefined);
            operation.__changeNotification && operation.__changeNotification(name, originalDataset);
        }}
    />

    return <FieldContainer {...{ label, required, classes }}>
        {children && <FlatHPanel width="100%" verticalAlign="center">
            { children }
            { input }
        </FlatHPanel> || input}
    </FieldContainer>
}

export function LabelField({ name, record, text, label, required, refBook, source, type, after, noData, classes, stateUID }: IField & { stateUID?: string, text?: string, noData?: ReactNode, source?: Record<string, any>[], refBook?: IRefBookSource, type?: 'string' | 'number' | 'bigint' | 'object' | 'date'}) {
    const dataSet = useRef<Record<string, any>>({});
    const originalDataset = dataSet.current = useContext(DataSourceContext) || {};
    (record || record === 0) && (dataSet.current[record] || (dataSet.current[record] = {})) && (dataSet.current = dataSet.current[record]);
    const valueType = type || typeof dataSet.current?.[name];

    const fieldValue = useRef(dataSet.current?.[name]);
    const [isReady, setIsReady] = useState(false);
    const color = useRef('');

    useEffect(() => {
        fieldValue.current = (!refBook) ? (
            (valueType === 'undefined' || fieldValue.current === null) ? '' :
                (valueType === 'number' || valueType === 'bigint' || valueType === 'boolean' || valueType === 'string') ? fieldValue.current :
                    Array.isArray(fieldValue.current) ? fieldValue.current.map(item => typeof item === 'object' ? getItemLabel(item) : item.toString()).join(', ') :
                        valueType === 'date' ? getDDMMYYYY(fieldValue.current) :
                            valueType === 'object' ? getItemLabel(fieldValue.current) : ''
        ) : '';

        refBook &&
            RetrieveDataset<ILookupFieldListItem>({
                source: refBook.refBookName,
                sliceId: refBook.sliceId,
                params: refBook.params,
                uid: stateUID,
                sourceDetalization: refBook.detalization,
                serviceDomain: refBook.serviceDomain,
                searchParams: refBook.searchParams,
                cached: refBook.cached,
            }).then(result => {
                const arr = Array.isArray(result?.dataSet) && result?.dataSet ||
                    typeof result?.dataSet === 'object' && objectAsRecordArray(result?.dataSet) || [];
                setLabelValue(arr);
            });

        function setLabelValue(array: Record<string, any>[]) {
            const dataRow = array.find(el => el.id === dataSet.current?.[name]) as ILookupFieldListItem
            fieldValue.current = getItemLabel(dataRow);
            color.current = dataRow && (dataRow.backgroundColor || dataRow.color) || '';
            setIsReady(true);
        }

        !refBook && source && setLabelValue(source);

        !refBook && !source && setIsReady(true);   

    }, [dataSet.current?.id]);

    return <FieldContainer {...{ label, required, classes }}>
        {isReady && dataSet.current &&
            <FlatHPanel verticalAlign="center">
                {color.current && <div className="colorCircle" style={{ backgroundColor: color.current }} />}
                <span>
                    { text || (text === '' && ' ') || fieldValue.current || noData || ''}{ after }
                </span>
            </FlatHPanel>}
    </FieldContainer>
}

export function TextField({ name, record, label, required, disabled, readOnly }: IField) {
    const dataSet = useRef<Record<string, any>>({}); 
    const originalDataset = dataSet.current = useContext(DataSourceContext) || {};
    (record || record === 0) && (dataSet.current[record] || (dataSet.current[record] = {})) && (dataSet.current = dataSet.current[record]);

    const [_, setValue] = useState<string>(dataSet.current?.[name]);

    const operation = useContext(ModalOperationContext);
    useMemo(() => { operation.__fieldValidations?.push(() => !required || (dataSet.current?.[name] ? dataSet.current?.[name]?.trim() !== '' : false)) }, [operation]);

    return <FieldContainer {...{ label, required }}>
        <textarea value={dataSet.current?.[name] || ''} disabled={disabled} readOnly={readOnly} className="textField thinScrollbar" rows={3} onChange={e => {
            setValue(e.target.value);
            dataSet.current && (dataSet.current[name] = e.target.value);
            operation.__changeNotification && operation.__changeNotification(name, originalDataset);
        }} />
    </FieldContainer>
}

export function BooleanField({ name, record, label, required }: IField) {
    const dataSet = useRef<Record<string, any>>({}); 
    const originalDataset = dataSet.current = useContext(DataSourceContext) || {};
    (record || record === 0) && (dataSet.current[record] || (dataSet.current[record] = {})) && (dataSet.current = dataSet.current[record]);

    const operation = useContext(ModalOperationContext);
    useMemo(() => { operation.__fieldValidations?.push(() => !required || dataSet.current?.[name]) }, [operation]);

    return <FieldContainer {...{ required }}>
        <Checkbox defaultValue={dataSet.current?.[name]} label={label && t(`%${label}%`) || ''} onClick={checked => {
            dataSet.current && (dataSet.current[name] = checked);
            operation.__changeNotification && operation.__changeNotification(name, originalDataset);
        }} />
    </FieldContainer>
}

export function DateField({ name, record, label, required, type, disabled, readOnly }: IField & { type?: 'yyyy-mm-dd' | 'unix' }) {
    const dataSet = useRef<Record<string, any>>({});
    const originalDataset = dataSet.current = useContext(DataSourceContext) || {};
    (record || record === 0) && (dataSet.current[record] || (dataSet.current[record] = {})) && (dataSet.current = dataSet.current[record]);
    const typeOfValue = useRef('');
    typeOfValue.current = (type === 'unix' && 'number' || type === 'yyyy-mm-dd' && 'string') || typeof dataSet.current?.[name];

    const operation = useContext(ModalOperationContext);
    useMemo(() => operation.__fieldValidations?.push(() => {
        return !required || (
            typeOfValue.current === 'number' && dataSet.current?.[name] ||
            typeOfValue.current === 'string' && dataSet.current?.[name] && dataSet.current?.[name]?.trim() !== '') || false
    }
    ), [operation, typeOfValue.current]);

    const date = dataSet.current?.[name] && (typeOfValue.current === 'number' ? new Date(dataSet.current?.[name] * 1000).DDMMYYYY() :
    typeOfValue.current === 'string' && (new Date()).setYYYY_MM_DD(dataSet.current?.[name]).DDMMYYYY()) || undefined

    return <FieldContainer {...{ label, required }}>
        <DatePickerEasy<string>
            key = { date || 0 }
            date={ date }

            onChange={date => {
                dataSet.current && (dataSet.current[name] = typeOfValue.current === 'number' ? Math.round((date?.getTime() || 0) / 1000) : date?.YYYY_MM_DD());
                !dataSet.current?.[name] && (delete dataSet.current?.[name]);
                operation.__changeNotification && operation.__changeNotification(name, originalDataset);
            }} />
    </FieldContainer>
}


export function ColorField({ name, record, label, required, colors }: IField & { colors?: { id: any, color: string, selectable?: boolean }[] }) {
    const dataSet = useRef<Record<string, any>>({});
    
    const originalDataset = dataSet.current = useContext(DataSourceContext) || {};
    (record || record === 0) && (dataSet.current[record] || (dataSet.current[record] = {})) && (dataSet.current = dataSet.current[record]);

    const operation = useContext(ModalOperationContext);
    useMemo(() => { operation.__fieldValidations?.push(() => !required || (dataSet.current?.[name] ? dataSet.current?.[name]?.trim() !== '' : false)) }, [operation]);

    return <FieldContainer {...{ label, required, classes: 'thinScrollbar' }}>
        <ColorSelect 
            colorItems      = {colors}
            classes         = "thinScrollbar" 
            defaultValues   = {dataSet.current?.[name] && [dataSet.current?.[name]] || []} 
            onSelect        = {item => {
                dataSet.current && (dataSet.current[name] = item.id);
                operation.__changeNotification && operation.__changeNotification(name, originalDataset);
            }}
        />
    </FieldContainer>
}

export interface ILookupFieldListItem {
    id: any,
    name?: string,
    label?: string,
    title?: string,
    value?: string,
    text?: string,
    detailedInfo?: string,
    color?: string,
    backgroundColor?: string,
    level?: number,
}

export function getItemLabel(item?: ILookupFieldListItem): string {
    const value = item?.name || item?.label || item?.title || item?.value || item?.text;
    if (typeof value === 'object') return getItemLabel(value);
    return value || '';
}

export type LookupFieldList = ILookupFieldListItem[];

type RefBookName = string;

export interface IRefBookSource {
    refBookName: RefBookName,
    detalization?: string[],
    sliceId?: number,
    params?: Object,
    serviceDomain?: string,
    searchParams?: Object,
    cached?: boolean,
}

export interface ILookupRemoteCall {
    search: { path: string, params?: Object },
    initValueSource: IRefBookSource | Store<any> | Record<string, any>[] | Record<string, any>
}

const LookupFieldMaxSuggestionsCount = 10;

function objectAsRecordArray(obj: Object): ILookupFieldListItem[] {
    return Object.keys(obj).map(key => ({ id: parseInt(key), value: (obj as any)[key] } as ILookupFieldListItem));
}

export function LookupField({ name, record, label, source, preferred = [], required, maxCount, disabled }: IField & { source?: LookupFieldList | ILookupRemoteCall | IRefBookSource, preferred?: string[], maxCount?: number }) {
    const dataSet = useRef<Record<string, any>>({});
    const originalDataset = dataSet.current = useContext(DataSourceContext) || {};
    (record || record === 0) && (dataSet.current[record] || (dataSet.current[record] = {})) && (dataSet.current = dataSet.current[record]);

    const isArray = Array.isArray(source) || ((source as IRefBookSource).refBookName && true);
    const [isReady, setIsReady] = useState(false);
    const initValue = useRef<string | undefined>(undefined);
    const refList = useRef<LookupFieldList | null>(null);

    const operation = useContext(ModalOperationContext);
    useMemo(() => { 
        const validate = () => !required || (dataSet.current?.[name] ? dataSet.current?.[name]?.toString().trim() !== '' : false); 
        operation.__fieldValidations?.push(validate);
        return () => { 
            const index = operation.__fieldValidations?.findIndex(validate) || -1; 
            index > -1 && operation.__fieldValidations?.splice(index, 1); 
        }
    }, [operation]);    

    useEffect(() => {
        if ((isArray && Array.isArray(source)) || !source) {
            refList.current = source as LookupFieldList;
            setIsReady(true);
            return;
        };

        if ((source as ILookupRemoteCall).initValueSource) {
            const initSource = source as ILookupRemoteCall;
            if (Array.isArray(initSource.initValueSource)) {
                initValue.current = getItemLabel(initSource.initValueSource.find(row => row.id === dataSet.current?.[name]) as ILookupFieldListItem);
                setIsReady(true);
                return;
            }

            if (typeof (initSource.initValueSource as any).getState === 'function') {
                const state = (initSource.initValueSource as Store<any>).getState();
                initValue.current = Array.isArray(state) ? getItemLabel(state.find(row => row.id === dataSet.current?.[name])) :
                    typeof state === 'object' && getItemLabel(state) ||
                    state;
                setIsReady(true);
                return;
            }

            if (typeof initSource.initValueSource === 'object' && (initSource.initValueSource as any).refBookName && !getItemLabel(initSource.initValueSource as ILookupFieldListItem)) {
                const refBook = initSource.initValueSource as IRefBookSource;
                RetrieveDataset<ILookupFieldListItem>({
                    source: refBook.refBookName,
                    sliceId: refBook.sliceId,
                    params: initSource.search.params || refBook.params,
                    sourceDetalization: refBook.detalization
                }).then(data => {
                    const arr = Array.isArray(data?.dataSet) && data?.dataSet ||
                        typeof data?.dataSet === 'object' && objectAsRecordArray(data?.dataSet) ||
                        [];
                    initValue.current = getItemLabel(arr.find(el => el.id === dataSet.current?.[name]) as ILookupFieldListItem);
                    setIsReady(true);
                });
                return;
            }

            if (typeof initSource.initValueSource === 'object') {
                initValue.current = getItemLabel(initSource.initValueSource as any as ILookupFieldListItem);
                setIsReady(true);
            }
        }

        if ((source as IRefBookSource).refBookName) {
            const refBook = source as IRefBookSource;
            RetrieveDataset<ILookupFieldListItem>({
                source: refBook.refBookName,
                sliceId: refBook.sliceId,
                params: refBook.params,
                sourceDetalization: refBook.detalization,
                serviceDomain: refBook.serviceDomain,
                searchParams: refBook.searchParams,
                cached: refBook.cached,
            }).then(data => {
                const arr = Array.isArray(data?.dataSet) && data?.dataSet ||
                    typeof data?.dataSet === 'object' && objectAsRecordArray(data?.dataSet) ||
                    [];
                initValue.current = getItemLabel(arr.find(el => el.id === dataSet.current?.[name]) as ILookupFieldListItem);
                refList.current = arr;
                setIsReady(true);
            });
            return;
        }
    }, [source, dataSet.current]);

    function searchFunction(searchValue: string, callback: (options: SuggestionOption[], moreData?: boolean) => void) {
        if (!(source as any).search) return;
        const src = source as ILookupRemoteCall;
        const res: SuggestionOption[] = [];

        RetrieveDataset<ILookupFieldListItem>({ 
            source: src.search.path, 
            searchParams: { 
                ...src.search.params, 
                q: searchValue, 
                ...(maxCount && maxCount > 0 ? { limit: maxCount } : {})
            }
        })
        .then(data => {
            const maxResultCount = maxCount || (src.search?.params as any)?.limit || LookupFieldMaxSuggestionsCount;
            data.dataSet.slice(0, maxResultCount).forEach(el => res.push({ key: el.id?.toString() || '', mainValue: getItemLabel(el) }));
            callback(res, data.dataSet.length > maxResultCount);
        });
    }

    return <FieldContainer {...{ label, required }}>
        {isReady && <SuggestionBox
            initKey={dataSet.current?.[name]}
            list={isArray && {
                options: refList.current && refList.current.map(el => ({
                    key: el.id.toString(),
                    mainValue: getItemLabel(el),
                    detailValue: el.detailedInfo,
                    colorCircle: el.backgroundColor || el.color,
                    level: el.level,
                })) || [],
                maxSuggestionsCount: maxCount || LookupFieldMaxSuggestionsCount
            } || undefined}
            initValue={initValue.current}
            searchFunction={!isArray && (source as ILookupRemoteCall)?.search && searchFunction || undefined}
            dropAfterDelete={true}
            ctrlDelete={true}
            preferredKeys={preferred}
            disabled = { disabled }
            onChange={(key, mainValue) => {
                initValue.current = mainValue;
                dataSet.current && (dataSet.current[name] = key && (isNaN(parseInt(key)) ? key : parseInt(key)));
                operation.__changeNotification && operation.__changeNotification(name, originalDataset);
            }}
        /> || <SuggestionBox initValue="..." />}
    </FieldContainer>;
}

export const userFieldContext = createContext<{ data: any, setData: (data: Record<string, any>) => void }>({ data: null, setData: (data) => { } });

export function UserField({ children, validate }: { validate?: (data: any) => boolean, children: React.ReactNode | React.ReactNode[] }) {
    return (
        <FlatVPanel style={{ width: '100%' }}>
            {children}
        </FlatVPanel>
    );
}

export function FieldsRow({ children, classes }: { children: React.ReactNode | React.ReactNode[], classes?: string }) {
    return (
        <FlatWidePanel classes={`fieldsRow ${classes || ''}`}>
            {children}
        </FlatWidePanel>
    )
}