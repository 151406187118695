let atoken = "token";
let rtoken = "rtoken";
let aperms = "perms";
let usershortname = "usrnn";
let permissions = "perms"
let userprofile = "usrpf";
let orgprofile = "orgpf";
let orgcurrent = "orgcur";
let orgsave = "orgsv";
let orgid = "orgid";
let orguid = "orguid";
let orgname = "orgname";
let navkey = "nkey";
let lookaheadid = "lhid";
let refbooks = "refbk";
let countries = "cntrs";
let timezones = "timez";
let taskstats = "tskst";
let milestats = "milst";
let daytypes = "daytp";
let memberlevel = "membl";
let planstats = "plnst";
let plansesnstats = "plnss";
let plansesntyps = "plnsst";
let subscrstats = "subst";
let locale = "loc";
let localekey = "lockey";
let menukey = "mnkey";
let isflexible = "flxb";
let colswidth = "colwd";
let isowner = "isown";
let userid = "uid";
let dateformats = "dtfmt";
let invcode = "incd";
let invorg = "inorg";
let projtab = "ptab";
let projview = "pview"
let masterplanoper = "mpop";
let planscopeid = "psid";
let deadline = "bddl";
let pinemail = "pne"
let pinpass = "pnp"
let oacode = "oac"
let localres = "locrs"


export const getLocaleResources = () => {
  let js = localStorage.getItem(localres);
  return JSON.parse(js);
};
export const setLocaleResources = (obj) => {
  localStorage.setItem(localres, JSON.stringify(obj));
};

export const getPinEmail = () => localStorage.getItem(pinemail);
export const getPinPass = () => localStorage.getItem(pinpass);

export const setPinEmail = (val) => localStorage.setItem(pinemail, val);
export const setPinPass = (val) => localStorage.setItem(pinpass, val);

export const clearPinVars = () => {
  localStorage.removeItem(pinemail);
  localStorage.removeItem(pinpass);
};

// export const getOAuthCode = () => localStorage.getItem(oacode);
// export const setOAuthCode = (val) => localStorage.setItem(oacode, val);
// export const clearOAuthCode = () => localStorage.removeItem(oacode);

export const getInvitationCode = () => localStorage.getItem(invcode);
export const setInvitationCode = (val) => localStorage.setItem(invcode, val);
export const clearInvitationCode = () => localStorage.removeItem(invcode);

export const getInvitationOrg = () => localStorage.getItem(invorg);
export const setInvitationOrg = (val) => localStorage.setItem(invorg, val);
export const clearInvitationOrg = () => localStorage.removeItem(invorg);

export const getGridColumnWidth = () => {
  let js = localStorage.getItem(colswidth);
  return new Map(JSON.parse(js));
};
export const setGridColumnWidth = (map) => {
  localStorage.setItem(colswidth, JSON.stringify(Array.from(map.entries())));
};

export const clearGridColumnWidth = () => {
  localStorage.removeItem(colswidth);
}

export const getAccessToken = () => localStorage.getItem(atoken);
export const setAccessToken = (val) => localStorage.setItem(atoken, val);

export const getRefreshToken = () => localStorage.getItem(rtoken);
export const setRefreshToken = (val) => localStorage.setItem(rtoken, val);

export const getAllowedPermissions = () => {
  let js = localStorage.getItem(aperms);
  return JSON.parse(js);
};
export const setAllowedPermissions = (obj) => {
  localStorage.setItem(aperms, JSON.stringify(obj));
};

export const getUserShortName = () => localStorage.getItem(usershortname);
export const setUserShortName = (val) => localStorage.setItem(usershortname, val);


export const getUserPerms = () => {
  let js = localStorage.getItem(permissions);
  return JSON.parse(js);
};
export const setUserPerms = (obj) => {
  localStorage.setItem(permissions, JSON.stringify(obj));
};

export const getUserProfile = () => {
  let js = localStorage.getItem(userprofile);
  return JSON.parse(js);
};
export const setUserProfile = (obj) => {
  localStorage.setItem(userprofile, JSON.stringify(obj));
};

export const getOrgProfile = () => {
  let js = localStorage.getItem(orgprofile);
  return JSON.parse(js);
};
export const setOrgrProfile = (obj) => {
  localStorage.setItem(orgprofile, JSON.stringify(obj));
};

export const getRefBooks = () => {
  let js = localStorage.getItem(refbooks);
  return JSON.parse(js);
};
export const setRefBooks = (obj) => {
  localStorage.setItem(refbooks, JSON.stringify(obj));
};

export const getCountries = () => {
  let js = localStorage.getItem(countries);
  return JSON.parse(js);
};
export const setCountries = (obj) => {
  localStorage.setItem(countries, JSON.stringify(obj));
};

export const getTimezones = () => {
  let js = localStorage.getItem(timezones);
  return JSON.parse(js);
};
export const setTimezones = (obj) => {
  localStorage.setItem(timezones, JSON.stringify(obj));
};


export const getTaskStatuses = () => {
  let js = localStorage.getItem(taskstats);
  return JSON.parse(js);
};
export const setTaskStatuses = (obj) => {
  localStorage.setItem(taskstats, JSON.stringify(obj));
};

export const getMilestoneStatuses = () => {
  let js = localStorage.getItem(milestats);
  return JSON.parse(js);
};
export const setMilestoneStatuses = (obj) => {
  localStorage.setItem(milestats, JSON.stringify(obj));
};

export const getDayTypes = () => {
  let js = localStorage.getItem(daytypes);
  return JSON.parse(js);
};
export const setDayTypes = (obj) => {
  localStorage.setItem(daytypes, JSON.stringify(obj));
};

export const getMembershipLevels = () => {
  let js = localStorage.getItem(memberlevel);
  return JSON.parse(js);
};
export const setMembershipLevels = (obj) => {
  localStorage.setItem(memberlevel, JSON.stringify(obj));
};

export const getPlanStatuses = () => {
  let js = localStorage.getItem(planstats);
  return JSON.parse(js);
};
export const setPlanStatuses = (obj) => {
  localStorage.setItem(planstats, JSON.stringify(obj));
};

export const getPlanningSessionStatuses = () => {
  let js = localStorage.getItem(plansesnstats);
  return JSON.parse(js);
};
export const setPlanningSessionStatuses = (obj) => {
  localStorage.setItem(plansesnstats, JSON.stringify(obj));
};

export const getPlanningSessionTypes = () => {
  let js = localStorage.getItem(plansesntyps);
  return JSON.parse(js);
};
export const setPlanningSessionTypes = (obj) => {
  localStorage.setItem(plansesntyps, JSON.stringify(obj));
};

export const getSubscriptionStatuses = () => {
  let js = localStorage.getItem(subscrstats);
  return JSON.parse(js);
};
export const setSubscriptionStatuses = (obj) => {
  localStorage.setItem(subscrstats, JSON.stringify(obj));
};

export const getDateFormats = () => {
  let js = localStorage.getItem(dateformats);
  return JSON.parse(js);
};
export const setDateFormats = (obj) => {
  localStorage.setItem(dateformats, JSON.stringify(obj));
};

export const getCurOrgKey = () => localStorage.getItem(orgcurrent);
export const setCurOrgKey = (val) => localStorage.setItem(orgcurrent, val);
export const getSvOrgKey = () => localStorage.getItem(orgsave);
export const setSvOrgKey = (val) => localStorage.setItem(orgsave, val);
export const getOrgId = () => localStorage.getItem(orgid);
export const setOrgId = (val) => localStorage.setItem(orgid, val);
export const getOrgUid = () => localStorage.getItem(orguid);
export const setOrgUid = (val) => localStorage.setItem(orguid, val);
export const getOrgName = () => localStorage.getItem(orgname);
export const setOrgName = (val) => localStorage.setItem(orgname, val);
export const getNavSelKey = () => localStorage.getItem(navkey);
export const setNavSelKey = (val) => localStorage.setItem(navkey, val);
export const getIsOrgOwner = () => localStorage.getItem(isowner);
export const getUserId = () => localStorage.getItem(userid);


export const setOrgKeys = (val) => {
  localStorage.setItem(orgcurrent, val);
  localStorage.setItem(orgsave, val);
  localStorage.setItem(orgid, val);

  let upf = JSON.parse(localStorage.getItem(userprofile));
  // console.info("upf, val: ", upf, val);
  localStorage.setItem(userid, upf.id);
  if (upf.organizations) {
    const o = upf.organizations.findIndex(e => e.id === Number(val) && e.membershipLevel === 1) > -1;
    localStorage.setItem(isowner, o ? "yes" : "no");
  } else {
    localStorage.setItem(isowner, "no");
  }
}

export const delOrgKeys = () => {
  localStorage.removeItem(orgcurrent);
  localStorage.removeItem(orgsave);
  localStorage.removeItem(orgid);
};

export const delTokens = () => {
  localStorage.removeItem(atoken);
  localStorage.removeItem(rtoken);
};


export const getMasterPlanOper = () => localStorage.getItem(masterplanoper);
export const setMasterPlanOper = (val) => localStorage.setItem(masterplanoper, val);

export const getPlanScopeId = () => localStorage.getItem(planscopeid);
export const setPlanScopeId = (val) => localStorage.setItem(planscopeid, val);

export const getDeadlineKey = () => localStorage.getItem(deadline);
export const setDeadlineKey = (val) => localStorage.setItem(deadline, val);


export const getDateFormat = () => {
  const upf = getUserProfile();
  if (upf && upf.preferences && upf.preferences.locale && upf.preferences.locale.datePattern) {
    return upf.preferences.locale.datePattern;
  } else {
    return "DD-MMM-YYYY";
  }
};

export const getTimeFormat = () => {
  const upf = getUserProfile();
  if (upf && upf.preferences && upf.preferences.locale && upf.preferences.locale.timePattern) {
    return upf.preferences.locale.timePattern;
  } else {
    return "H:mm";
  }
};

export const getLocale = () => localStorage.getItem(locale);
export const setLocale = (val) => localStorage.setItem(locale, val);

export const getLocaleKey = () => localStorage.getItem(localekey);
export const setLocaleKey = (val) => localStorage.setItem(localekey, val);

export const getMenuKey = () => localStorage.getItem(menukey);
export const setMenuKey = (val) => localStorage.setItem(menukey, val);

export const getProjView = () => localStorage.getItem(projview);
export const setProjView = (val) => localStorage.setItem(projview, val);


export const getIsFlexible = () => {
  let v = localStorage.getItem(isflexible);
  return v === "1";
};
export const setIsFlexible = (v) => {
  localStorage.setItem(isflexible, v ? "1" : "0");
};

export const getProjectTab = () => localStorage.getItem(projtab);
export const setProjectTab = (val) => localStorage.setItem(projtab, val);

export const getLookAheadId = () => localStorage.getItem(lookaheadid);
export const setLookAheadId = (val) => localStorage.setItem(lookaheadid, val);


export const cleanupAll = () => {
  delTokens();
  localStorage.removeItem(userprofile);
  localStorage.removeItem(projtab);
  localStorage.removeItem(aperms);
  localStorage.removeItem(usershortname);
  localStorage.removeItem(orgprofile);
  localStorage.removeItem(orgcurrent);
  localStorage.removeItem(orgsave);
  localStorage.removeItem(orgid);
  localStorage.removeItem(orgname);
  localStorage.removeItem(navkey);
  localStorage.removeItem(refbooks);
  localStorage.removeItem(countries);
  localStorage.removeItem(timezones);
  localStorage.removeItem(taskstats);
  localStorage.removeItem(milestats);
  localStorage.removeItem(daytypes);
  localStorage.removeItem(memberlevel);
  localStorage.removeItem(planstats);
  localStorage.removeItem(plansesnstats);
  localStorage.removeItem(plansesntyps);
  localStorage.removeItem(subscrstats);
  localStorage.removeItem(permissions);
  localStorage.removeItem(isflexible);
  localStorage.removeItem(dateformats);
  localStorage.removeItem(colswidth);
  localStorage.removeItem(isowner);
  localStorage.removeItem(orguid);
  localStorage.removeItem(userid);
  localStorage.removeItem(localekey);
  localStorage.removeItem(isflexible);
  localStorage.removeItem(projview);  
  localStorage.removeItem(masterplanoper);
  localStorage.removeItem(planscopeid);
  localStorage.removeItem(deadline);
  localStorage.removeItem(projview);
  localStorage.removeItem(pinemail);
  localStorage.removeItem(pinpass);
  localStorage.removeItem(oacode);
  localStorage.removeItem(localres);
  localStorage.removeItem(invcode);
  localStorage.removeItem(invorg);
};
