import React, { CSSProperties, ReactNode, MouseEvent } from 'react';
import { ButtonBase, IButtonBase } from './AbstractButton/ButtonBase';
import { Icons } from '../SVGIcons/icons';
import { Color } from '../../bcGlobals';
import './Buttons.css';
import '../SVGIcons/icons.css'
import { cssProp } from '../../../../utils/utils';

export interface IButton extends IButtonBase {
    /**
     * The content to be displayed within the button.
     */
    children?: ReactNode;

    /**
     * The name of the icon to be displayed. This should correspond to a class name defined
     * in the icons.css file, which applies the appropriate SVG background image.
     * Example values: 'delete', 'edit', etc.
     */
    icon?: Icons;

    /**
     * The color for the icon. This sets the CSS 'color' property for the icon.
     * This color will be applied to the SVG icon via the 'currentColor' attribute.
     * Example value: 'red', '#00ff00', etc.
     */
    iconColor?: Color;

    /**
     * The color for the icon on hover. This sets the CSS 'color' property for the icon
     * when the button is hovered over. This color will be applied to the SVG icon via the
     * 'currentColor' attribute during hover.
     * Example value: 'blue', '#ff0000', etc.
     */
    iconHoverColor?: Color;

    /**
     * Optional size for the icon, which will be used to set the CSS variable '--icon-size'. 
     * Default value: --icon-size = --size
     * This determines the width and height of the icon.
     * Example value: '2em', '24px', etc.
     */
    iconSize?: string;
}

/**
 * `Button` Component - Renders a customizable button with flexible styles and content.
 * 
 * @component
 * @param {IButton} props - The component's props.
 * 
 * @returns {JSX.Element} The rendered Button component.
 * 
 * @example
 * // Example usage:
 * <Button onClick={handleClick} padding="10px" hoverBorderColor="blue" hoverBorder="2px solid red">
 *     Custom Button
 * </Button>
 */
export function Button(props: IButton) {
    const { children, ...restProps } = props;
    const cssVariables = { 
        ...(props.iconColor && { '--icon-color': props.iconColor }),
        ...(props.iconHoverColor && { '--hover-icon-color': props.iconHoverColor }), 
        ...(cssProp('--icon-size', props.iconSize, props.iconSize))
    };

    return (
        <ButtonBase
            {...restProps}
            classes={`${props.icon ? `asideIcon svgIcon ${props.icon}` : ''} ${props.classes || ''}`}
            style={{...props.style, ...cssVariables }}
        >
            { children }
        </ButtonBase>
    );
}

export function ButtonWithCancel(props: IButton & { onCancelClick?: (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void }) {
    const { children, ...restProps } = props;
    return (
        <Button {...restProps}>
            { children }
            <Button icon="none" classes="after afterCancel" onClick={ props.onCancelClick }></Button>            
        </Button>
    );
}