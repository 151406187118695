import { useState, useRef, useEffect, CSSProperties } from 'react';
import { Icons } from '../Controls/SVGIcons/icons';
import { FlatRightPanel } from './Panels/RightPanel';
import { Button } from '../Controls/Buttons/Buttons';
import { FlatWidePanel } from './Panels/StretchedPanels';
import { FlatControlsPanel } from './Panels/ContolsPanel';
import { ButtonClickEvent } from '../Controls/Buttons/AbstractButton/ButtonBase';
import './Layouts.css';

export interface IHeaderButton {
    icon?: Icons,
    label: string,
    classes?: string,
    style?: CSSProperties,
    onClick?: (e: ButtonClickEvent) => void,
}

export interface IHeaderBackButton { 
    label: string, 
    onClick: () => void 
};

export interface IHeader {
    label: string;
    menu?: JSX.Element;
    back?: IHeaderBackButton,
    buttons?: IHeaderButton[],
    classes?: string, 
    children?: React.ReactNode; 
    style?: CSSProperties;
}

export function HeaderButtons({ buttons } : {buttons: IHeaderButton[]}) {
    return (<>
        { buttons?.map((button, index) => <Button key = { index } { ...button } label={undefined}>{button.label}</Button>) }
    </>)    
}

export function HeaderButtonsInContainer({ buttons } : {buttons: IHeaderButton[]}) {
    return (
        <FlatRightPanel>
            <HeaderButtons buttons={buttons} />
        </FlatRightPanel>
    )
}

export function Header({ label, menu, back, buttons, classes, style, children }: IHeader) {
    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        menuRef.current && !menuRef.current.contains(event.target as Node) && setMenuVisible(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside); }
    }, []);

    return (
        <div className={`containerHeader ${back ? 'withBackButton' : ''} ${classes || ''}`} style={style}>
            {back && <div><div className="back" onClick={back.onClick}>{back.label}</div></div>}
            <FlatWidePanel>
                <div className="label">
                    {label}
                    {menu && (
                        <div ref={menuRef} className="kebabMenu">
                            <div onClick={() => setMenuVisible(!menuVisible)}></div>
                            {menuVisible && (
                                <div className="kebabMenuContainer">
                                    { menu }
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <FlatControlsPanel alignment='end'>
                    { children }
                    { buttons && <HeaderButtons buttons={buttons} /> }
                </FlatControlsPanel>
            </FlatWidePanel>    
        </div>
    );
}